import { isNullOrUndefined } from 'shared/lib/object/object';
import {
  numberRange as numberRangeConfig,
  timeRange as timeRangeConfig,
  userScope as userScopeConfig,
  userScopeTree as userScopeTreeConfig,
} from 'shared/api/query/configs.json';

export class UserScopeTreeHasher {
  treeHash(tree) {
    return this.identity(this.userScopeTreeIdentity(tree), false);
  }

  userScopeTreeIdentity(tree) {
    let res = [];

    res.push(tree[userScopeTreeConfig.edges.op]);

    if (!isNullOrUndefined(tree[userScopeTreeConfig.edges.scope])) {
      res = res.concat(this.userScopeIdentity(tree[userScopeTreeConfig.edges.scope]));
    }

    let childrenHashes = [];
    if (!isNullOrUndefined(tree[userScopeTreeConfig.edges.children])) {
      childrenHashes = tree[userScopeTreeConfig.edges.children].map((child) => this.identity(this.userScopeTreeIdentity(child), false));
    }
    res.push(this.identity(childrenHashes, true));
    return res;
  }

  identity(input, sortInput = false) {
    if (sortInput === true) {
      input.sort();
    }

    return input.join('');
  }

  userScopeIdentity(scope) {
    let res = [];

    if (!isNullOrUndefined(scope[userScopeConfig.edges.type])) {
      res.push(userScopeConfig.edges.type, scope[userScopeConfig.edges.type]);
    }

    if (!isNullOrUndefined(scope[userScopeConfig.edges.property])) {
      res.push(userScopeConfig.edges.property, scope[userScopeConfig.edges.property].uid.toString());
    }

    if (!isNullOrUndefined(scope[userScopeConfig.edges.timeRange])) {
      res.push(userScopeConfig.edges.timeRange);
      res = res.concat(this.timeRangeIdentity(scope[userScopeConfig.edges.timeRange]));
    }

    if (!isNullOrUndefined(scope[userScopeConfig.edges.numberRange])) {
      res.push(userScopeConfig.edges.numberRange);
      res = res.concat(this.numberRangeIdentity(scope[userScopeConfig.edges.numberRange]));
    }

    res.push(
      userScopeConfig.edges.users,
      this.identity(this.userIdentity(scope[userScopeConfig.edges.users]), true),
    );

    res.push(
      userScopeConfig.edges.selectedOptions,
      this.identity(this.propertyOptionIdentity(scope[userScopeConfig.edges.selectedOptions]), true),
    );

    res.push(
      userScopeConfig.edges.spaces,
      this.identity(this.spaceIdentity(scope[userScopeConfig.edges.spaces]), true),
    );

    if (!isNullOrUndefined(scope[userScopeConfig.edges.isEmpty])) {
      res.push(userScopeConfig.edges.isEmpty, scope[userScopeConfig.edges.isEmpty].toString());
    }

    res.push(
      userScopeConfig.edges.staticUsers,
      this.identity(this.userIdentity(scope[userScopeConfig.edges.staticUsers]), true),
    );

    return res;
  }

  propertyOptionIdentity(options = []) {
    return options.map((option) => option.uid.toString());
  }

  spaceIdentity(spaces = []) {
    return spaces.map((space) => space.uid.toString());
  }

  userIdentity(users = []) {
    return users.map((user) => user.uid.toString());
  }

  timeRangeIdentity(timeRange) {
    const res = [];

    if (!isNullOrUndefined(timeRange.start)) {
      res.push(timeRangeConfig.edges.start, new Date(timeRange[timeRangeConfig.edges.start]).toISOString());
    }

    if (!isNullOrUndefined(timeRange.end)) {
      res.push(timeRangeConfig.edges.end, new Date(timeRange[timeRangeConfig.edges.end]).toISOString());
    }

    return res;
  }

  numberRangeIdentity(numberRange) {
    const res = [];

    if (!isNullOrUndefined(numberRange.minType)) {
      res.push(numberRangeConfig.edges.minType, numberRange[numberRangeConfig.edges.minType]);
    }

    if (!isNullOrUndefined(numberRange.maxType)) {
      res.push(numberRangeConfig.edges.maxType, numberRange[numberRangeConfig.edges.maxType]);
    }

    if (!isNullOrUndefined(numberRange.min)) {
      res.push(numberRangeConfig.edges.min, numberRange[numberRangeConfig.edges.min].toString());
    }

    if (!isNullOrUndefined(numberRange.max)) {
      res.push(numberRangeConfig.edges.max, numberRange[numberRangeConfig.edges.max].toString());
    }

    return res;
  }
}
