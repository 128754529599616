import globalLang from 'shared/lang/de';
import { dataSourceQueryErrorSource, dataSourceQueryGenericError, goalProgressMeasurement, jiraError, msPlannerError, msPowerBiError, printablePage, progressDisplayOption, spreadsheetError } from 'shared/constants.json';
import { importValidationFailure } from '@/constants.json';
import { mergeLangObjects } from 'shared/lang/lang';

/* eslint-disable max-len */
const lang = {
  views: {
    survey: 'Umfrage',
    home: 'Home',
    profile: 'Profil',
    profileSettings: 'Profil - Einstellungen',
    myNotifications: 'Benachrichtigungen',
    accountSettings: 'Account - Einstellungen',
    login: 'Login',
    register: 'Neuen Account anlegen',
    registerLandingPage: 'Workspace - Login',
    userList: 'Mitglieder',
    spaceList: 'Teams',
    userDetail: 'Mitarbeiter',
    userAdd: 'Mitarbeiter hinzufügen',
    surveyTemplateList: 'Umfragevorlagen',
    surveyList: 'Umfragen',
    surveySuccess: 'Umfrage erfolgreich abgeschickt',
    createForm: 'Erstellen',
    formSettings: 'Einstellungen',
    formSend: 'Planen',
    formResults: 'Ergebnisse',
    fieldFactors: 'Faktoren',
    properties: 'Eigenschaften',
    documentList: 'Dashboards',
    accessGroupDetails: 'Zugangsgruppen',
    setPasswordTitle: 'Passwort zurücksetzen',
    passwordForgetTitle: 'Passwort vergessen',
    formShare: 'Umfrage versenden',
    createFormTemplate: 'Umfragevorlage erstellen',
    newForm: 'Neue Umfrage',
    newFormTemplatePreview: 'Vorschau der Vorlage',
    questionBank: 'Fragenkatalog',
    createFormTemplatesList: 'Umfragevorlagen',
    goalCycles: 'Zyklen',
    goalDetails: 'Zieldetails',
    goalSettings: 'Ziele - Einstellungen',
    settings: 'Einstellungen',
    notFound: '404',
    goalsAtlas: '{title} Netzwerk',
    profileInbox: 'Posteingang',
    optionMembers: 'Mitarbeiter',
    dashboards: 'Dashboards',
    dashboardDetails: 'Dashboard',
    goalInsights: 'Dashboard',
    accountSpace: 'Organisation',
    spaceDetails: 'Team',
    onboarding: 'Onboarding',
    publishedView: 'Ziele',
    formTemplateTags: 'Kategorien',
    integrationSettings: 'Integrationen',
    manageDataSource: 'Integration bearbeiten',
    goalsUpdateFeed: 'Feed',
    plans: 'Pläne',
    goalTypes: 'Zieltypen',
    goalStatus: 'Status',
    authorizationCodeGrantRedirect: 'Anmeldung',
  },
  highcharts: {
    decimalPoint: ',',
    thousandsSep: '.',
    months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    actual: 'Tatsächlich',
    expected: 'Erwartet',
    trend: 'Trend',
    noData: 'Keine Daten verfügbar',
  },
  navigation: {
    home: 'Home',
    surveys: 'Umfragen',
    settings: 'Einstellungen',
    logout: 'Ausloggen',
    feedback: 'Feedback',
    about: 'Info',
    users: 'Mitglieder',
    explorer: 'Explorer',
    goalInsights: 'Dashboard',
    goalInsightsPerformance: 'Progress',
    goalInsightsHealth: 'Health',
    goalInsightsPerformanceTitle: 'Progress dashboard',
    goalInsightsHealthTitle: 'Health dashboard',
    dashboards: 'Dashboards',
    goalsExplorerCascade: 'Kaskade',
    goalsExplorerList: 'Liste',
    goalsAtlas: 'Netzwerk',
    updatesExplorer: 'Check-ins',
    feed: 'Feed',
    updatesExplorerList: 'Liste',
    search: 'Suchen',
    instantFeedback: 'Feedback',
    updateSchedules: 'Check-in Zeitpläne',
    updateScheduleDetails: 'Check-in Zeitplan Details',
    updateTemplates: 'Check-in Vorlagen',
    plannings: 'Plannings',
    planningDetails: 'Planning Details',
    newDashboard: 'Dashboard erstellen',
    dashboard: 'Dashboards',
  },
  mainNavigation: {
    tools: 'Tools',
    macSearchKeyBinding: '⌘K',
    searchKeyBinding: 'Strg + K',
    inbox: 'Posteingang',
    favorites: 'Favoriten',
    spaceDropdownTitle: 'Space',
    invitePeople: 'Personen einladen',
    onboarding: 'Onboarding',
    helpAndSupport: 'Hilfe & Support',
    myWorkspace: 'Bereiche',
    insights: 'Insights',
    trash: 'Papierkorb',
  },
  trashContent: {
    title: 'Gelöschte Elemente',
    tabGoals: 'Ziele',
    noItems: { goal: 'Keine gelöschten Ziele', filter: 'Keine Filterergebnisse' },
    action: {
      confirmDeletePermanently: 'Bist du sicher, dass das Ziel \'{title}\' endgültig gelöscht werden soll?',
      yesDelete: 'Ja. Lösche dieses Ziel.',
    },
    openGoal: 'Ziel öffnen',
  },
  myWorkspace: {
    home: 'Home',
    viewAll: 'Alle anzeigen',
  },
  settingsNavigation: {
    title: 'Einstellungen',
    preferences: 'Profil',
    workspace: 'Workspace',
    account: 'Account',
    general: 'Allgemein',
    member: 'Nutzer',
    features: 'Tools',
    profile: 'Profil',
    userList: 'Mitglieder',
    spaceList: 'Teams',
    formTemplateList: 'Umfragen',
    userFields: 'Eigenschaften',
    accessControl: 'Zugangsgruppen',
    goals: 'Ziele',
    myNotifications: 'Benachrichtigungen',
    instantFeedbackTemplateList: 'Feedbackvorlagen',
    identityAndProvisioning: 'Identität und Provisionierung',
    plans: 'Pläne und Rechnungen',
    goalSettings: 'Einstellungen',
    goalCycles: 'Zyklen',
    goalTypes: 'Typen und Regeln',
    goalStatusSettings: 'Status',
  },
  edges: {
    status: 'Status',
    title: 'Titel',
    createdAt: 'Erstellungsdatum',
    modifiedAt: 'Änderungsdatum',
    cachedLastUpdatedAt: 'Zuletzt aktualisiert',
    cachedCalculatedCurrent: 'Fortschritt',
    cachedGoalCycle: 'Zyklus',
    cachedStatus: 'Status',
    creator: 'Ersteller',
    date: 'Datum',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'E-Mail',
  },
  pagination: {
    rowsPerPage: 'Zeilen pro Seite',
    from: 'von',
  },
  surveyList: { addSurvey: 'Neue Umfrage' },
  createFormCard: {
    heading: 'Erwecke deine Umfrage zum Leben',
    nameLabel: 'Wie heißt die Umfrage?',
  },
  charts: {
    answer: 'Antwort',
    proportion: 'Verteilung',
    amountOfAnswers: 'Anzahl Antworten',
    avg: 'Ø',
    top2: 'Top 2',
    top3: 'Top 3',
    count: 'Anzahl',
    positiveAnswers: 'Positiv',
    negativeAnswers: 'Negativ',
    neutralAnswers: 'Neutral',
    favorability: 'Favorability',
  },
  editPropertyForm: {
    editTitle: 'Eigenschaft editieren',
    createTitle: 'Neue Eigenschaft erstellen',
    label: 'Bezeichnung',
    description: 'Beschreibung',
    labelHint: 'Das Label sollte nicht zu lang sein',
    deletePrompt: 'Soll diese Eigenschaft wirklich gelöscht werden?',
    deletePromptButton: 'Ja, löschen',
    type: 'Typ',
    usedFor: 'Anwendung',
    usedForDescription: 'Stelle hier ein, für welche Objekte diese Eigenschaft verwendet werden soll. Es ist auch möglich, Eigenschaften für mehrere Objekte zu verwenden, z. B. die Eigenschaft "Abteilung" für Ziele und Benutzer.',
    numberFormat: 'Formattierungsoptionen',
    segments: 'Segmente',
    segmentsDescription: 'Segmente sind die konkreten Werte der Eigenschaft, z.B. die Abteilungen des Unternehmens.',
    addSegment: 'Segment hinzufügen',
    deleteText: 'Sicher, dass dieses Segment gelöscht werden soll?',
    number: 'Zahl',
    commaSeparated: 'Tausender Trennzeichen',
    percent: 'Prozent',
    euro: 'Euro',
    dollar: 'Dollar',
    pound: 'Pfund',
    lookupValue: 'Wert',
    lookupDescription: 'Lege die Ziel- und Nutzereigenschaft fest, anhand welcher der dargestellte Wert ermittelt wird.',
    lookupUsedForHint: 'Verweis-Eigenschaften können nur für Ziele benutzt werden.',
    lookupRelationsNotSet: 'Der Verweis-Wert muss gesetzt sein.',
    spaceManagementHint: 'Hinweis: Teams befinden sich nun auf einer eigenen Seite unter {link}.',
    spaceManagementLink: '@:{\'settingsNavigation.workspace\'} / @:{\'settingsNavigation.spaceList\'}',
  },
  properties: {
    pageTitle: 'Eigenschaften',
    pageSubtitle: 'Eigenschaften reichern deine Objekte, wie Mitarbeiter oder Ziele, mit weiteren Informationen an und erlauben es dir die Plattform nach deinen Wünschen zu individualisieren.',
    addButtonLabel: 'Eigenschaft hinzufügen',
    labelTitle: 'Name',
    labelType: 'Typ',
    labelStatus: 'Status',
    labelUsedFor: 'Anwendung',
    createNewTitle: 'Neue Eigenschaft erstellen',
    editTitle: 'Eigenschaft editieren',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/merkmale-in-mooncamp',
    help: 'Erfahre mehr darüber, wie du Eigenschaften verwenden kannst.',
  },
  propertyConstants: {
    space: 'Space',
    options: 'Multi-Select',
    singleSelect: 'Select',
    text: 'Text',
    date: 'Datum',
    number: 'Zahl',
    user: 'Person',
    url: 'Link',
    lookup: 'Verweis',
    goal: 'Ziele',
    update: 'Check-ins',
    inactive: 'Inaktiv',
    active: 'Aktiv',
    spaceDescription: 'Auswahl mehrerer Teams',
    optionsDescription: 'Auswahl mehrerer Optionen, z.B. Abteilungen',
    singleSelectDescription: 'Eine Liste mit Optionen, z.B. Status',
    textDescription: 'Ein beliebiger Text, z.B. Position',
    dateDescription: 'Ein festes Datum, wie Start- oder Geburtsdatum',
    userDescription: 'Eine Relation zu einer anderen Person, z.B. Abteilungsleiter',
    urlDescription: 'Ein Link zu einer Seite im Web',
    numberDescription: 'Ein zahlenbasierter Wert, wie Gehalt oder Performance Score',
    lookupDescription: 'Gibt den Inhalt einer Eigenschaft aus einem anderen Objekt (z.B. Benutzer) zurück',
  },
  formFieldSettings: {
    opinionScale: 'Likert-Skala Frage',
    longText: 'Freitext Frage',
    questionTypeLabel: 'Fragetyp',
    stepsLabel: 'Stufen',
    requiredLabel: 'Pflichtfeld',
    allowComment: 'Kommentare erlauben',
    startsFromZero: 'Starte Skala bei 0',
    labelLabel: 'Beschriftungen',
    labelLeftLabel: 'Linkes Label',
    labelCenterLabel: 'Zentrales Label',
    labelRightLabel: 'Rechtes Label',
  },
  formFieldList: {
    addField: 'Frage hinzufügen',
    noItems: 'Noch keine Fragen vorhanden',
    welcomeScreen: 'Willkommenstext',
    opinionScale: 'Likert-Skala Frage',
    longText: 'Freitext Frage',
  },
  createForm: {
    allSaved: 'Alle Änderungen sind gespeichert',
    needsSaving: 'Es sind nicht alle Änderungen gespeichert',
  },
  formField: {
    prompt: 'Sicher, dass die Frage gelöscht werden soll?',
    submitLabel: 'Ja, löschen',
    titlePlaceholder: 'Frage hier eingeben',
  },
  welcomeScreenField: {
    titleLabel: 'Titel',
    descriptionLabel: 'Beschreibung',
  },
  fieldTranslator: { heading: 'Übersetzungen' },
  formSettingsCard: {
    languageHeading: 'Spracheinstellungen',
    addLanguage: 'Sprache hinzufügen',
  },
  createFormTopBar: {
    analyticAccess: 'Teilen',
    accessManagement: 'Zugriff',
    preview: 'Vorschau',
    activateProcess: 'Aktivieren',
    deactivateProcess: 'Pausieren',
    deactivatePrompt: 'Durch das deaktivieren wird der Versandprozess gestoppt. Es werden keine automatischen E-Mails mehr versendet.',
    deactivated: 'Die Umfrage wurde erfolgreich deaktiviert',
    deactivateNow: 'Jetzt deaktivieren',
    activatePrompt: 'Durch das aktivieren wird der Versandprozess in Gang gesetzt.',
    activateNow: 'Jetzt aktivieren',
    activated: 'Die Umfrage wurde erfolgreich aktiviert',
    deactivateNotice: 'Stoppt die automatische Versendung der Umfrage.',
    errorNotice: 'Beseitige zuerst die angezeigten Fehler.',
    notActive: 'Inaktiv',
    active: 'Aktiv',
  },
  formLanguageItem: {
    deleteLabel: 'Übersetzung entfernen',
    prompt: 'Bist du sicher, dass die Übersetzung gelöscht werden soll?',
  },
  formLanguageSettingsCard: { addLanguage: 'Sprache hinzufügen' },
  formCard: {
    results: 'Ergebnisse',
    deleteTitle: 'Bist du sicher, dass du die Umfrage löschen willst? Du verlierst alle Antworten, die gesammelt wurden. Wir können sie nach dem Löschen nicht mehr wiederherstellen.',
  },
  editUserForm: {
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'E-Mail',
    language: 'Sprache',
    createTitle: 'Mitarbeiter hinzufügen',
    editTitle: 'Mitarbeiter editieren',
    groups: {
      personal: 'Persönliche Daten',
      other: 'Sonstige Eigenschaften',
    },
    sendInvitationMail: 'Einladung senden',
    timezone: 'Zeitzone',
  },
  userAdd: { title: 'Mitarbeiter hinzufügen' },
  userList: {
    title: 'Mitglieder ({total})',
    tableTitle: 'Mitglieder',
    active: 'Aktiv',
    status: 'Status',
    accessGroups: 'Zugangsgruppen',
    activatedAt: 'Aktiv seit',
    invitedByAt: 'Eingeladen von {name}, am',
    invitedAt: 'Eingeladen am',
    invitedByYouAt: 'Eingeladen von dir am',
    invite_pending: 'Einladung ausstehend',
    no_invitation: 'Noch nicht eingeladen',
    add: 'Mitglieder hinzufügen',
    deleteUserTitle: 'Bist du sicher, dass du den Nutzer löschen willst?',
    deleteSubmitLabel: 'Ja, löschen',
    importModalHeader: 'Aus CSV Importieren',
    importModalButton: 'CSV Importieren',
    importModalText: 'Du hast die Möglichkeit eine CSV-Datei hochzuladen um Mitarbeiter hinzuzufügen. Deine CSV-Datei muss eine Kopf-Zeile beinhalten, welche deiner Eigenschaft entspricht. Du kannst {link} ein entsprechendes Beispiel herunterladen.',
    csvDisabled: 'Feature ist im Testzeitraum nicht verfügbar.',
    here: 'hier',
    link: '/api/v1/csv-example?lang=de',
    importModalFileSelect: 'Datei auswählen',
    importSubmit: 'Hochladen',
    exportUsers: 'CSV Exportieren',
    bulkInvitationBox: {
      text: 'Es gibt {users}, die noch nicht eingeladen worden sind. Sie werden keine E-Mails von Mooncamp erhalten, bis sie sich mindestens einmal anmelden, um ihr Konto zu aktivieren.',
      userCount: '{userCount} Mitglieder',
      bulkAction: 'Einladung an {userCount} Mitglieder senden',
      goToMembers: 'Gehe zu @:{\'settingsNavigation.userList\'}',
    },
    demo: 'Demo Nutzer',
    bulkInvitationDialog: {
      text: 'Bitte bestätige, dass du eine Einladungs-E-Mail an {userCount} Benutzer mit dem Status "Noch nicht eingeladen" senden möchtest.',
      confirmAction: 'Ja, sende Einladungen an {userCount} Benutzer',
    },
    removeDemoUsers: {
      text: 'Dein Account enthält noch Demo Nutzer. Wir empfehlen, diese zu löschen, bevor du aktiv mit deinem Team in Mooncamp arbeitest.',
      button: 'Demo Nutzer löschen',
      confirmText: 'Bist du sicher?',
      confirmAction: 'Demo Nutzer löschen',
    },
  },
  userListCard: {
    name: 'Name',
    editUser: 'Mitarbeiter editieren',
    sendInvitationMailConfirm: 'Soll eine Einladung an {firstname} {lastname} gesendet werden?',
    sendInvitationMail: 'Einladung senden',
    invitationSuccess: 'Einladung erfolgreich gesendet',
    pending: 'Einladung ausstehend',
  },
  spaceStatus: {
    active: 'Aktiv',
    archived: 'Archiviert',
    joined: 'Beigetreten',
  },
  spaceList: {
    learnMore: 'Erfahre mehr über Teams.',
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/teams',
    title: 'Teams ({total})',
    tableTitle: 'Teams',
    create: 'Team hinzufügen',
    edit: 'Team editieren',
    filters: {
      active: 'Aktiv',
      archived: 'Archiviert',
      joined: 'Meine Teams',
      all: 'Alle',
    },
  },
  spaceListTable: {
    header: {
      name: 'Name',
      members: 'Mitglieder',
      status: 'Status',
    },
    archivedAt: 'Archiviert am',
  },
  spaceTopBarMenu: { members: '{count} Mitglied | {count} Mitglieder' },
  spacesContextMenu: {
    openSpace: 'Team öffnen',
    createSubSpace: 'Unterteam erstellen',
    cannotCreate: 'Dir fehlt die Berechtigung Teams zu erstellen.',
    cannotCreateMultiple: 'Es ist nicht erlaubt, Teams mit mehreren Eltern zu bilden.',
    cannotCreateLevel: 'Es können nur {maxLevel} Ebenen von Teams erstellt werden.',
    cannotDelete: 'Dir fehlt die Berechtigung dieses Team zu löschen.',
    cannotDeleteMultiple: 'Es ist nicht möglich, mehrere Einträge zu löschen.',
    archiveSomeHint: 'Einige Teams sind bereits archiviert.',
  },
  spaceEditor: {
    create: 'Team erstellen',
    edit: 'Teameinstellungen',
    dangerZoneLabel: 'Aktionen',
    generalTab: 'Allgemein',
    membersTab: 'Mitglieder {Anzahl}',
    archiveText: 'Team archivieren',
    archiveSubtext: 'Aus der Seitenleiste entfernen, Ziele werden nicht gelöscht',
    restoreText: 'Team wiederherstellen',
    restoreSubtext: 'Team in der Seitenleiste wiederherstellen',
    deleteText: 'Team löschen',
    deleteSubtext: 'Löscht dieses Team und alle verbundenen Ziele.',
    deleteModal: {
      title: 'Bist du dir sicher, dass du "{optionTitle}" löschen möchtest?',
      message: 'Ziele, die ausschließlich mit diesem Team verbunden sind, werden gelöscht.\nZiele, die mit anderen Teams geteilt werden, bleiben erhalten.\n\nDie Daten können nicht wiederhergestellt werden, sei vorsichtig!',
      securityQuestion: 'Gebe den Teamnamen ein, um fortzufahren.',
      okText: 'Team löschen',
    },
    validation: {
      titleIsEmpty: ' Der Teamname darf nicht leer sein.',
      titleAlreadyExists: 'Dieser Teamname existiert bereits.',
    },
  },
  spaceGeneralForm: {
    generalLabel: 'Icon, Farbe und Name',
    parentsLabel: 'Übergeordnetes Team',
    descriptionLabel: 'Beschreibung',
    titlePlaceholder: 'Name eingeben...',
    parentsPlaceholder: 'Kein Team ausgewählt',
  },
  spaceMembers: {
    addMembers: 'Mitglieder hinzufügen',
    memberRole: 'Mitglied',
    header: {
      name: 'Name',
      role: 'Rolle',
    },
    removeMemberPrompt: 'Bist du sicher, dass du das Mitglied aus dem Team entfernen möchtest?',
    removeRolePrompt: 'Bist du sicher, dass du deine eigene Rolle ändern möchtest? Du könntest die Berechtigungen verlieren, die du zur Bearbeitung dieses Teams benötigst.',
  },
  spaceMembershipSelector: {
    addMembersTo: 'Mitglieder hinzufügen zu',
    addMore: 'Mehr hinzufügen',
  },
  documentList: { createDocument: 'Layout erstellen' },
  createDocumentCard: {
    nameLabel: 'Name des Dokuments',
    heading: 'Erwecke dein Dokumnent zum Leben',
  },
  gridItemContent: {
    turnInto: 'Verwandeln in',
    h1: 'Überschrift 1',
    h2: 'Überschrift 2',
    h3: 'Überschrift 3',
    text: 'Text',
    handleHint: '<strong>Ziehen</strong>, um zu verschieben.',
    replace: 'Ersetzen',
    settings: 'Einstellungen',
  },
  accessGroupDetails: {
    addGroup: 'Gruppe hinzufügen',
    noAccessGroups: 'Es wurden noch keine Zugangskontrollgruppen erstellt.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/rechte-und-zugangskontrolle',
    help: 'Erfahre mehr über Rechte und Zugangskontrolle.',
  },
  passwordForget: { header: 'Passwort zurücksetzen' },
  passwordForgetForm: {
    hint1: 'Bitte gib die E-Mail ein, mit der du dich bei Mooncamp registriert hast.',
    buttonText: 'Link an mich senden',
    success: 'Hilfe ist unterwegs! Bitte schaue in deinem Posteingang nach einer E-Mail zum Zurücksetzen des Passworts.',
    error: 'Leider ist ein Fehler aufgetreten. Bitte kontrolliere deine E-Mail und probiere es nochmal!',
  },
  setPassword: { header: 'Neues Passwort festlegen' },
  setPasswordForm: {
    passwordLabel: 'Neues Passwort',
    tip: 'Tipp',
    hint: 'Dein Passwort ist besonders sicher, wenn es mindestens 8 Zeichen hat, idealerweise eine Mischung aus Groß- und Kleinbuchstaben, Ziffern und Sonderzeichen (z. B. *, %, §, ?).',
    buttonText: 'Neues Passwort festlegen',
    success: 'Dein Passwort wurde erfolgreich gesetzt. Du kannst dich wieder neu einloggen.',
    noLongerValid: 'Der Link ist nicht mehr gültig.',
  },
  rRulePicker: {
    first: '1.',
    second: '2.',
    third: '3.',
    fourth: '4.',
    last: 'letzten',
    startDate: 'Startdatum',
    startDateDescription: 'Startdatum der Erinnerung',
    at: 'Um',
    on: 'Am',
    recurrencePattern: 'Häufigkeit',
    occurrence: 'Nächste Ausführung | Nächsten {amount} Ausführungen',
    once: 'Einmalig',
    daily: 'Täglich',
    weeklyOn: 'Wöchentlich am',
    monthlyOn: 'Monatlich am',
    yearlyOn: 'Jährlich am',
    custom: 'Benutzerdefinierte Option hinzufügen',
    customConfig: 'Benutzerdefinierte Einstellungen',
    repeatEvery: 'Wiederholen alle ',
    day: 'Tag | Tage',
    week: 'Woche | Wochen',
    month: 'Monat | Monate',
    year: 'Jahr | Jahre',
    repeatAt: 'Wiederholen am',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
    end: 'Ende',
    never: 'Nie',
    after: 'Nach',
    executions: 'Ausführungen',
    startTimeHint: 'Die Startzeit muss in der Zukunft liegen',
  },
  formShare: {
    title: 'Automatischer E-Mail Versand',
    description: 'Du hast die Möglichkeit deine Umfrage mit unserem automatischen Prozess zu versenden. Dieser erlaubt es dir, Umfragen wiederholt und nur an bestimmte Nutzergruppen zu versenden.',
    noSurveyProcessYet: 'Du hast noch keinen Versandprozess eingerichtet.',
    createProcess: 'Versandprozess erstellen',
  },
  formSend: {
    reminderDescription: 'Falls ein Mitarbeiter die Umfrage nicht beantwortet, wird dieser daran per E-Mail erinnert.',
    addReminder: 'Erinnerung hinzufügen',
    timeDifferenceHeader: 'Wähle den Zeitabstand zwischen den Erinnerungen aus',
    previewTitle: 'Ausführungen',
    reminder: 'Erinnerung',
    noReminders: 'Es wurden keine Erinnerungen eingestellt.',
    sendingOptions: 'Versandoptionen',
    participants: 'Teilnehmer',
    verify: 'Zusammenfassung',
    reminders: 'Erinnerungen',
    optional: '(optional)',
    timing: 'Zeitplanung',
    saveAndContinue: 'Speichern und weiter',
    configuration: 'Einstellungen',
    channels: 'Kanäle',
    channelSubTitle: 'Wähle aus, wie Du die Umfrage verteilen möchtest.',
    emailSettings: 'E-Mail Einstellungen',
    errorsExist: 'Bitte behebe zuerst die Fehler in der Konfiguration.',
    sendToAll: 'An alle Mitarbeiter senden',
    sendToSegments: 'An spezifische Segmente senden',
  },
  userScopeTreeUserList: { userList: 'Ausgewählte Mitarbeiter' },
  durationPicker: {
    minute: 'Minute | Minuten',
    hour: 'Stunde | Stunden',
    day: 'Tag | Tage',
  },
  timeDifference: {
    minute: 'Minute | Minuten',
    hour: 'Stunde | Stunden',
    day: 'Tag | Tage',
    afterInitialMail: 'nach der initialen Benachrichtigung',
  },
  mDatePickerInput: { afterToday: 'Datum muss in der Zukunft liegen' },
  answerCard: {
    noResult: 'Keine Antwort',
    outOf: 'von',
    comment: 'Kommentar',
  },
  fieldChartCard: { comments: 'Kommentare' },
  chartComments: { valueTooltip: 'Die Frage wurde mit dem Wert „{value}“ beantwortet' },
  formEditor: {
    editForm: 'Umfrage editieren',
    languageSettings: 'Spracheinstellungen',
    fieldFactors: 'Faktoren',
    questionBank: 'Fragenkatalog',
    translationNotAvailable: 'Die Übersetzung für die ausgewählte Sprache ist nicht verfügbar',
    tags: 'Kategorien',
  },
  tableHeaderMenuCell: {
    filter: 'Filter',
    sortDescending: 'Absteigend sortieren',
    sortAscending: 'Aufsteigend sortieren',
    hideInView: 'In Ansicht ausblenden',
  },
  userScopeFilter: {
    addFilter: 'Filter hinzufügen',
    empty: 'Es ist kein Filter aktiv',
  },
  accountScopeFilter: {
    all: 'Alle Mitarbeiter',
    segments: 'Spezifische Segmente',
  },
  leafNode: {
    placeholder: 'Auswählen...',
    deleteTooltip: 'Filter entfernen',
  },
  dateScope: {
    isBefore: 'Ist vor',
    isAfter: 'Ist nach',
    isBetween: 'Ist innerhalb',
    isEmpty: 'Ist leer',
    isNotEmpty: 'Ist nicht leer',
    today: 'Heute',
    exactDate: 'Datum',
    nextXDays: 'Nächste X Tage',
    lastXDays: 'Letzte X Tage',
    nextNDays: 'Nächste {value} Tage',
    lastNDays: 'Letzte {value} Tage',
    thisWeek: 'Diese Woche',
    lastWeek: 'Letzte Woche',
    thisMonth: 'Dieser Monat',
    lastMonth: 'Letzter Monat',
    thisQuarter: 'Dieses Quartal',
    lastQuarter: 'Letztes Quartal',
    thisYear: 'Dieses Jahr',
    lastYear: 'Letztes Jahr',
    allTime: 'Gesamte Daten anzeigen',
    dateRange: 'Zeitraum',
    xDaysFromNow: 'In X Tagen',
    xDaysAgo: 'Vor X Tagen',
    nDaysFromNow: 'In {value} Tagen',
    nDaysAgo: 'Vor {value} Tagen',
    beginningThisWeek: 'Start diese Woche',
    beginningLastWeek: 'Start letzte Woche',
    beginningThisMonth: 'Start dieser Monat',
    beginningLastMonth: 'Start letzter Monat',
  },
  optionScope: {
    not: 'Ist nicht',
    is: 'Ist',
    contains: 'Enthält',
    notContains: 'Enthält nicht',
    isEmpty: 'Ist leer',
    isNotEmpty: 'Ist nicht leer',
    placeholder: 'Auswählen...',
  },
  userScope: {
    staticUserLabel: 'Mitarbeiter',
    placeholder: 'Wähle eine oder mehrere Personen',
  },
  profileLayout: {
    hoohoo: 'Huhuu! Huhuu!',
    goodEvening: 'Guten Abend',
    goodMorning: 'Guten Morgen',
    hello: 'Hallo',
    users: 'Mitarbeiter',
  },
  profilePageContent: {
    emptySurveyTasks: 'Dein Posteingang ist leer.',
    surveyTaskHeader: 'Posteingang',
  },
  surveyTaskItem: { questions: 'Fragen' },
  taskItem: { actionTitle: 'zur Umfrage' },
  formSendSummary: {
    questions: 'Fragen',
    numberOfQuestions: 'Anzahl an Fragen',
    privacyThreshold: 'Anonymitätsschwelle',
    startDate: 'Startdatum',
    frequency: 'Häufigkeit',
    firstOccurence: 'Erste Ausführung',
    reminder: 'Erinnerung',
    reminders: 'Erinnerungen',
    noReminders: 'Keine Erinnerungen eingestellt',
    once: 'Einmalig',
    participants: 'Teilnehmer',
    userList: 'Teilnehmerliste',
    questionsPerPulse: 'Fragen pro Pulsumfrage',
    pulseSurvey: 'Pulsumfrage',
    standardSurvey: 'Standardumfrage',
    surveyType: 'Umfragetyp',
    initialEmail: 'Initiale E-Mail',
    previewEmail: 'Vorschau E-Mail',
    startDateNotValid: 'Das Startdatum muss in der Zukunft liegen',
  },
  formSendSettings: {
    privacyThreshold: 'Anonymitätsschwelle',
    privacyThresholdDescription: 'Minimale Anzahl der Antworten in einer Gruppe, sodass Auswertungen oder Kommentare angezeigt werden können.',
    surveyTypeHeading: 'Umfragetyp',
    surveyTypeSubHeading: 'Wähle zwischen Standardbefragung und Pulsbefragung.',
    standardSurveyTitle: 'Standardumfrage',
    standardSurveyDescription: 'Umfragen, die einmalig oder wiederkehrend verschickt werden und <strong>alle</strong> Fragen auf einmal stellen.',
    pulseSurveyTitle: 'Pulsumfrage',
    pulseSurveyDescription: 'Kurze, häufigere Umfragen, die jedes Mal nur einen <strong>Teil</strong> der Fragen stellen.',
    questionsPerPulseHeading: 'Fragen pro Pulsumfrage',
    questionsPerPulseDescription: 'Begrenze die Anzahl der Fragen, um jede Pulsumfrage kurz und einfach zu halten.',
    pulseCalculationHeading: 'Puls-Score Berechnung',
    pulseCalculationDescription: 'Nutze nur die neusten Antworten eines Nutzers zur Score Berechnung (Empfehlung).',
    pulseCalculationInfo: '<p>Bei Verwendung der Standardumfrage-Berechnung werden nur die in einem bestimmten Zeitraum (z.B. einem Monat) gestellten Fragen zur Berechnung der Faktorwerte verwendet. Dies kann zu merkwürdigen Ergebnissen führen, da während einer Umfrage möglicherweise nur eine Teilmenge der Faktorfragen gestellt wurde. Folglich kann die Faktorberechnung in jeder Periode von verschiedenen Fragen abhängen, was nicht das beabsichtigte Verhalten ist.</p><p>Dieses Problem wird durch Aktivierung der Puls-Score Berechnung gelöst. Sie schließt ältere Umfragen ein, verwendet aber nur die letzte Antwort jedes Benutzers für die Berechnung.</p>',
  },
  numberSelectInput: { set: 'Einstellen' },
  fieldFactors: {
    addFactor: 'Faktor hinzufügen',
    empty: 'Es wurden noch keine Faktoren erstellt.',
    addTitle: 'Faktor hinzufügen',
    nonSelected: 'Kein Faktor ausgewählt',
    prompt: 'Bist du sicher, dass du diesen Faktor löschen willst?',
    editTitle: 'Faktor bearbeiten',
  },
  factorForm: { factorTitleLabel: 'Name ({lang})' },
  factorDetails: {
    empty: 'Du hast diesem Faktor noch keine Fragen zugewiesen',
    addQuestion: 'Frage hinzufügen',
    addQuestionPlaceholder: 'Frage auswählen',
  },
  formSendChannels: {
    subject: 'Betreff',
    body: 'Inhalt',
    previewEmail: 'Vorschau E-Mail',
    previewEmailTitle: 'Vorschau E-Mail',
  },
  analyticAccess: {
    description: 'Definiere welche Personen, oder Gruppen auf diese Ansicht Zugriff haben.',
    notify: 'E-Mail Benachrichtigung',
    personalMessage: 'Optional: persönliche Nachricht hinzufügen',
    link: 'Link zur dieser Ansicht',
    linkInfo: 'Nur Nutzer mit Zugriff können diese Ansicht durch diesen Link betrachten',
    copyBtn: 'Kopieren',
    copySuccess: 'Kopieren erfolgreich',
    copyError: 'Kopieren fehlgeschlagen',
  },
  emailPreview: { takeSurvey: 'Umfrage starten' },
  formResults: {
    overView: 'Übersicht',
    heatmap: 'Heatmap',
    timeRange: 'Zeitraum',
    notEnoughData: 'Nicht genug Antworten um Daten anzuzeigen.',
    employees: 'Mitarbeiter',
    responses: 'Antworten',
    participation: 'Teilnahme',
    factors: 'Faktoren',
    questions: 'Fragen',
    factor: 'Faktor',
    question: 'Frage',
    distribution: 'Verteilung',
    score: 'Score',
    delta: 'Delta',
    absolute: 'Absolut',
    show: 'Anzeige',
    factorsAndItems: 'Faktoren und Fragen',
    items: 'Fragen',
  },
  fieldChartRow: {
    // 0, 1, n
    responses: 'Antworten | Antwort | Antworten',
  },
  formTemplateList: {
    title: 'Umfragevorlagen',
    createTitle: 'Neue Vorlage erstellen',
    modalHeading: 'Neue Vorlage erstellen',
    deleteTitle: 'Bist du sicher, dass du die Vorlage löschen möchtest?',
  },
  createFormTemplateForm: { nameLabel: 'Wie heißt die Umfragevorlage?' },
  createFormTemplateTopBar: {
    notReleased: 'Entwurf',
    released: 'Freigegeben',
    release: 'Freigeben',
    setToDraft: 'Freigabe aufheben',
    releasePrompt: 'Bist du sicher, dass die Vorlage freigegeben werden soll? Sie kann danach zur Erstellung von Umfragen innerhalb deiner Organisation genutzt werden',
    unReleasePrompt: 'Bist du sicher, dass du die Vorlage nicht mehr freigeben möchtest?',
    releaseTooltip: 'Wenn eine Vorlage freigegeben ist, kann sie zur Erstellung von Umfragen genutzt werden.',
    draftTooltip: 'Vorlagen im Entwurf Status können nicht zur Erstellung von Umfragen genutzt werden. Du musst sie zuerst freigeben.',
    buttonLabel: 'Freigeben',
  },
  newForm: {
    templatesFrom: '%s Vorlagen',
    all: 'Alle',
    startFromScratch: 'Ohne Vorlage starten',
    allSurveyTemplates: 'Alle Vorlagen',
  },
  newFormSidebar: {
    surveyTemplates: 'Vorlagen',
    subTitle: 'Wähle eine Vorlage aus oder klicke auf <i>Ohne Vorlage starten</i>, um eine brandneue Umfrage zu erstellen.',
  },
  newFormSidebarTemplatePreview: {
    back: 'Zurück zur Vorlagenbibliothek',
    useThisTemplate: 'Diese Vorlage benutzen',
    startFromScratch: 'Ohne Vorlage starten',
  },
  newFormTemplatePreviewContent: { templatePreview: 'Vorschau der Vorlage' },
  questionsBank: {
    title: 'Fragenkatalog',
    subTitle: 'Wähle aus allen Fragen oder suche Fragen nach Vorlage.',
    allQuestions: 'Alle Fragen',
    templates: 'Vorlagen',
    questions: 'Frage | Fragen',
    allTemplates: 'Alle Vorlagen',
    addAll: 'Alle hinzufügen',
  },
  goalCycleEditor: {
    setup: 'Einrichtung',
    notifications: 'Benachrichtung',
    verify: 'Prüfen',
    subTitle: 'Ein Zyklus wird für die Gruppierung von Zielen benutzt. Lege den Namen des Zyklus fest, wie er für andere Mitarbeiter erscheinen soll.',
    verifySubTitle: 'Du bist bereit! Überprüfe die Zusammenfassung deiner Konfigurationen sowie die Zieleinstellungen, bevor du den neuen Zyklus aktivierst.',
    notificationSubTitle: 'Benachrichtige dich und deine Kollegen über den Start des Zyklus.',
    deactivate: 'Deaktivieren',
  },
  cycleSetup: {
    cycleNameLabel: 'Name des Zyklus',
    datesHeading: 'Start- und Enddatum',
    subHeading: 'Lege das Start- und Enddatum dieses Zyklus fest.',
  },
  goalsExplorer: {
    list: 'Liste',
    goalsAtlas: 'Netzwerk',
    cascade: 'Kaskade',
    properties: 'Eigenschaften',
    sort: 'Sortierung',
    allCycles: 'Alle Zyklen',
    explorer: 'Explorer',
    feed: 'Feed',
  },
  goal: {
    status: { unpublished: 'Entwurf' },
    detailHeader: {
      allGoals: 'Alle {title}',
      shareTitle: 'Sichtbarkeit',
      custom: 'Benutzerdefiniert',
      deletePrompt: 'Bist du dir sicher, dass du die ausgewählten {title} löschen willst?',
      share: 'Teilen',
      notification: 'Benachrichtigungen',
      addToDashboard: 'Zum Dashboard hinzufügen',
      disableStatusAutoUpdate: 'Automatisches Status-Update',
      statusUpdateTooltip: 'Aktualisiere den Status automatisch basierend auf den Unterelementen dieses Ziels.',
    },
    goalEditor: {
      progressMeasurement: 'Messart',
      description: 'Beschreibung',
      parent: 'Übergeordnetes {title}',
      goalCycle: 'Zyklus',
      binary: 'Offen/Erledigt',
      metric: 'Metrik',
      start: 'Start',
      end: 'Ziel',
      addDescription: 'Beschreibung hinzufügen',
    },
  },
  pageHeader: {
    openAsPage: 'Öffnen',
    share: 'Teilen',
  },
  goalDetails: {
    keyResults: 'Key Results',
    addKeyResult: 'Key Result hinzufügen',
    updates: 'Aktivität',
    deleteEmptyPrompt: 'Dieses {title} ist leer. Möchtest du es verwerfen?',
    deleteOkText: 'Ja, verwerfen',
    deleteCancelText: 'Nein, behalten',
  },
  goalsFilter: {
    title: 'Name',
    description: 'Beschreibung',
    progress: 'Fortschritt',
    parent: 'Übergeordnetes Ziel',
    children: 'Untergeordnetes Ziel',
    status: 'Status',
    current: 'Aktueller Wert',
    cycle: 'Zyklus',
    lastUpdatedAt: 'Zuletzt aktualisiert',
  },
  goalsSorter: { sort: 'Sortierung' },
  sorterBuilder: {
    add: 'Sortierung hinzufügen',
    empty: 'Es ist keine Sortierung aktiv',
  },
  sortOrder: {
    descending: 'Absteigend',
    ascending: 'Aufsteigend',
  },
  metricForm: {
    binary: 'Offen/Erledigt',
    continuous: 'Start- und Zielwert',
    threshold: 'Grenzwert',
    alignedItems: 'Aggregation',
    none: 'Keine',
    alignedItemsDescription: {
      relative: 'Berechne den Fortschritt anhand der Unterelemente',
      absolute: 'Wähle Unterelemente zum Aufsummieren',
    },
    startTargetDescription: 'Start- und Zielwert der Unterelemente',
    dataSourceDisabledHint: 'Du musst das Ziel veröffentlichen, bevor du eine Verbindung zu einer Datenquelle herstellen kannst.',
    start: 'Start',
    end: 'Ziel',
    metric: 'Einheit',
    custom: 'Benutzerdefiniert',
    coloring: 'Typ',
    notEqual: 'Start- und Zielwert dürfen nicht gleich sein.',
    notEmpty: 'Wert ist erforderlich.',
    metricMaxLen: 'Die Länge muss weniger als {length} Zeichen betragen.',
    tooltip: {
      [goalProgressMeasurement.continuous]: 'Zielfortschritt wird über einen Start- und Zielwert gemessen.',
      [goalProgressMeasurement.alignedItems]: 'Für Ziele, deren Fortschritt über den Fortschritt von Unterzielen gemessen werden soll. Angegeben in Prozent von 0-100.',
      [goalProgressMeasurement.threshold]: 'Für Ziele, die einen bestimmten Grenzwert nicht über- oder unterschreiten sollen.',
      [goalProgressMeasurement.binary]: 'Für Ziele, die entweder erreicht oder nicht erreicht sind.',
      [goalProgressMeasurement.none]: 'Für Ziele, bei denen kein Fortschritt gemessen werden soll.',
    },
    aggregationMethod: {
      relative: 'Prozent (Relativ)',
      absolute: 'Summe (Absolut)',
    },
  },
  progressMeasurement: {
    measurement: 'Messart',
    calculateBy: 'Fortschrittsberechnung durch',
  },
  alignedItemAdvancedOptions: {
    trigger: 'Erweiterte Optionen',
    settings: {
      unboundedProgress: 'Übererfüllung der Unterelemente erlauben',
      disableStatusAutoUpdate: 'Automatisches Status-Update aktivieren',
    },
    tooltips: {
      unboundedProgress: 'Unterelemente können mit mehr als 100% auf die Fortschrittskalkulation einzahlen.',
      disableStatusAutoUpdate: 'Der Status des Ziels aktualisiert sich automatisch, wenn sich der Fortschritt der Unterelemente ändert.',
    },
  },
  goalThresholdTargetArea: {
    above: 'Oberhalb bleiben',
    below: 'Unterhalb bleiben',
    aboveOrEqual: 'Oberhalb oder gleich bleiben',
    belowOrEqual: 'Unterhalb oder gleich bleiben',
    description: 'Zielbereich ist {placement} der Linie',
    placement: {
      above: 'oberhalb',
      below: 'unterhalb',
      aboveOrEqual: 'oberhalb oder auf',
      belowOrEqual: 'unterhalb oder auf',
    },
  },
  profileSettings: {
    microsoftSyncStatus: {
      consentUrlError: 'Fehler während der Kommunikation mit Microsoft.',
      readStateError: 'Fehler während der Kommunikation mit Microsoft.',
      syncError: 'Fehler während der Kommunikation mit Microsoft.',
      success: 'Die Verbindung wurde hergestellt.',
    },
    connectMicrosoftDescription: 'Wenn du dich über ein beliebiges (zusätzliches) Microsoft-Konto anmelden möchtest, kannst du es hier verbinden. Das Entfernen oder Hinzufügen einer Verbindung hat keinen Einfluss auf das Anmeldeverhalten mit {email}.',
    deleteMicrosoftEmailPrompt: 'Möchtest du die Microsoft-Verbindung {email} wirklich trennen?',
    connectMicrosoft: 'Microsoft Accounts verbinden',
    connectMicrosoftAction: 'Verbindung herstellen',
    deleteMicrosoftEmail: 'Trennen',
    title: 'Einstellungen',
    sectionHeading: 'Persönliche Informationen',
    setProfilePicture: 'Profilbild zuschneiden',
    photo: 'Foto',
    uploadImage: 'Hochladen',
    deleteImage: 'Löschen',
    deleteImagePrompt: {
      content: 'Bist du sicher, dass du das Foto löschen möchtest?',
      okText: 'Ja, löschen',
    },
    password: {
      sectionHeading: 'Passwort',
      button: 'Neues Passwort setzen',
      promptTitle: 'Neues Passwort setzen',
      content: 'Wir senden dir eine E-Mail an „{email}“, mit der du ein neues Passwort setzen kannst.',
      okText: 'Jetzt senden',
      success: 'Die E-Mail ist unterwegs und wird jeden Moment in deinem Postfach erscheinen.',
    },
    notificationsHeading: 'Benachrichtigungen',
    slackChannel: 'Channel',
    changeSlackChannel: 'Channel ändern',
    activateSlack: 'Aktivieren',
    deactivateSlack: 'Deaktivieren',
    activateMicrosoft: 'Aktivieren',
    deactivateMicrosoft: 'Deaktivieren',
    microsoftHintTitle: 'Microsoft Teams Anbindung nutzen',
    microsoftHint: 'Um die Microsoft Teams-Integration zu nutzen, installiere bitte den Mooncamp Bot in dem Microsoft Teams App Store. Weitere Informationen findest du unter:',
    slackHintTitle: 'Slack Integration nutzen',
    slackHint: 'Um die Slack-Integration zu nutzen, kannst du ganz einfach den Mooncamp Bot über Slack ansprechen. Bots lassen sich wie alle anderen Nutzer über einen privaten Channel erreichen.',
  },
  accountSettings: {
    setCompanyPicture: 'Setze ein Firmen Logo',
    setProfilePicture: 'Setze ein Profilbild',
    nameTaken: 'Die Domain ist bereits vergeben. Bitte benutze eine andere.',
    clickToCopy: 'Klicken, um zu kopieren',
    appIntegration: {
      heading: 'Integrationen',
      subHeading: 'Verbinde Mooncamp mit anderen Apps und Services',
      connectSlack: 'Slack verbinden',
      slackConnected: 'Slack verbunden',
      slackDisconnect: 'Verbindung zu Slack trennen',
      slackDisconnectText: 'Möchtest du wirklich die Verbindung zwischen Slack und Mooncamp trennen?',
      slackConnectionError: 'Fehler beim Verbinden mit Slack',
      notActivated: 'Slack ist für dieses Konto nicht installiert, bitte wende dich an einen Administrator.',
    },
    title: 'Account Einstellungen',
    addLogo: 'Logo hinzufügen',
    deleteImagePrompt: {
      content: 'Bist du sicher, dass du das Logo löschen möchtest?',
      okText: 'Ja, löschen',
    },
    companyInfo: {
      heading: 'Unternehmensinformation',
      nameLabel: 'Name des Unternehmens',
      signUpPathName: 'Einladungs-Link',
      signUpPathNameDescription: 'Teile den Link {link}, um Personen zu deinem Arbeitsbereich einzuladen.',
      gotIt: 'Verstanden',
      inviteHint: 'Bitte teile diesen Link erst, nachdem alle Nutzer in Mooncamp angelegt wurden. Andernfalls könnten eingeladene Nutzer versehentlich neue Workspaces erstellen, anstatt deinem Workspace beizutreten.',
    },
    features: {
      heading: 'Funktionen',
      subHeading: 'Bitte wähle die Funktionen aus, die in deinem Account genutzt werden sollen.',
      surveysHeading: 'Umfragen',
      surveysDescription: 'Umfragen sind ein gutes Werkzeug um anonymes Feedback von seinen Mitarbeiter zu erhalten.',
      activateSurveys: {
        title: 'Umfragen aktivieren',
        content: 'Bist du sicher, dass du die Umfragen Funktion für alle Mitarbeiter aktivieren möchtest?',
      },
      deactivateSurveys: {
        title: 'Umfragen deaktivieren',
        content: 'Bist du sicher, dass du die Umfragen Funktion im Account deaktivieren möchtest?',
      },
      activateGoals: {
        title: 'Ziele / OKRs aktivieren',
        content: 'Bist du sicher, dass du die Ziele Funktion für alle Mitarbeiter aktivieren möchtest?',
      },
      deactivateGoals: {
        title: 'Ziele / OKRs deaktivieren',
        content: 'Bist du sicher, dass du die Ziele Funktion im Account deaktivieren möchtest?',
      },
      activateUpdates: {
        title: 'Check-ins aktivieren',
        content: 'Bist du sicher, dass du die Check-ins Funktion für alle Mitarbeiter aktivieren möchtest?',
      },
      deactivateUpdates: {
        title: 'Check-ins deaktivieren',
        content: 'Bist du sicher, dass du die Check-ins Funktion im Account deaktivieren möchtest?',
      },
      goalsHeading: 'Ziele / OKRs',
      goalsDescription: 'Ziele schaffen Transparenz und Fokus für die wichtigen Themen im Unternehmen.',
      updatesHeading: 'Check-ins',
      updatesDescription: 'Check-ins ermöglichen es den Status und Fortschritt von Teams und Mitarbeitern zu kommunizieren.',
      activate: 'Aktivieren',
      deactivate: 'Deaktivieren',
    },
    reset: {
      heading: 'Account zurücksetzen',
      subHeading: 'Du kannst deinen Account von existierenden Daten, wie Demo- oder Testdaten, bereinigen.',
      button: 'Daten auswählen',
    },
    import: {
      heading: 'Nutzer importieren',
      subheading: 'Du kannst Nutzer im CSV-Format importieren. Das Beispiel gibt vor wie die Daten importiert werden müssen.',
      buttonExample: 'Beispiel herunterladen',
      buttonSelectFile: 'CSV-Datei auswählen',
      buttonUpload: 'Hochladen',
    },
  },
  notification: {
    subject: 'Betreff',
    body: 'Nachricht',
    date: 'Sendezeit',
    time: 'Zeit',
    receivers: 'Empfänger',
    showParticipants: 'Nutzer anzeigen',
    userList: 'Empfänger',
    runAt: 'Datum',
  },
  goalNotification: {
    addNotification: 'Benachrichtigung hinzufügen',
    deleteNotification: 'Bist du sicher, dass du die Benachrichtigung löschen möchtest? Wir können sie nach dem Löschen nicht mehr wiederherstellen.',
  },
  app: {
    newVersion: 'Eine neue Version von Mooncamp ist verfügbar. Bitte lade die Seite neu oder klicke hier, um die Anwendung zu aktualisieren.',
    foreignLogin: 'Du bist mittels foreign login eingeloggt, sei vorsichtig!',
    noAccess: {
      heading: 'Kein Zugriff',
      explanation: 'Du hast keine Berechtigung für diesen Inhalt',
      button: 'Zurück zur Hauptseite',
    },
    featureNotAvailable: {
      heading: 'Funktion nicht freigeschaltet',
      explanation: 'Diese Funktion ist für diesen Account nicht freigeschaltet. Für eine Freischaltung kannst du dich an deinen Account-Administrator oder unseren Kundenservice wenden.',
      button: 'Zurück zur Hauptseite',
    },
    accountExpired: {
      heading: 'Dieser Account ist abgelaufen',
      explanation: 'Dieser Account wurde deaktiviert. Für eine Freischaltung kannst du dich an deinen Account-Administrator oder unseren Kundenservice (support{\'@\'}mooncamp.com) wenden.',
    },
    accountInactive: {
      heading: 'Account deaktiviert',
      explanation: 'Dieser Account wurde deaktiviert. Für eine Freischaltung kannst du dich an deinen Account-Administrator oder unseren Kundenservice (support{\'@\'}mooncamp.com) wenden.',
    },
    reload: 'Neu laden',
  },
  goalUpdateEditor: {
    writeComment: 'Kommentar schreiben...',
    edited: 'bearbeitet',
    goalUpdates: 'Updates',
    viewCheckIn: 'Check-in ansehen',
    deleteUpdatePrompt: 'Bist du sicher, dass du das Check-in löschen willst?',
    addReaction: 'Reaktion hinzufügen',
    replyInThread: 'Im Thread antworten',
    createdAtExplanation: 'Dieses Update wurde am {date} veröffentlicht.',
  },
  mEditorMenuBubble: {
    turnInto: 'Verwandeln in',
    text: 'Text',
    heading1: 'Überschrift 1',
    heading2: 'Überschrift 2',
    heading3: 'Überschrift 3',
    bulletedList: 'Aufzählung',
    orderedList: 'Nummerierte Aufzählung',
    quote: 'Zitat',
    code: 'Code',
    editLinkTitle: 'Link editieren',
    image: 'Bild einfügen',
    file: 'Datei hinzufügen',
  },
  networkLegend: {
    legend: 'Legende',
    belongsTo: 'ist verbunden mit',
  },
  networkControls: {
    colorByStatus: 'Nach {statusLabel} einfärben',
    progress: 'Nach Fortschritt einfärben',
  },
  propsFilter: { button: 'Eigenschaften', title: 'Eigenschaften' },
  list: {
    filter: 'Filter',
    noTitle: 'Ohne Titel',
    ungrouped: 'Ungruppiert',
    list: 'Liste',
    atlas: 'Netzwerk',
    cascade: 'Kaskade',
    feed: 'Feed',
    tree: 'Baum',
    dashboard: 'Dashboard',
    default: 'Standardansicht',
    addTitle: 'Titel hinzufügen...',
  },
  register: {
    title: 'Starte mit Mooncamp',
    landingPageTitle: 'Einloggen im Workspace',
    emailNotFound: 'Wir konnten die eingegebene E-Mail-Adresse nicht finden. Bitte kontaktiere deinen Mooncamp-Administrator, um sicherzustellen, dass deine E-Mail-Adresse bereits registriert ist. Falls du mehrere Arbeits-E-Mails hast, probiere die Alternativen aus.',
    accountNotFound: 'Wir konnten den Workspace leider nicht finden.',
    perk1: '14 Tage kostenlos testen',
    perk2: 'Keine Kreditkarte benötigt',
    perk3: 'Jederzeit kündbar',
    viaGoogle: 'Weiter mit Google',
    viaMicrosoft: 'Weiter mit Microsoft',
    continueWithEmail: 'Weiter mit E-Mail',
    continueWithCode: 'Weiter mit Code',
    enterEmail: 'E-Mail eingeben...',
    codePlaceholder: 'Code hier eingeben...',
    terms: 'Durch einen Klick auf \'Weiter mit E-Mail / Google / Microsoft\' akzeptierst du Mooncamps {tos} und {privacyPolicy}.',
    tos: 'AGB',
    privacyPolicy: 'Datenschutzerklärung',
    hint1: 'Wir haben dir soeben einen Bestätigungscode per E-Mail geschickt.',
    hint2: 'Bitte schau in dein Postfach.',
    codeInvalid: 'Der Code ist entweder falsch oder abgelaufen. Bitte versuche es erneut!',
  },
  login: {
    header: {
      password: 'Willkommen zurück 👋',
      sso: 'Log in via SSO',
      ssoWithProvider: 'Log in via',
    },
    documentHeader: 'Willkommen zurück 👋',
    samlCallToAction: 'Fortfahren mit SSO',
    callToAction: 'Anmelden',
    emailPasswordCombinationWrong: 'Das eingegebene Passwort passt nicht zur E-Mail Adresse.',
    passwordLoginRestricted: 'Login via Passwort ist für diesen Account nicht erlaubt. Bitte nutze den SSO-Login oder frage deinen Mooncamp-Admin, wie du dich einloggen kannst.',
    viaGoogle: 'Anmelden via Google',
    viaMicrosoft: 'Anmelden via Microsoft',
    viaSaml: 'Anmelden via SSO',
    back: 'Zurück',
    samlLoginStatus: {
      unableToStart: 'Das hat leider nicht funktioniert. Der Benutzer existiert möglicherweise nicht oder SSO ist nicht richtig konfiguriert. Für Login via Microsoft, gehe zurück und klicke dort auf "Weiter mit Microsoft".',
      unableToVerify: 'Leider konnten wir die SSO-Anfrage nicht validieren. Bitte überprüfe die SSO-Konfiguration.',
    },
    register: 'Registrieren',
    noAccount: 'Du hast kein Konto?',
    haveAccount: 'Du hast bereits ein Konto?',
    logIn: 'Anmelden',
  },
  loginForm: {
    passwordForget: 'Passwort vergessen?',
    expired: 'Dieser Link ist abgelaufen.',
    enterPassword: 'Passwort eingeben',
    enterEmail: 'E-Mail eingeben',
    notAValidEmail: 'Bitte trage eine valide E-Mail Adresse ein',
    googleLoginNotAllowed: 'Einloggen via Google ist für diesen Account nicht erlaubt',
    microsoftLoginNotAllowed: 'Einloggen via Microsoft ist für diesen Account nicht erlaubt',
  },
  oauthError: {
    emailNotRegistered: {
      microsoft: '😧 Wir konnten kein Mooncamp-Konto für den Microsoft-Account {email} finden. Unter {signup} kannst du dich für einen Test-Account registrieren.',
      google: '😧 Wir konnten kein Mooncamp-Konto für den Google-Account {email} finden. Unter {signup} kannst du dich für einen Test-Account registrieren.',
    },
  },
  signIn: {
    imprint: 'Impressum',
    privacy: 'Datenschutzerklärung',
    terms: 'AGB',
  },
  notFoundPage: {
    title: '{type} wurde nicht gefunden',
    description: 'Das {type} wurde entweder gelöscht oder die eingegebene {type}-ID existiert nicht.',
    buttonText: 'Zurück zu allen {types}',
    buttonTextGoto: 'Gehe zu {name}',
    checkInSchedule: 'Check-in Zeitplan',
    profile: 'Profil',
    dashboard: 'Dashboard',
  },
  notFound: {
    title: '404',
    errorText: 'Error Code: 404',
    heading: 'Oops!',
    explanation: 'Wir können die Seite leider nicht finden.',
    goBack: 'Zur Startseite',
  },
  goalChildrenList: {
    alignedItems: 'Unterelemente',
    adaptProps: 'Eigenschaften',
  },
  groupBy: { groupBy: 'Gruppiert nach' },
  props: {
    user: {
      name: 'Name',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail',
    },
  },
  navItems: { noPageInside: 'Keine Unterseiten vorhanden' },
  profileInbox: { tasks: 'Posteingang' },
  propertyOption: {
    nothingToSee: 'Hier gibt es nichts zu sehen.',
    separatorNotAllowed: 'Trennzeichen \'{separator}\' ist nicht erlaubt.',
  },
  stackedBarChart: {
    total: 'Gesamtanzahl',
    distribution: 'Anteilig',
  },
  editGoalCycleModal: {
    createTitle: 'Zyklus erstellen',
    editTitle: 'Zyklus editieren',
    titleLabel: 'Name',
    titlePlaceholder: 'Name eingeben...',
    rangeLabel: 'Zeitraum',
    deletePromptLabel: 'Bist du dir sicher, dass du den Zyklus löschen willst?',
  },
  goalCycleSelector: {
    withoutCycle: 'Ohne Zyklus',
    all: 'Alle Zyklen',
    noneSelected: 'Kein Zyklus ausgewählt',
    title: 'Zyklus auswählen',
    current: 'Aktive Zyklen',
    upcoming: 'Bevorstehende Zyklen',
    past: 'Vergangene Zyklen',
    unassigned: 'Ohne Zyklus',
    more: 'weitere',
    selectPlaceholder: 'Zyklus auswählen...',
    searchPlaceholder: 'Nach Zyklus suchen...',
    lockCycle: 'Zyklus auf Ansicht speichern',
    tooltipLockCycleButton: 'Wenn gespeichert, bleibt dieser Zyklus in dieser Ansicht und ändert sich nicht, wenn du global einen anderen Zyklus auswählst.',
    tooltipLockCycleIcon: 'Dieser Zyklus ist in dieser Ansicht gespeichert.',
    emptyGroup: 'Keine Zyklen vorhanden',
  },
  accessRightSource: {
    default: 'Du hast {accessRight} zu {entityType}',
    via: 'Du hast {accessRight} zu {entityType} via',
    accessRight: {
      full: 'Vollzugriff',
      write: 'Bearbeitungszugriff',
      comment: 'Kommentarzugriff',
      read: 'Lesezugriff',
    },
    entityType: {
      default: 'dieser Ressource',
      goal: 'diesem Ziel',
      planning: 'diesem Planning',
      gridPage: 'diesem Dashboard',
      notification: 'diesem Zeitplan',
      updateTemplate: 'dieser Vorlage',
    },
  },
  accessPolicy: {
    organizationAccess: 'Organisation',
    organizationDescription: 'Jeder Mitarbeiter dieser Organisation',
    creatorDescription: 'Ersteller dieses Ziels',
    inviteButton: 'Personen hinzufügen',
    copyLink: 'Link kopieren',
    changeText: 'Bist du sicher, dass du deinen eigenen Zugriff ändern möchtest?',
  },
  accessPolicyForAccessGroups: {
    inviteButton: 'Gruppe oder Nutzer hinzufügen',
    confirmRemoveSelfText: 'Bist du sicher, dass du dich aus der Gruppe entfernen möchtest?',
  },
  accessPolicyScopeRow: { people: '1 Person | {count} Personen' },
  accessPolicyScopeSelector: {
    buttonLabel: 'Einladen',
    addMore: 'Mehr hinzufügen',
  },
  accessPolicyTypeSelector: {
    full: 'Voller Zugang',
    write: 'Editieren',
    comment: 'Kommentieren',
    read: 'Ansehen',
    disabled: 'Deaktiviert',
    remove: 'Entfernen',
    fullDescription: 'Kann editieren und Rechte ändern.',
    writeDescription: 'Kann editieren, aber nicht Rechte ändern.',
    commentDescription: 'Kann kommentieren und updaten, aber nicht editieren.',
    readDescription: 'Kann weder editieren, kommentieren noch Rechte ändern.',
    title: 'Recht wählen',
  },
  updateEditor: {
    startTyping: 'Schreibe los...',
    writeCheckIn: 'Check-in erstellen',
    editCheckIn: 'Check-in editieren',
    viewCheckIn: 'Check-in anschauen',
    emptyTemplate: 'Leeres Check-in',
    placeholderHeading: 'Drücke die Eingabetaste, um mit einer leeren Check-in fortzufahren, oder wähle eine Vorlage aus.',
    addGoalsFromLastWeek: 'Ziele deines letzten Check-ins hinzufügen',
    addGoalsFromUserProp: 'Ziele hinzufügen, bei denen ich "{label}" bin',
    goalsWithoutCommentAccessRemoved: 'Ziele, für die du keine Berechtigung zum Aktualisieren hast, wurden entfernt.',
  },
  updateTemplate: {
    placeholder: 'Schreibe los...',
    prefillGoalActivities: 'Automatisch Ziele von Check-in-Empfängern hinzufügen',
    prefillGoalActivitiesHint: 'Aktiviere diese Option, um dynamisch die Ziele zu filtern und hinzuzufügen, die die Empfänger am Ende ihres Check-in-Formulars aktualisieren sollen.',
    where: 'Wo',
    and: 'Und',
    is: 'Ist',
    recipient: 'Empfänger',
  },
  createUpdateBtn: {
    selectTemplateTitle: 'Zeitplan auswählen',
    addTemplate: 'Vorlage erstellen',
    deletePrompt: 'Bist du sicher, dass du diese Vorlage löschen willst?',
    newFromScratch: 'Neu ohne Zeitplan',
  },
  updateTemplateEditor: {
    alert: 'Du editierst gerade eine Vorlage für Check-ins',
    access: 'Zugriffsrechte',
    addUser: 'Person hinzufügen',
    creatorDescription: 'Ersteller der Vorlage',
  },
  resetAccountCard: {
    heading: 'Account zurücksetzen',
    description: 'Du kannst deinen Account von existierenden Daten, wie Demo- oder Testdaten, bereinigen. Dies wird empfohlen, bevor Mooncamp produktiv im Unternehmen genutzt wird. </br></br> Die Daten können nicht wiederhergestellt werden!',
    goalLabel: 'Lösche alle Ziele von diesem Account.',
    updateLabel: 'Lösche alle Check-ins und Updates von diesem Account.',
    demoUserLabel: 'Lösche alle Demo-Nutzer, die Eigenen bleiben erhalten.',
    submitButton: 'Löschen',
    confirmText: 'Bist du sicher, dass die ausgewählten Daten gelöscht werden sollen? Die Daten können nicht wiederhergestellt werden.',
  },
  deletePropertyCard: {
    heading: 'Eigenschaft löschen',
    description: 'Eigenschaften werden für verschiedene Features verwendet, z.B. bei Zielen oder Mitarbeitern, aber auch zur Definition von Umfrageteilnehmern oder zur Zugriffsverwaltung. Wenn Du eine Eigenschaft löscht, sind die Daten nicht wiederherstellbar. Du solltest diese Funktion sehr vorsichtig verwenden.',
    propertyLabel: 'Name',
    usedForLabel: 'Anwendung',
  },
  addGoalActivityBtn: {
    addUpdate: 'Update hinzufügen',
    newUpdate: 'Neue Updates erstellen',
    referenceUpdate: 'Vergangene Updates verlinken',
    description: 'Wähle ein oder mehrere Elemente zur Aktualisierung aus.',
    title: 'Update hinzufügen',
    add: 'Hinzufügen ({amount} ausgewählt)',
  },
  updatesList: {
    schedules: 'Zeitpläne',
    explorer: 'Explorer',
    templates: 'Vorlagen',
  },
  updateDetails: {
    update: 'Check-in',
    updates: 'Check-ins',
  },
  notificationEditorCard: {
    titleLabel: 'Name',
    recipients: 'Empfänger',
    template: 'Vorlage',
    pause: 'Pausieren',
    activate: 'Aktivieren',
    deletePrompt: 'Bist du sicher, dass du diese Erinnerung löschen möchtest?',
    notEditableNote: 'Die Erinnerung ist nur von {userName} editierbar.',
    reminders: 'Erinnerungen',
    reminderHint: 'Erinnerungen werden nur an Personen gesendet, die ihr Check-in noch nicht abgeschickt haben.',
    templateIsRequired: 'Vorlage ist erforderlich',
  },
  userScopeTreeUserField: { persons: 'Person | Personen' },
  updateTemplateSelector: {
    noTemplateSelected: 'Vorlage auswählen',
    title: 'Vorlage auswählen',
  },
  notificationItem: { paused: 'Pausiert' },
  importError: {
    [importValidationFailure.usersNotUnique]: 'Nutzer nicht eindeutig in der Datei',
    [importValidationFailure.noSuchProperty]: 'Eigenschaft existiert nicht',
    [importValidationFailure.noSuchPropertyOption]: 'Eigenschaft-Option existiert nicht',
    [importValidationFailure.noSuchUser]: 'Nutzer konnte nicht gefunden werden',
    [importValidationFailure.numberParse]: 'Fehler beim lesen der Zahl',
    [importValidationFailure.timeParse]: 'Fehler beim lesen des Datums',
    [importValidationFailure.userExists]: 'Nutzer existiert bereits',
    [importValidationFailure.csvMalformed]: 'Die CSV-Datei ist falsch formatiert',
    [importValidationFailure.invalidEmail]: 'E-Mail nicht valide',
    [importValidationFailure.invalidLanguage]: 'Sprache ist nicht valide',
    [importValidationFailure.nameIncomplete]: 'Vor- oder Nachname fehlt',
    [importValidationFailure.unauthorized]: 'Nutzer gehört zu einem anderen Konto',
  },
  heatmap: {
    groupBy: 'Gruppiert nach',
    all: 'Gesamt Score ',
    ungrouped: 'Ungruppierte Fragen',
    showAll: 'Alle anzeigen',
  },
  searchDialog: {
    results: 'Ergebnisse',
    recentlyVisited: 'Vor kurzem besucht',
  },
  timeRangeSelector: {
    thisMonth: 'Dieser Monat',
    lastMonth: 'Vorheriger Monat',
    lastXMonth: 'Letzten {value} Monate',
    custom: 'Benutzerdefiniert',
    title: 'Zeit auswählen',
    customTitle: 'Benutzerdefiniert',
  },
  updateFeedItem: {
    deletePrompt: 'Bist du dir sicher, dass du dein Check-in löschen möchtest?',
    edited: 'editiert',
    addReaction: 'Reaktion hinzufügen',
    addComment: 'Kommentieren',
    viaCheckin: 'via Check-in',
  },
  reactionItem: {
    title: '{names} {have} mit {emoji} reagiert',
    have: 'hat | haben',
    you: 'Du',
    have2ndFormSingular: 'hast',
  },
  listFilter: {
    title: 'Filter',
    limitFilterToFirstLevel: 'Filter nur auf erste Ebene anwenden',
    allLevels: 'Auf alle Ebenen anwenden',
    firstLevel: 'Auf erste Ebene anwenden',
    filterOptions: 'Filteroptionen',
  },
  listSorter: { title: 'Sortieren' },
  shareDropdown: { shareTitle: 'Teilen' },
  reactionList: { addEmoji: 'Emoji hinzufügen' },
  commentList: {
    writeComment: 'Verfasse einen Kommentar...',
    showMore: 'Mehr Kommentare zeigen',
    comments: 'Kommentar | Kommentare',
    latestComment: 'Letzter Kommentar {latestComment}',
    viewThread: 'Kommentare anschauen',
  },
  commentItem: { deletePrompt: 'Bist du dir sicher, dass du deinen Kommentar löschen möchtest?' },
  mLangPicker: { selectLanguage: 'Sprache wählen' },
  notificationFeed: {
    goalPropertyValueReferenced: '{actor} hat dich zu {propertyTitle} in {goalTitle} zugewiesen',
    goalComment: '{actor} hat für {goalTitle} einen Kommentar verfasst',
    goalUpdateMention: '{actor} hat dich in {goalTitle} erwähnt',
    updateMention: '{actor} hat dich in einem {checkInTitle} erwähnt',
    updateComment: '{actor} hat einen neuen Kommentar auf einem {checkInTitle} verfasst',
    goalUpdateComment: '{actor} hat einen neuen Kommentar auf {goalTitle} verfasst',
    goalUpdate: '{actor} hat {goalTitle} aktualisiert',
    survey: 'Neue Umfrageteilnahme {formTitle}',
    updateNotification: 'Check-in {checkinTitle}',
    feedbackInquiry: '{actor} hat Feedback von dir angefragt',
    feedbackDeclined: '{actor} hat deine Feedback-Anfrage abgelehnt',
    feedbackReceived: '{actor} hat dir Feedback gegeben',
    dataSourceUnhealthy: 'Die Autorisierung für {dataSourceName} läuft bald ab',
    dataSourceDead: 'Die Autorisierung für {dataSourceName} ist abgelaufen und muss erneuert werden',
    dataSourceQueryError: 'Integration konnte keine Daten für {goalTitle} abrufen',
    view: 'Anschauen',
    reply: 'Antworten',
    noItems: {
      notifications: 'Keine Aktualisierungen vorhanden.',
      tasks: '🎉 Keine offenen Aufgaben',
      all: 'Keine Einträge vorhanden',
    },
    action: {
      survey: 'Umfrage ausfüllen',
      updateNotification: 'Check-in durchführen',
      dataSourceUnhealthy: 'Verbindung erneuern',
      dataSourceDead: 'Verbindung erneuern',
      dataSourceQueryError: 'Ziel anzeigen',
    },
    actionCompleted: {
      survey: 'Umfrage ausgefüllt',
      updateNotification: 'Check-in erledigt',
      feedbackInquiry: 'Feedback gegeben',
      dataSourceUnhealthy: 'Verbindung erneuert',
      dataSourceDead: 'Verbindung erneuert',
      dataSourceQueryError: 'Integration wiederhergestellt',
    },
    tabNotifications: 'Benachrichtigungen',
    tabTasks: 'Aufgaben',
    declineFeedbackInquiry: 'Anfrage ablehnen',
    giveFeedback: 'Feedback geben',
    feedbackStatusDeclined: 'Anfrage abgelehnt',
  },
  spaceDetails: {
    owners: 'Leitung',
    description: 'Beschreibung',
    addDescription: 'Beschreibung hinzufügen',
    addADescription: 'Beschreibung hinzufügen...',
    deleteMessage: 'Bist du dir sicher, dass du "{optionTitle}" löschen möchtest?',
  },
  savedViewSelector: {
    addView: 'Ansicht hinzufügen',
    addViewPlaceholder: 'Name der Ansicht',
    cascadeDescription: 'Tabelle zur Darstellung und Bearbeitung hierarchischer Daten',
    listDescription: 'Eine einfache flache Listenansicht zum Durchsuchen von Daten',
    feedDescription: 'Ideal um den Inhalt direkt darzustellen',
    atlasDescription: 'Ein Netz von verknüpften Daten wie Teams, Ziele und Initiativen',
    treeDescription: 'Baumansicht zur Erkundung der Hierarchie von Zielen und Daten',
    selectView: 'Ansicht auswählen',
    createView: 'Ansicht hinzufügen',
    editView: 'Ansicht bearbeiten',
    deletePrompt: 'Bist du dir sicher, dass du diese Ansicht löschen möchtest?',
    setPublicPrompt: 'Bist du dir sicher, dass diese Ansicht veröffentlicht werden soll? Sie ist danach für die gesamte Organisation sichtbar.',
    setPrivatePrompt: 'Bist du dir sicher, dass du Ansicht nicht mehr öffentlich sein soll? Sie ist danach nur noch für dich sichtbar.',
    setPrivatePromptNoAccess: 'Bist du dir sicher, dass du Ansicht nicht mehr öffentlich sein soll? Sie ist danach nur noch für {user} sichtbar.',
    publish: 'Für alle Nutzer sichtbar',
    defaultDescription: 'Standardansicht von Dashboards',
    saveForEveryone: 'Für alle speichern',
    canNotSave: 'Nicht gespeichert',
    canNotSaveNote: 'Die Änderungen können nicht für alle gespeichert werden, da du nicht genügend Rechte besitzt.',
    saveButtonTooltipPublic: 'Speichert die Änderungen in der Ansicht für alle',
    saveButtonTooltipPrivate: 'Änderungen in der Ansicht speichern',
    reset: 'Zurücksetzen',
    more: 'mehr...',
  },
  favoriteButton: {
    favorite: 'Zu Favoriten',
    favorited: 'Favorisiert',
    title: 'Favoriten',
  },
  favoriteEditor: {
    namePlaceholder: 'Name',
    delete: 'Von Favoriten entfernen',
    create: 'Zu Favoriten hinzufügen',
  },
  mention: {
    subheading: 'Person auswählen',
    noUsers: 'Keine Person gefunden',
  },
  feedEntryTitle: {
    checkIn: 'Check-in',
    genericPropertyLabel: 'ein Eigenschaft',
  },
  goalListGroup: { disabledDragging: 'Du kannst die Reihenfolge der Elemente nicht verändern, weil du folgende Elemente nicht bearbeiten darfst: {goals}' },
  createSpaceCard: {
    placeholder: 'Name eingeben...',
    dropdownTitle: 'Space erstellen',
  },
  dataSources: {
    sheets: 'Google Sheets',
    asana: 'Asana',
    jira: 'Jira',
    excel: 'Excel Online',
    hubspot: 'Hubspot',
    salesforce: 'Salesforce',
    msPowerBi: 'Microsoft Power BI',
    msPlanner: 'Microsoft Planner',
    errors: {
      [dataSourceQueryErrorSource.generic]: {
        [dataSourceQueryGenericError.unknown]: 'Bei der Kommunikation mit {source} ist ein Fehler aufgetreten.',
        [dataSourceQueryGenericError.incompleteSetup]: 'Setup of {sourceName} is not correct/complete. Please verify your data source.',
        [dataSourceQueryGenericError.unauthenticated]: 'Failed to authenticate. Please verify that your {sourceName} connection is still valid.',
        [dataSourceQueryGenericError.unauthorized]: 'Failed to obtain authorization. Please verify if access to {source} resources has not been revoked.',
        [dataSourceQueryGenericError.notFound]: '{source} resources could not be found. Please verify if they have not been deleted.',
      },
      [dataSourceQueryErrorSource.spreadsheet]: {
        [spreadsheetError.notANumber]: 'Die ausgewählte Zelle beinhaltet keine Zahl.',
        [spreadsheetError.empty]: 'Die ausgewählte Zelle ist leer.',
        gapiError: 'Es gab einen Fehler beim Laden der Google API Erweiterung.',
      },
      [dataSourceQueryErrorSource.jira]: { [jiraError.jqlMalformed]: 'Die JQL-Query ist fehlerhaft.' },
      [dataSourceQueryErrorSource.hubspot]: {},
      [dataSourceQueryErrorSource.asana]: {
        taskNotFound: 'Die ausgewählte Asana-Aufgabe konnte nicht gefunden werden.',
        userNotFound: 'Der ausgewählte Asana-Nutzer konnte nicht gefunden werden.',
        sectionNotFound: 'Der ausgewählte Asana-Abschnitt konnte nicht gefunden werden.',
      },
      [dataSourceQueryErrorSource.salesforce]: {},
      [dataSourceQueryErrorSource.msPowerBi]: {
        [msPowerBiError.empty]: 'Für die angegebene Konfiguration wurden keine Ergebnisse gefunden.',
        [msPowerBiError.notANumber]: 'Für die angegebene Konfiguration ist das Ergebnis keine Zahl.',
        [msPowerBiError.notASingleElement]: 'Für die angegebene Konfiguration ist das Ergebnis keine einzelne Zelle.',
      },
      [dataSourceQueryErrorSource.msPlanner]: { [msPlannerError.empty]: 'Für die angegebene Konfiguration wurden keine Aufgaben oder Checklistenelemente gefunden.' },
    },
  },
  dataSource: {
    nonSelected: 'Keine ausgewählt',
    untitled: 'Kein Titel',
    empty: 'Bisher gibt es keine Verbindung. Erstelle eine neue Verbidung um deine {title} automatisch zu aktualisieren.',
    connectToDataSource: 'Mit Datenquelle verbinden',
    selectDataSource: 'Datenquelle auswählen',
    connectedTo: 'Verbunden mit {source}',
    syncNow: 'Jetzt synchronisieren',
    successfullySynced: 'Daten erfolgreich synchronisiert',
    title: 'Datenverbindung',
    alreadySet: 'Dieses {title} ist bereits mit einer Datenquelle verbunden. Du kannst diese jedoch überschreiben',
    overwriteAction: 'Datenquelle überschreiben',
    deleteAction: 'Verknüpfung entfernen',
    deleteConnectionPrompt: 'Bist du sicher, dass du diese Verknüpfung löschen möchtest?',
    alwaysSync: 'Immer synchronisieren',
    alwaysSyncHint: 'Bei Aktivierung werden auch Updates erstellt, wenn sich der Wert gegenüber dem letzten Update nicht verändert hat.',
  },
  jiraEditor: {
    serverLabel: 'Server',
    clientId: 'Client ID',
    clientSecret: 'Client secret',
    tokenLabel: 'Token',
    tokenHint: 'Du kannst von {account} Tokens erstellen. Finde {description} mehr Informationen.',
    accountHint: 'deinem Atlassian-Konto',
    descriptionHint: 'hier',
    authenticationType: {
      apiToken: 'Cloud',
      oauth: 'Self-managed (OAuth)',
      oauthv2: 'Self-managed (OAuth 2.0)',
    },
    signIn: 'Mit Jira verbinden',
    signInAgain: 'Erneut verbinden',
    tutorial: 'Hier findest du eine Anleitung wie du Mooncamp mit Jira verbindest.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/jira-integration',
    faultyCredentials: 'Die Zugangsdaten sind nicht korrekt',
    emailHint: 'Die E-Mail, welche du bei deinem Jira-Konto verwendest.',
    serverHint: 'Die Account-URL von deinem Jira-Konto, bspw. "acme.atlassian.net"',
    serverPlaceholder: 'z.B. acme.atlassian.net',
    oauthServerPlaceholder: 'z.B. https://jira.example.com',
    oauthv2HelpLink: 'https://confluence.atlassian.com/adminjiraserver/link-to-other-applications-938846918.html',
    oauthv2Help: 'Hier findest du eine Anleitung wie du Mooncamp mittels OAuth 2.0 verbindest.',
  },
  spreadsheetForm: {
    help: 'Erfahre mehr über die Google Sheets / Excel Online Integration.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/google-sheets-excel-online-integration',
    selectDocumentLabel: 'Dokument',
    selectSheetLabel: 'Sheet',
    selectColumnLabel: 'Spalte',
    selectRowLabel: 'Zeile',
    viewFile: 'Öffne Datei',
    error: {
      excel: 'Es gab einen Fehler mit der Kommunikation mit Excel. Bitte versuche es noch einmal mit einer anderen Datenquelle.',
      sheets: 'Es gab einen Fehler mit der Kommunikation mit Google Sheets. Bitte versuche es noch einmal mit einer anderen Datenquelle.',
    },
    selectFile: 'Dokument auswählen',
    changeFile: 'Auswahl ändern',
    excelHint: 'Daten erfolgreich synchronisiert. Beachte, dass Änderungen in Excel einen Moment brauchen, bis sie für Mooncamp verfügbar sind.',
  },
  dataSourceEditor: {
    connect: 'Mit {provider} verbinden',
    connectHint: 'Erstelle eine neue Verbindung zu {provider}, um Daten zwischen Mooncamp und {provider} automatisch zu synchronisieren.',
    refreshHint: 'Aktualisiere deine Verbindung zu {provider}, um Daten zwischen Mooncamp und {provider} automatisch zu synchronisieren.',
    successfullyConnected: 'Erfolgreich verbunden',
    shareHintDocs: 'Du kannst die Verbindung mit anderen Nutzern teilen. Beachte allerdings, dass dadurch allen Nutzern Zugriff auf die Ressourcen gegeben wird, die über diese Datenquelle erreichbar sind. Dies gilt auch, wenn der Nutzer sonst keinen Zugriff auf die Ressource hat.',
    shareHint: 'Du kannst die Verbindung mit anderen Nutzern teilen. Beachte allerdings, dass dadurch allen Nutzern Zugriff auf die verfügbaren Metriken gegeben wird.',
    nameLabel: 'Name',
    sharedLabel: 'Teile diese Verbindung mit anderen Nutzern',
  },
  dataSourceItem: {
    deletePrompt: 'Bist du dir sicher, dass du diese Datenverbindung löschen willst? Eventuell gibt es {title}, welche diese Verbindung nutzen und somit nicht mehr automatisch synchronisiert werden.',
    editTitle: 'Mit {source} verbinden',
    refreshAction: 'Verbindung erneuern',
    refreshDescription: 'Abgelaufene Verbindungen erfordern eine erneute Zustimmung. Klicke auf den Button, um die Verbindung zu aktualisieren.',
    connectionUnhealthy: 'Verbindung läuft aus',
    connectionExpired: 'Verbindung abgelaufen',
    incompleteDescription: 'Die Verbindung wurde noch nicht hergestellt.',
    establishAction: 'Verbindung herstellen',
    creatorDescription: 'Ersteller der Datenverbindung',
  },
  jiraForm: {
    help: 'Erfahre mehr über die Jira Integration.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/jira-integration',
    jqlLabel: 'JQL',
    trackMetricLabel: 'Fortschritt verfolgen nach',
    onlyDoneLabel: 'Nur erledigte Tickets zählen',
    openInJira: 'In Jira öffnen',
    operationModes: {
      overallProgress: {
        label: 'Gesamtfortschritt',
        description: 'Messe den Gesamtfortschritt (%) bestimmter Aufgaben gegenüber der Gesamtmenge von Aufgaben wie unten festgelegt.',
      },
      countOfIssues: {
        label: 'Anzahl an Aufgaben',
        description: 'Gibt die Anzahl der unten angegebenen Aufgaben zurück',
      },
    },
    learnJql: 'Lerne mehr über JQL',
    learnJqlLink: 'https://www.atlassian.com/de/software/jira/guides/expand-jira/jql',
  },
  jiraJqlEditor: { placeholder: 'JQL-Query einfügen...' },
  jiraCountOfIssues: { label: 'JQL-Query' },
  jiraOverallProgress: {
    countIssues: 'Anzahl an Aufgaben',
    totalIssues: 'Gesamtfortschritt',
  },
  msPowerBIForm: {
    help: 'Erfahre mehr über die Microsoft Power BI-Integration.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/microsoft-power-bi-integration',
    selectDatasetLabel: 'Dataset',
    daxQueryLabel: 'DAX Query',
    daxQueryPlaceholder: 'EVALUATE ...',
  },
  msPlanner: {
    aggregationMode: {
      absolute: {
        task: {
          label: 'Anzahl der erledigten Aufgaben',
          description: 'Verwende diese Option, um die Anzahl der erledigten Aufgaben in Planner zu zählen.',
        },
        checklist: {
          label: 'Anzahl der erledigten Checklistenpunkte',
          description: 'Verwende diese Option, um die Anzahl der erledigten Checklistenelemente in Planner zu zählen.',
        },
      },
      percentage: {
        task: {
          label: 'Fortschritt der Aufgaben',
          description: 'Verwende diese Option, um den Fortschritt deiner Planner-Aufgaben zu verfolgen. Erledigte Aufgaben zählen mit 100%, während Aufgaben "In Arbeit" mit 50% gewertet werden. Der Gesamtfortschritt wird als relativer Fortschritt aller ausgewählten Aufgaben berechnet.',
        },
        checklist: {
          label: 'Fortschritt der Checklistenpunkte',
          description: 'Verwende diese Option, um den Fortschritt deiner Checklistenelemente in Planner zu verfolgen.  Der Gesamtfortschritt setzt die erledigten Elemente in Relation zu allen ausgewählten Elementen.',
        },
      },
    },
  },
  msPlannerForm: {
    help: 'Erfahre mehr über die Microsoft Planner-Integration.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/microsoft-planner-integration',
    aggregationLabel: 'Wie möchtest du den Fortschritt messen?',
    selectHint: 'Wähle Aufgaben zur Fortschrittsberechnung aus',
    selectPlanLabel: 'Plan',
    selectBucketsLabel: 'Buckets (optional)',
    selectLabelsLabel: 'Bezeichnungen (optional)',
    labels: {
      category1: 'Rosa',
      category2: 'Grün',
      category3: 'Aquamarin',
      category4: 'Rot',
      category5: 'Gelb',
      category6: 'Blau',
      category7: 'Lila',
      category8: 'Bronze',
      category9: 'Limonengrün',
      category10: 'Grau',
      category11: 'Silber',
      category12: 'Braun',
      category13: 'Preiselbeerot',
      category14: 'Orange',
      category15: 'Pfirsichrosa',
      category16: 'Marigold',
      category17: 'Hellgrün',
      category18: 'Dunkelgrün',
      category19: 'Blaugrün',
      category20: 'Hellblau',
      category21: 'Dunkelblau',
      category22: 'Lavendel',
      category23: 'Pflaumenblau',
      category24: 'Hellgrau',
      category25: 'Dunkelgrau',
    },
  },
  goalProgress: {
    dataConnectionTitle: 'Datenverbindung',
    autoUpdateDescription: 'Automatisch aktualisiert durch {dataSourceType}',
  },
  dataSourceSummary: {
    connectedBy: 'Verbunden durch',
    private: 'Private Datenquelle',
    syncError: 'Verbindungsproblem',
  },
  googleSheetsSummary: {
    column: 'Spalte',
    row: 'Zeile',
    sheetName: 'Sheet',
  },
  jiraSummary: {
    openInJira: 'In Jira öffnen',
    operationMode: 'Berechnungsmodus',
    empty: 'alle Jira Issues',
  },
  hubspotSummary: {
    trackMetric: 'Metrik',
    startDate: 'Start',
    endDate: 'Ende',
  },
  asanaSummary: {
    project: 'Projekt',
    assignedTo: 'Zugewiesen an',
    sections: 'Task Status',
    task: 'Task',
    operationMode: 'Berechnungsmodus',
  },
  salesforceSummary: {
    report: 'Bericht',
    field: 'Feld',
  },
  msPowerBiSummary: {
    dataset: 'Dataset',
    dax: 'DAX Query',
  },
  msPlannerSummary: {
    aggregation: 'Aggregation',
    plan: 'Plan',
    buckets: 'Bucket | Buckets',
    labels: 'Bezeichnung | Bezeichnungen',
  },
  dataSourceQueryError: { details: 'Details' },
  dataSourcePicker: {
    title: 'Datenquelle',
    integrations: 'Integrationen',
    defaultName: '{source} Datenquelle',
  },
  salesforce: {
    selectReportLabel: 'Bericht',
    selectFieldLabel: 'Feld',
  },
  asanaForm: {
    help: 'Erfahre mehr über die Asana Integration.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/asana-integration',
    selectProjectLabel: 'Projekt',
    selectWorkspaceLabel: 'Workspace',
    selectAssignedTo: 'Zugewiesen an (optional)',
    selectSection: 'Fortschritt gemessen durch',
    sectionOrStatus: 'Abschnitt oder Status (optional)',
    completed: 'Erledigt',
    countMeasuredBy: 'Anzahl gemessen durch',
    completionMeasuredBy: 'Erledigt, wenn',
    singleTask: 'Einzelne Aufgabe verbinden',
    operationModes: {
      overallProgress: {
        label: 'Gesamtfortschritt',
        description: 'Messe den Gesamtfortschritt (%) bestimmter Aufgaben gegenüber der Gesamtmenge von Aufgaben wie unten festgelegt.',
      },
      countOfTasks: {
        label: 'Anzahl an Aufgaben',
        description: 'Gibt die Anzahl der unten angegebenen Aufgaben zurück.',
      },
      singleTask: { label: 'Einzelne Aufgabe', description: 'Messe die Erledigung einer einzelnen Aufgabe.' },
    },
    overallLabel: 'Gesamte Aufgaben, gemessen durch',
    emptyPlaceholder: 'Beliebige',
    selectTask: 'Aufgabe auswählen',
    task: 'Aufgabe',
    all: 'Alle',
    asanaCompletionStatus: {
      completed: 'Erledigt',
      incomplete: 'Unerledigt',
      any: 'Beliebig',
    },
    countTasks: 'Ausgewählte Aufgaben',
    countTasksHint: 'Anzahl der Aufgaben, die den Fortschritt bestimmen.',
    totalTasks: 'Gesamte Aufgaben',
    and: 'Und',
  },
  asanaSectionForm: {
    section: 'Abschnitt',
    status: 'Status',
    or: 'Oder',
    addAction: 'Abschnitt hinzufügen',
    any: 'Beliebig',
  },
  asanaTaskSelectionTrigger: {
    assignedTo: 'Zugewiesen an',
    allTasks: 'Beliebige Aufgaben',
    any: 'Beliebig',
    criteriaSelected: 'Kriterien ausgewählt',
  },
  hubspotForm: {
    help: 'Erfahre mehr über die Hubspot Integration.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/hubspot-integration',
    trackMetric: {
      numberOfRawViews: 'Anzahl Seitenaufrufe (Rohdaten)',
      numberOfVisits: 'Anzal Seitenaufrufe',
      numberOfVisitors: 'Anzahl Besucher',
      numberOfLeads: 'Anzahl Leads',
      numberOfContacts: 'Anzahl Kontakte',
      numberOfSubscribers: 'Anzahl Subscribers',
      numberOfMqls: 'Anzahl MQLs',
      numberOfSqls: 'Anzahl SQLs',
      numberOfOpportunities: 'Anzahl Opportunities',
      numberOfCustomers: 'Anzahl Kunden',
      averagePageViewsPerSession: 'Durchschnittliche Seitenaufrufe pro Sitzung',
      bounceRate: '% Bounce Rate',
      timePerSession: 'Zeit pro Session (in Sekunden)',
      newSessions: '% Neue Sessions',
      sessionToContact: '% Session pro Kontakt',
      contactToCustomer: '% Contact pro Kunde',
    },
    selectTrackMetric: 'Metrik',
    selectTimeRange: 'Zeitfenster (optional)',
    startPlaceholder: 'Start',
    endPlaceholder: 'Ende',
  },
  onboarding: {
    personalInfosTitle: 'Willkommen bei Mooncamp',
    personalInfosSubTitle: 'Erzähl uns zuerst ein wenig über dich.',
    accountInfosTitle: 'Erstelle deinen Account',
    accountInfosSubTitle: 'Fülle ein paar Details zu deinem Unternehmen aus.',
    moduleSelectionTitle: 'Welche Tools möchtest du verwenden?',
    moduleSelectionSubTitle: 'Wir werden den Onboarding-Leitfaden entsprechend anpassen.',
    continue: 'Weiter',
    loggedInAs: 'Du bist aktuell als {email} eingeloggt. Wenn du keinen neuen Account erstellen willst, kannst du dich {link}',
    logInAsDifferentUser: 'hier als anderer Nutzer einloggen',
    redeemCouponTitle: 'Gutscheincode anwenden',
    redeemCouponSubTitle: 'Bitte gib deinen Gutscheincode ein',
    launchMooncamp: 'Mooncamp starten',
  },
  personalInfos: {
    uploadImage: 'Foto hinzufügen',
    firstNameLabel: 'Vorname',
    lastNameLabel: 'Nachname',
    passwordLabel: 'Passwort',
  },
  accountInfos: {
    uploadImage: 'Logo hinzufügen',
    companyNameLabel: 'Unternehmen',
    companySize: 'Unternehmensgröße',
  },
  moduleSelectionInfos: {
    okrTitle: 'OKRs',
    okrDescription: 'Erzeuge Alignment, Transparenz und Fokus.',
    surveysTitle: 'Umfragen',
    surveysDescription: 'Erhalte Feedback von deinen Mitarbeitern.',
    creatingYourAccount: 'Dein Account wird erstellt...',
  },
  msTeamsTeamDetails: {
    hint: 'Du bist derzeit in keinem Team.',
    employeePage: 'Du kannst deine Team-Einstellungen  {employeePage} ändern.',
    employeePageLinkDesc: 'hier',
    adminRequest: 'Bitte frage einen Mooncamp-Admin, um deine Team-Eigenschaften richtig zu setzen.',
    myTeams: 'Teams',
  },
  msTeamsAccountDetails: {
    unableToFindAccountSpace: 'Wir konnten den Kontobereich nicht finden.',
    goToBtn: 'Gehe zu Team OKRs',
  },
  msTeams: {
    connectionEstablished: 'Verbindung zum Teams-Bot ist hergestellt.',
    connectionError: 'Leider ist ein Fehler aufgetreten.',
  },
  onboardingGuide: {
    inviteUserModal: 'Personen einladen',

    gettingStarted: {
      title: 'Erste Schritte',
      introVideo: { title: 'Mooncamp Introvideo' },
      appTour: { title: 'Mache eine kurze App-Tour' },
      whyMooncampIsUnique: { title: 'Tipps & Tricks in Mooncamp' },
      videoTutorials: { title: 'Sieh dir unsere Video-Tutorials an' },
      okrResources: { title: 'Schau dir unsere OKR-Ressourcen an' },
    },
    accountSetupNew: {
      title: 'Konto einrichten',
      removeDemoData: { title: 'Demo-Daten entfernen' },
      messengers: { title: 'Mit Slack oder MS Teams verbinden' },
      inviteTeamMembers: { title: 'Lade deine Teammitglieder ein' },
      checkins: { title: 'Check-ins für deine Teams einrichten' },
    },
    markAllAsDone: 'Alles als erledigt markieren',
  },
  inviteUsers: {
    inviteUsersLabel: 'E-Mails',
    inviteUsersPlaceholder: 'E-Mails mit einem Komma, Semikolon oder Leerzeichen getrennt eingeben.',
    spaceLabel: 'Wähle ein Team (optional)',
    spacePlaceholder: 'Kein Team ausgewählt',
    inviteNow: 'Einladungen versenden',
    recognizedEmailsLabel: 'Diese Personen erhalten eine Einladung zum Workspace:',
    noEmails: 'Keine E-Mails erkannt',
    sendInvite: 'Einladung senden',
    oneEmailAlreadyExists: 'Eine oder mehrere der eingegebenen E-Mail Adressen sind bereits in einem anderen Account registriert. Um die Personen hinzuzufügen, müssen sie aus dem anderen Account entfernt werden. Unser Kundenservice kann dich hierbei unterstützen.',
    success: 'Einladungen erfolgreich versendet',
  },
  trialBox: {
    // 0,1,n
    expired: 'Dein Testzeitraum ist abgelaufen!',
    today: 'Dein Testzeitraum endet heute!',
    tomorrow: 'Dein Testzeitraum endet morgen!',
    remaining: 'Dein Testzeitraum endet in {amount}',
    days: '{amount} Tagen',
    button: 'Upgrade',
    bookDemo: 'Demo vereinbaren',
    demoLink: '@:{\'general.websiteBaseUrl\'}/kontakt/',
  },
  subscriptionInfo: {
    heading: 'Abonnement starten',
    headingExpired: 'Sieht so aus, als wäre dein Testzeitraum abgelaufen 😱',
    text1: 'Wenn du dich für ein Abonnement entschieden hast, deinen Testzeitraum verlängern möchtest oder du sonstige Fragen zu Mooncamps Software hast, melde dich bei uns unter',
    text2: 'Das Mooncamp Team',
  },
  time: {
    weeksAgo: 'vor einer Woche | vor {count} Wochen',
    date: '{dateTime}',
    daysAgo: 'gestern | vor {count} Tagen',
    hoursAgo: 'vor einer Stunde | vor {count} Stunden',
    minutesAgo: 'vor einer Minute | vor {count} Minuten',
  },
  notificationComment: { reply: 'Antworten' },
  goalSettings: {
    title: 'Ziele - Einstellungen',
    advanced: {
      title: 'Erweiterte Einstellungen',
      multiGoalAlignment: {
        title: 'Mehrfachausrichtung von Zielen',
        subTitle: 'Richte ein Ziel an zwei oder mehr übergeordneten Zielen aus.',
      },
      publishedViews: {
        title: 'Öffentliche Links zu Ansichten',
        subTitle: 'Erstelle öffentliche Ansichten, die für jeden sichtbar sind.',
      },
      weightedGoals: {
        title: 'Gewichtete Ziele',
        subTitle: 'Gebe Zielen ein Gewicht, um die Wichtigkeit in der Fortschrittsberechnung zu berücksichtigen',
      },
      canGroupByUser: {
        title: 'Mitarbeitergruppierung für Insights',
        subTitle: 'Einzelne Benutzeraufschlüsselungen aktivieren oder deaktivieren (z.B. Progress Dashboard)',
      },
    },
    accessRight: {
      title: 'Zugriffsrechte - Voreinstellung',
      description: 'Du kannst eine Zugangsgruppe anlegen, die bei der Erstellung neuer Ziele verwendet wird.',
      addUserText: 'Gruppe hinzufügen',
    },
    insightRanges: {
      title: 'Schwellenwerte Fortschrittsindikator',
      description: 'Passe die Schwellenwerte an, um festzulegen, was als guter, mittlerer und schlechter Fortschritt bei deinen OKRs gilt. Diese Einstellungen beeinflussen, wie der Fortschritt auf dem Fortschrittsdashboard und der Netzwerkansicht visuell dargestellt wird.',
    },
    featureName: {
      title: 'Anzeigename',
      subTitle: 'Wenn deine Organisation einen anderen Begriff für Ziele verwendet, kannst du diesen hier ändern.',
      defaultSingular: 'Ziel',
      defaultPlural: 'Ziele',
      labelSingular: 'Singular',
      labelPlural: 'Plural',
    },
    confidence: {
      title: 'Confidence Level',
      subTitle: 'Du kannst wählen, ob du den Status eines Ziels basierend auf einem 3-stufigen Ampelsystem oder einer Skala von 0 bis 10 anzeigen möchtest. Das Confidence Level beschreibt, wie zuversichtlich man ist, dass das Ziel am Ende eines Zyklus erreicht wird.',
    },
  },
  spaceNavMenu: {
    createTitle: 'Neue {title} erstellen',
    editTitle: '{title} editieren',
    createSubspace: 'Untergruppe erstellen',
    move: 'Verschieben',
    pin: 'Zur Seitenleiste hinzufügen',
    unpin: 'Von Seitenleiste entfernen',
    settings: '{title} Einstellungen',
  },
  moveSpaceCard: {
    dropdownTitle: 'Gruppe verschieben',
    move: 'Verschieben',
    placeholder: 'Keine Obergruppe ausgewählt',
  },
  goalInsights: {
    user: 'Mitarbeiter',
    groupBy: 'Gruppieren nach',
  },
  goalInsightsTable: {
    user: 'Mitarbeiter',
    publicGoals: 'Öffentliche {title}',
    lastUpdate: 'Letztes Update',
    goalsByStatus: '{title} nach {statusType}',
    progress: 'Fortschritt',
    confidence: 'Ø Score',
    confidenceDistribution: '{title}-Verteilung',
    progressExpectedValueAbbreviation: 'EW',
    progressExpectedValueTooltip: 'Erwarteter Wert',
    subOfSubGoals: 'Summe aus direkten Zielen und Unterzielen',
  },
  goalInsightsPerformance: {
    statusActive: 'Aktiv',
    statusClosed: 'Geschlossen',
    overview: 'Übersicht',
    overallProgress: 'Gesamtfortschritt',
    averageScore: 'Durchschnittlicher Score',
    goalsAtRisk: 'Gefährdete {title}',
    totalNumberOfGoals: 'Gesamtanzahl der {title}',
    goalsTotal: 'Gesamt:',
    goalsByType: '{title} nach Typ',
    goalsByProgress: '{title} nach Fortschritt: {label}',
    goalsByStatus: '{title} nach {statusLabel}: {label}',
    noGoalType: 'Ohne Typ',
    progress: 'Fortschritt',
    progressChart: 'Durchschnittlicher Fortschritt',
    progressDistribution: 'Verteilung des Fortschritts',
    statusChart: '{title} im Zeitverlauf',
    statusDistribution: '{title}-Verteilung',
    confidenceChart: '{title} im Zeitverlauf',
    confidenceChartHint: 'In der Fortschrittsübersicht werden deine individuellen Fortschrittsschwellen (von 0% bis 100%) in eine vereinfachte Skala von 0 bis 10 umgewandelt.',
    breakdown: 'Breakdown',
    breakdownTable: '{title} Fortschritt gruppiert nach {groupBy}',
  },
  goalInsightsHealth: {
    goToSettings: 'Zu den Einstellungen',
    infobox: {
      text: 'Das Health Dashboard basiert auf den Regeln, die im Einstellungsbereich unter "Typen und Regeln" definiert sind. Mit dem Dashboard können alle Fehler bei deinen Zielen, wie fehlende Eigenschaftswerte, ungültige übergeordnete Ziele oder Messtypen, schnell erkannt und behoben werden.',
      learnMore: 'Mehr erfahren',
      helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/health-dashboard',
    },
    groups: {
      overview: 'Übersicht',
      byGoalType: 'Health Check nach Zieltyp',
      breakdown: 'Breakdown',
    },
    overview: {
      parentType: 'Ziele mit ungültigem übergeordnetem Ziel',
      propertiesRequired: 'Ziele mit fehlenden Eigenschaftswerten',
      measurementTypes: 'Ziele mit ungültigen Messtypen',
    },
    measurementTypes: {
      cardTitle: 'Ziele mit ungültigen vs. gültigen Messtypen',
      cardHelp: 'Dieser Bericht basiert auf den zulässigen Messarten, die unter {settingsTitle} konfiguriert werden können',
      dialogTitle: '{goalType} - Messtypen - {label}',
    },
    automated: {
      cardTitle: 'Automatisiert vs. manuell',
      cardHelp: 'Verteilung von Zielen, die manuell oder automatisch über Datenintegrationen aktualisiert werden.',
      labelAutomated: 'Automatisiert',
      labelManual: 'Manuell',
      dialogTitle: '{goalType} - Integrationen - {label}',
    },
    parentType: {
      cardTitle: 'Ziele mit ungültigem vs. gültigem übergeordnetem Ziel',
      cardHelp: 'Dieser Bericht basiert auf den Regeln für die Eigenschaft "Übergeordnetes Ziel", die unter {settingsTitle} konfiguriert werden können.',
      labelWrong: 'Ungültiges übergeordnetes Ziel',
      labelRight: 'Gültiges übergeordnetes Ziel',
      dialogTitle: '{goalType} - Übergeordnetes Ziel - {label}',
    },
    propertiesRequired: {
      cardTitle: 'Ziele mit fehlenden vs. bestehenden Eigenschaftswerten',
      cardHelp: 'Dieser Bericht zeigt die Anzahl der Ziele mit fehlenden vs. vorhandenen Eigenschaftswerten. Der Bericht basiert auf den Eigenschaften, die als Pflichtfeld gekennzeichnet sind. Diese können unter {settingsTitle} konfiguriert werden.',
      labelWrong: 'Fehlend',
      labelRight: 'Vorhanden',
      dialogTitle: '{goalType} - Eigenschaftswerten - {label}',
    },
    breakdown: {
      cardTitle: 'Health-Check gruppiert nach {groupBy}',
      table: {
        publicGoals: 'Anzahl der Ziele',
        invalidParents: 'Ungültiges übergeordnetes Ziel',
        invalidProperties: 'Fehlende Eigenschaftswerte',
        invalidMeasurementTypes: 'Ungültige Messtypen',
      },
    },
  },
  singleMetricCard: {
    expectedValue: 'erwarteter Wert: {expectedValue}',
    expectedValueRange: 'Werte zwischen {from} und {to}',
  },
  accessGroupForm: {
    defaultGroupTitle: 'Mitglied',
    defaultGroupDescription: 'Diese Zugangsgruppe beinhaltet alle Mitarbeiter dieser Organisation.',
    accessGroupsTitle: 'Zugangsgruppe',
    memberTitle: 'Rechteinhaber',
    accountTitle: 'Account Allgemein',
    goalsTitle: 'Ziele',
    updatesTitle: 'Check-ins',
    formsTitle: 'Umfragen',
    usersTitle: 'Benutzer',
    groupNameLabel: 'Name',
    accountWriteAccess: 'Verwalten der Accounteinstellungen (Admin)',
    accessGroupWriteAccessLabel: 'Verwalten von Zugangsgruppen (Admin)',
    formWriteAccessLabel: 'Verwalten von Umfragen',
    formTemplateWriteAccessLabel: 'Verwalten von Umfragevorlagen',
    userWriteAccessLabel: 'Verwalten von Nutzern',
    propertyWriteAccessLabel: 'Verwalten von Eigenschaften',
    spaceWriteAccessLabel: 'Verwalten von Teams',
    publicSavedViewAccessLabel: 'Verwalten von öffentlichen Ansichten',
    referenceForeignEntityLabel: {
      tooltip: 'Für Team- und Benutzereigenschaften auf Zielen können Benutzer aus allen Workspaces und Benutzern auswählen (nicht nur aus ihren Teams und Teammitgliedern).',
      label: 'Kann aus allen Teams und deren Mitgliedern wählen',
    },
    createNewAccessGroup: 'Neue Zugangsgruppe erstellen',
    updateAccessGroupTitle: 'Zugangsgruppe bearbeiten',
    accessGroupsHeading: 'Zugangsgruppen',
    formsHeading: 'Umfragen',
    userHeading: 'Nutzer',
    selectUserHeading: 'Nutzer wählen',
    userLabel: 'Nutzer auswählen',
    deletePrompt: 'Soll diese Zugangsgruppe wirklich gelöscht werden?',
    goalCycleWriteAccessLabel: 'Verwalten von Zieleinstellungen und Zyklen',
    createGoalAccessLabel: 'Kann Ziele erstellen',
    goalSuperAccessLabel: 'Uneingeschränkter Zugriff auf alle Ziele',
    goalDashboardViewAccessLabel: 'Hat Zugriff auf Progress- und Health Dashboards',
    adminWarning: 'Die Admin-Gruppe darf nicht ohne Rechteinhaber sein.',
    instantFeedbackTemplateAccess: 'Verwalten von Feedbackvorlagen',
    updateAdminAccess: 'Anlegen und editieren von Check-in Vorlagen und Einstellungen',
    writeDashboardsAndReports: 'Kann Dashboards und Berichte erstellen',
    dashboardSuperAccess: 'Uneingeschränkter Zugriff auf alle Dashboards',
  },
  accessGroupList: {
    nameLabel: 'Name',
    numberOfMembersLabel: 'Gruppenmitglieder',
    actions: 'Aktionen',
    editGroup: 'Gruppe editieren',
  },
  breadcrumbs: { items: 'Elemente' },
  goalContextMenu: {
    cannotDelete: 'Du bist nicht berechtigt dieses {title} zu bearbeiten.',
    duplicateToCycle: 'Zu Zyklus duplizieren',
    addBeneath: 'Unterziel erstellen',
    cannotAddToDashboard: 'Only goals with continuous progress measurement can be added to a dashboard.',
  },
  contextMenu: { editProperty: 'Eigenschaft bearbeiten' },
  goalsContextMenu: {
    cannotDeleteMultiple: 'Du bist nicht berechtigt diese {title} zu bearbeiten.',
    deleteHint: 'Du bist nicht berechtigt alle ausgewählten {title} zu bearbeiten.',
    changeAccess: 'Zugriffsrechte ändern',
    changeAccessHint: 'Du bist nicht berechtigt die Zugriffsrechte aller ausgewählten {title} zu bearbeiten.',
    addToDashboard: 'Zum Dashboard hinzufügen',
    addToDashboardHint: 'Nicht alle ausgewählten {title} sind auf Dashboards erlaubt.',
    selected: 'ausgewählt',
    selectWithSubgoals: 'Ausklappen und auswählen',
  },
  goalPickerV2: {
    title: '{title} auswählen',
    selected: 'ausgewählt',
  },
  goalActivityPicker: { title: 'Updates auswählen' },
  compareToPrevious: {
    title: 'Vergleichen zu',
    customDate: 'Benutzerdefiniertes Datum',
    pickADate: 'Datum wählen',
    oneDayAgo: 'Vor einem Tag',
    oneWeekAgo: 'Vor einer Woche',
    oneMonthAgo: 'Vor einem Monat',
    none: 'Kein',
  },
  colorPicker: {
    title: 'Farbe wählen',
    default: 'Hellgrau',
    grey: 'Grau',
    brown: 'Braun',
    orange: 'Orange',
    yellow: 'Gelb',
    green: 'Grün',
    blue: 'Blau',
    purple: 'Lila',
    pink: 'Pink',
    red: 'Rot',
  },
  savedViewInfo: {
    filterLabel: 'Filter für',
    propertyLabel: 'Eigenschaften für',
    sortLabel: 'Sortierung für',
    privateTooltip: 'Dies ist eine private Ansicht, welche nur für dich sichtbar ist.',
    disabledInfo: 'Du hast nicht genug Rechte um öffentliche Ansichten editieren zu können.',
  },
  goalsCascadeTable: {
    emptyGoalList: 'Keine {title} vorhanden',
    addTooltip: 'Klicke, um ein Unterziel zu erstellen',
    moreTooltip: 'Klicke, um Aktionen anzuzeigen',
  },
  editorImage: { original: 'Original anzeigen', addAnImage: 'Bild auswählen' },
  editorFile: { addAFile: 'Datei auswählen' },
  fileUpload: {
    fileTooBig: 'Datei ist zu groß (max. 20 MB)',
    featureDisabled: 'Hochladen von Dateien ist für dieses Konto deaktiviert.',
  },
  myNotifications: {
    title: 'Benachrichtigungen',
    channelHeading: 'Kanäle',
    msTeamsHint: 'Erhalte Benachrichtigungen via Microsoft Teams.',
    slackHint: 'Erhalte Benachrichtigungen via Slack.',
    emailHint: 'Erhalte Benachrichtigungen via E-Mail.',
    emailTitle: 'E-Mail',
    notificationTypes: 'Mitteilungen',
    directMessageTitle: 'Direkte Nachrichten',
    directMessageSubTitle: 'Bekomme eine Benachrichtigung, wenn jemand auf deine Kommentare oder dein Update antwortet oder du mit {\'@\'}username erwähnt wirst.',
    checkinReminderTitle: 'Erinnerung für Check-ins',
    checkinReminderSubTitle: 'Erhalte eine Benachrichtigung, wenn es Zeit ist, dein Check-in durchzuführen.',
    goalSubscriptionTitle: 'Abonnierte Ziele',
    goalSubscriptionSubTitle: 'Erhalte eine Benachrichtigung, wenn es neue Updates für deine abonnierten Ziele gibt.',
    goalReferenceTitle: 'Erwähnung in einem Ziel',
    goalReferenceSubTitle: 'Erhalte eine Benachrichtigung, wenn du in einem Ziel erwähnt wirst.',
    disconnect: 'Verbindung trennen',
    confirmMicrosoftDisconnect: 'Möchtest du die Verbindung zu Microsoft Teams wirklich trennen?',
  },
  checkinOnboardingModal: {
    onboardingTitle: 'Was sind Check-ins?',
    onboardingDescription: 'Ein regelmäßiges Check-in ist der effektivste Faktor jedes OKR-Prozesses. Es ist ein einfaches Tool, um Ziele und OKRs im Bewusstsein zu behalten, alle sehen zu lassen, woran andere arbeiten, Fortschritte und neue Erkenntnisse zu diskutieren und aufzudecken, wo gegenseitige Unterstützung nötig ist. Es gewährleistet kontinuierliches Feedback und Lernen sowie ein hohes Maß an Beteiligung und Verantwortung in Teams.',
    createCheckin: 'Check-in erstellen',
    explore: 'Überspringen und selbst erkunden',
  },
  demoDataModal: {
    title: 'Willkommen bei Mooncamp 👋',
    description: 'Lerne Mooncamp in nur <b>5 Minuten</b> kennen:',
    takeTour: 'Tour starten',
    skipTour: 'Tour überspringen',
    introVideo: 'https://www.loom.com/embed/8aa2f037915c4a0b9d5a8d7a6fea752d',
  },
  exportButton: {
    buttonText: 'CSV exportieren',
    exportingCSV: 'Ziele werden exportiert...',
    tooManyRequests: 'Es ist nur ein Exportiervorgang pro Minute zulässig. Versuch es später noch einmal.',
  },
  propertyApplication: {
    user: 'Mitarbeiter',
    goal: 'Ziele',
    update: 'Check-ins',
    space: 'Spaces',
  },
  mContextMenu: {
    paragraph: {
      title: 'Text',
      subTitle: 'Schreibe einfachen Text.',
    },
    heading1: {
      title: 'Überschrift 1',
      subTitle: 'Große Überschrift.',
    },
    heading2: {
      title: 'Überschrift 2',
      subTitle: 'Mittelgroße Überschrift.',
    },
    heading3: {
      title: 'Überschrift 3',
      subTitle: 'Kleine Überschrift.',
    },
    link: {
      title: 'Website Link',
      subTitle: 'Einen Link zu einer Website speichern.',
    },
    blockquote: {
      title: 'Zitat',
      subTitle: 'Füge ein Zitatblock ein.',
    },
    image: {
      title: 'Bild',
      subTitle: 'Ein Bild hochladen.',
    },
    file: {
      title: 'Datei',
      subTitle: 'Eine Datei hochladen.',
    },
    bulletList: {
      title: 'Aufzählung',
      subTitle: 'Füge eine Liste mit Aufzählungspunkten ein.',
    },
    orderedList: {
      title: 'Geordnete Liste',
      subTitle: 'Füge eine nummerierte Liste ein.',
    },
    codeBlock: {
      title: 'Code',
      subTitle: 'Füge einen Codeblock ein.',
    },
    mention: {
      title: 'Eine Person erwähnen',
      subTitle: 'Erwähne ein Mitglied des Workspaces.',
    },
    goalMention: {
      title: 'Verlinke {plural}',
      subTitle: 'Erstelle einen Link zu einem {singular}.',
    },
    todoList: {
      title: 'To-Do-Liste',
      subTitle: 'Aufgaben mit To-Do-Listen festhalten.',
    },
    noItems: 'Kein Block gefunden.',
  },
  mFloatingMenu: { backslashHint: 'Tippe \'/\' für Befehle' },
  type: {
    goal: 'Ziel',
    user: 'Person',
    propertyOption: 'Team',
  },
  mobileMenuComponent: { turnInto: 'Verwandeln in', add: 'Hinzufügen' },
  okta: {
    email: 'Okta Nutzername',
    enterEmail: 'Okta Nutzername eingeben',
  },
  globalNewBtn: {
    update: 'Check-in',
    requestFeedback: 'Feedback anfordern',
    noItemFound: 'Kein Element gefunden',
  },
  publishButton: {
    header: 'Für das Web freigeben',
    description: 'Link veröffentlichen und mit allen teilen',
    copyLink: 'Link kopieren',
    copyEmbedCode: 'Embed-Code kopieren',
  },
  publishedView: {
    notPublished: {
      title: 'Seite nicht gefunden.',
      description: 'Diese Seite wird nicht mehr geteilt.',
    },
  },
  helpButton: {
    contactSupport: 'Support kontaktieren',
    chatWithSales: 'Sales kontaktieren',
    whatsNew: 'Produktneuheiten',
    productFeedback: 'Feedback geben',
    placeholderFeedback: 'Hast du Feedback  oder wünschst du dir eine zusätzliche Funktion? Bitte beschreibe dein Feedback oder deine Wünsche so detailliert wie möglich.',
    companyInfo: { emptyTitle: 'Unternehmensinformationen' },
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}',
  },
  formTags: {
    heading: 'Kategorien',
    description: 'Kategorien werden benutzt, um Vorlagen besser zu strukturieren.',
  },
  feedbackInquiry: {
    title: 'Nach Feedback fragen',
    recipients: 'Von',
    messageLabel: 'Nachricht',
    typeYourMessage: 'Schreibe los...',
    addQuestions: 'Frage hinzufügen',
    emptyQuestion: 'Eigene Frage',
    questionFromTemplate: 'Aus Vorlage einfügen',
    questionsLabel: 'Fragen',
    created: 'Feedback-Anfrage erfolgreich versendet',
  },
  giveFeedbackForm: {
    title: 'Feedback geben',
    viewFeedback: 'Feedback anschauen',
    feedbackFrom: 'Feedback von {actor}',
    recipient: 'Empfänger',
    furtherRemarks: 'Weitere Anmerkungen (optional)',
    messageLabel: 'Dein Feedback',
    typeYourMessage: 'Antwort schreiben...',
    from: 'Von',
    answer: 'Antwort',
    yourMessage: 'Deine initiale Nachricht',
  },
  feedbackAnswerItem: { placeholder: 'Antwort schreiben...' },
  identityAndProvisioning: {
    security: { heading: 'Zugang' },
    restrictLogin: {
      passwordLoginTitle: 'Einloggen über Passwort erlauben',
      passwordLoginSubTitle: 'Nutzer dürfen sich mit einem Passwort einloggen',
      deactivatePasswordTitle: 'Bist du dir sicher, dass du Nutzern verbieten möchtest, sich über ein Passwort einloggen zu können?',
      activatePasswordTitle: 'Bist du dir sicher, dass du Nutzern erlauben möchtest, sich über ein Passwort einloggen zu können?',
      googleLoginTitle: 'Einloggen über Google erlauben',
      googleLoginSubTitle: 'Nutzer dürfen sich mit ihrem Google-Konto einloggen',
      deactivateGoogleLoginTitle: 'Bist du dir sicher, dass du Nutzern verbieten möchtest, sich über ihr Google-Konto einloggen zu können?',
      activateGoogleLoginTitle: 'Bist du dir sicher, dass du Nutzern erlauben möchtest, sich über ihr Google-Konto einloggen zu können?',
      microsoftLoginTitle: 'Einloggen über Microsoft erlauben',
      microsoftLoginSubTitle: 'Nutzer dürfen sich mit ihrem Microsoft-Konto einloggen',
      deactivateMicrosoftLoginTitle: 'Bist du dir sicher, dass du Nutzern verbieten möchtest, sich über ein Microsoft-Konto einloggen zu können?',
      activateMicrosoftLoginTitle: 'Bist du dir sicher, dass du Nutzern erlauben möchtest, sich über ein Microsoft-Konto einloggen zu können?',
    },
    userProvisioning: {
      enable: 'Bist du dicher sicher, dass du die Provisionierung aktivieren möchtest?',
      enableContent: 'Die Provisionierung erlaubt es Nutzer zwischen Mooncamp und Dritt-Anbietern (wie zB Okta, oder Microsoft Azure) zu synchronisieren.',
      disable: 'Bist du dir sicher, dass du die Provisionierung deaktivieren möchtest?',
      disableContent: 'Die Provisionierung wird deaktiviert. Die Konfiguration geht verloren und muss neu eingerichtet werden.',
    },
    saml: {
      heading: 'SAML',
      ssoUrl: 'SSO-URL',
      entityId: 'Entity-ID',
      certificate: 'Zertifikat',
      save: 'Speichern',
    },
    contactLink: '@:{\'general.websiteBaseUrl\'}/kontakt/',
  },
  integrationSettings: {
    title: 'Integrationen',
    subTitle: 'Aktivieren oder Deaktivieren von Integrationen für deinen Account',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/datenintegrationen-mit-zielen-und-okrs-verknpfen',
    help: 'Erfahre mehr darüber, wie du Integrationen einrichten kannst.',
    messaging: { title: 'Benachrichtigungen' },
    dataIntegrations: { title: 'Datenintegrationen' },
    calendarIntegrations: { title: 'Kalenderintegrationen' },
    slack: 'Slack',
    slackSubTitle: 'Erhalte persönliche Benachrichtigungen in Slack.',
    msTeams: 'Microsoft Teams',
    msTeamsSubTitle: 'Erhalte persönliche Benachrichtigungen in MS Teams.',
    manage: 'Verwalten',
    excelSubTitle: 'Verbinde deine Ziele mit einer Zelle in einem Excel Online Dokument.',
    sheetsSubTitle: 'Verbinde deine Ziele mit einer Zelle in einem Google Sheets Dokument.',
    hubspotSubTitle: 'Verbinde deine Ziele mit einer Hubspot Metrik.',
    asanaSubTitle: 'Verbinde deine Ziele mit Asana Tasks.',
    jiraSubTitle: 'Verbinde deine Ziele mit Jira Tasks.',
    salesforceSubTitle: 'Verbinde deine Ziele mit Salesforce-Berichten.',
    msPowerBiSubTitle: 'Verbinde deine Ziele mit Microsoft Power BI Datasets.',
    msPlannerSubTitle: 'Verbinde deine Ziele mit Microsoft Planner Tasks.',
    install: 'Installieren',
    installed: 'Installiert',
    syncDays: 'Synchronisierung',
    syncDaysTitle: 'Wochentage',
    syncDaysDescription: 'Wähle die Wochentage, an denen die Daten synchronisiert werden sollen.',
  },
  msTeamsInstallHint: {
    link: '@:{\'general.helpCenterBaseUrl\'}/microsoft-teams-integration',
    msTeamsGuide: 'So verbindest du Mooncamp mit MS Teams',
  },
  msTeamsSwitch: {
    uninstallHint: 'Um die Verbindung zwischen Mooncamp und MS Teams zu trennen, musst du die Mooncamp App in MS Teams deinstallieren',
    description: 'Aktiviere die MS Teams-Integration, damit Benutzer MS Teams und Mooncamp verbinden können.',
  },
  slackSwitch: { description: 'Verbinde Slack mit deinem Mooncamp Account.' },
  dataSourceDetails: {
    back: 'Zurück zu den Integrationen',
    connections: 'Verbindungen',
    connectionsDescription: 'Konfiguriere {type}-Verbindungen, die von Mooncamp-Benutzern verwendet werden können, um ihre Ziele zu verknüpfen und den Fortschritt zu aktualisieren.',
    jira: {
      help: 'Erfahre mehr über die @:{\'dataSources.jira\'}-Integration.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/jira-integration',
      description: 'Verbinde deine Ziele mit @:{\'dataSources.jira\'} Tasks.',
    },
    sheets: {
      help: 'Erfahre mehr über die @:{\'dataSources.sheets\'}-Integration.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/google-sheetsexcel-online-integration',
      description: 'Verbinde deine Ziele mit Zellen eines @:{\'dataSources.sheets\'} Dokuments.',
    },
    excel: {
      help: 'Erfahre mehr über die @:{\'dataSources.excel\'}-Integration.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/google-sheetsexcel-online-integration',
      description: 'Verbinde deine Ziele mit Zellen eines @:{\'dataSources.excel\'} Dokuments.',
    },
    asana: {
      help: 'Erfahre mehr über die @:{\'dataSources.asana\'}-Integration.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/asana-integration',
      description: 'Verbinde deine Ziele mit @:{\'dataSources.asana\'} Tasks.',
    },
    hubspot: {
      help: 'Erfahre mehr über die @:{\'dataSources.hubspot\'}-Integration.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/hubspot-integration',
      description: 'Verbinde deine Ziele mit @:{\'dataSources.hubspot\'} Metriken.',
    },
    salesforce: {
      help: 'Erfahre mehr über Integration.',
      helpLink: 'https://help.mooncamp.com/en/connect-data-integrations-to-goals-and-okrs',
      description: 'Verbinde deine Ziele mit @:{\'dataSources.salesforce\'} Berichten.',
    },
    msPowerBi: {
      help: 'Erfahre mehr über die @:{\'dataSources.msPowerBi\'}-Integration.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/microsoft-power-bi-integration',
      description: 'Verbinde deine Ziele mit @:{\'dataSources.msPowerBi\'} Datasets.',
    },
    msPlanner: {
      help: 'Erfahre mehr über die @:{\'dataSources.msPlanner\'}-Integration.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/microsoft-planner-integration',
      description: 'Verbinde deine Ziele mit @:{\'dataSources.msPlanner\'} Tasks.',
    },
  },
  instantFeedbackTemplateGallery: {
    selected: 'Hinzufügen',
    template: 'Vorlagen',
    uncategorized: 'Ohne Kategorie',
  },
  templateDetails: { noQuestions: 'Keine Fragen vorhanden' },
  oauth: { missingOpener: 'Sieht aus, als wäre etwas schief gegangen.', retry: 'Erneut versuchen' },
  fileExplorer: { empty: 'Dieser Ordner ist leer.', root: 'Mein Laufwerk' },
  accessEditor: { hint: 'Der Ersteller hat immer vollen Zugriff.' },
  goalCascadeParents: { more: 'weiteres | weitere' },
  userProvisioning: {
    heading: 'Mitglieder Provisionierung',
    subheading: 'Synchronisiere Mooncamp mit einem Dritt-Anbieter',
    token: 'API Token',
    activate: 'Aktivieren',
    actionTitle: 'Mitglieder Provisionierung aktivieren',
    actionSubtitle: 'Aktivieren der Mitglieder Provisionierung (SCIMv2)',
    learnAbout: 'Erfahre mehr über SCIM',
    attributeMapping: 'Attribute Mapping',
    attributeMappingDescription: 'Map SCIM Attributes to properties inside Mooncamp',
    addAttributeMapping: 'Attribute Mapping hinzufügen',
    property: 'Mooncamp Eigenschaft',
    space: 'Space',
    option: 'Segment',
    attribute: 'SCIM Attribute',
    update: 'Update',
    confirmAttributeDelete: 'Bist du sicher, dass du dieses Attribute Mapping entfernen willst?',
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/azure-user-provisioning-setup-scim',
    scimAttributeValues: 'SCIM Attribut-Werte ',
    scimAttributeType: 'SCIM Attribut-Typ',
    moreItems: 'mehr',
    firstName: 'Vorname',
    lastName: 'Nachname',
    language: 'Sprache',
    email: 'Email',
    immutableField: 'Dieses Feld ist immer erforderlich und kann daher nicht geändert werden.',
    syncSuccess: 'Mitglieder erfolgreich synchronisiert',
    sync: 'Synchronisieren',
    syncLoading: 'Synchronisierung läuft...',
    tooManySyncRequests: 'Es ist nur ein Synchronisierungsvorgang pro fünf Minuten zulässig. Versuch es später noch einmal.',
  },
  savedViewPrompt: { prompt: 'Du hast ungespeicherte Änderungen, willst du diese verwerfen?' },
  alignedItem: { weightDescription: 'Gewicht wählen' },
  productTour: {
    author: 'von Mooncamp',
    previous: 'Zurück',
    next: 'Weiter',
    done: 'Fertig',
    of: 'von',
    home: {
      masteringMooncampStep: {
        heading: 'Mit Mooncamp deine Strategie umsetzen',
        content: `Das <b>Ressourcen-Center</b> ist deine zentrale Anlaufstelle um
            <ul>
            <li>Mooncamp Tutorials anzuschauen</li>
            <li>Hilfe vom Support-Team zu bekommen</li>
            <li>das Helpcenter aufzurufen</li>
            <li>mehr über Strategie  und OKRs zu lernen</li>
            </ul>`,
      },

      goalExplorerStep: {
        heading: 'Ziele-Explorer',
        content: 'Im Ziele-Explorer kannst du alle Ziele durchsuchen und eigene Ansichten mit individuellen Filtern erstellen.',
      },
      planningStep: {
        heading: 'Plannings',
        content: 'In den Plannings kannst du mit deinem Team Ziele im Entwurfsmodus erstellen, mit anderen abstimmen und am Ende für alle veröffentlichen.',
      },
      goalCycleStep: {
        heading: 'Ziele gehören zu einem Zielzyklus',
        content: 'Ziele oder OKRs gehören immer zu mindestens einem Zielzyklus. Hier kannst du zwischen den Zyklen wechseln.',
      },
      checkInStep: {
        heading: 'Regelmäßige Check-ins',
        content: 'Check-ins helfen euch, eure Ziele stets aktuell zu halten. Durch einen festgelegten Zeitplan etabliert ihr einen regelmäßigen Prozess, der alle Teammitglieder an ein Statusupdate erinnert.',
      },
      dashboardStep: {
        heading: 'Dashboards',
        content: 'Mit unseren Dashboards kannst du den Fortschritt eurer Ziele mühelos analysieren und am Ende des Zyklus die Gesamtentwicklung präzise bewerten.',
      },
      personalHomePageStep: {
        heading: 'Dein persönlicher Bereich',
        content: 'Hier kannst du deine Ziele, Check-ins und Updates einsehen. Durch individuelle Ansichten lässt sich dieser Bereich präzise an deine Bedürfnisse anpassen.',
      },
      settingsStep: {
        heading: 'Einstellungen',
        content: 'Aktiviere hier Integrationen, verwalte Teams und Mitglieder, lege Berechtigungen fest oder greife auf erweiterte Funktionen zu.',
      },
      spacesStep: {
        heading: 'Teamspaces',
        content: 'Jedes Team verfügt über einen eigenen Bereich zur Darstellung seiner Ziele und Check-ins. \n\nDu kannst hier die Organisationsstruktur einsehen und deine wichtigsten Teams zur Seitenleiste hinzufügen.',
      },
    },
    checkins: {
      step1: {
        heading: 'Dies ist ein Check-in',
        content: 'Check-ins aller Teams werden standardmäßig in diesem Feed angezeigt. Check-ins, die zu einem Team gehören, findest du auch auf deinen Teamseiten.',
      },
      step2: {
        heading: 'Erstelle neue Check-ins hier',
        content: 'Du kannst hier ein neues (leeres) Check-in  erstellen oder auf Basis einer vordefinierten Vorlage ausfüllen.',
      },
      step3: {
        heading: 'Erstelle und editiere Zeitpläne',
        content: 'Hier kannst du neue Check-In-Zeitpläne erstellen oder bestehende bearbeiten. Abhängig von deinen Benachrichtigungseinstellungen erhalten die Empfänger eine Benachrichtigung per E-Mail, Slack oder MS Teams.',
      },
    },
  },
  discoverMooncamp: {
    title: 'Erfahre warum Mooncamp einzigartig ist',
    learnMore: 'Mehr erfahren',
    properties: {
      heading: 'Passe alles mit benutzerdefinierten Eigenschaften an',
      description: 'Eigenschaften sind eine der spannendsten Funktionen von Mooncamp, die es dir erlaubt, Mooncamp vollständig an deine Bedürfnisse, deine internen Prozesse und deine Unternehmenssprache anzupassen.',
    },
    powerful: {
      heading: 'Einfach, aber mächtig',
      description: 'Einfaches und intuitives Design, das die Akzeptanz in allen Teams fördert.',
    },
    integrations: {
      heading: 'Microsoft Teams und Slack Integration',
      description: 'Mooncamp kann in MS Teams oder Slack eingebettet werden, sodass du direkt in deinen Lieblingstools Benachrichtigungen erhalten und OKRs verwalten kannst.',
    },
    okrRules: {
      heading: 'Passe das Zielsystem mit Regeln an eure Prozesse an',
      description: 'Du kannst in Mooncamp nicht nur deine eigenen Zieltypen (wie z. B. {tag1}, {tag2} oder {tag3}) erstellen, sondern diese auch mit selbst definierten Regeln versehen. Regeln können z. B. sein:',
      bp1: '{tag1} dürfen nur auf {tag2} einzahlen',
      bp2: '{tag1} dürfen nur auf {tag2} einzahlen',
      bp3: '{tag1} müssen immer einen {tag2} haben',
      bp4: '{tag1} dürfen nur über {tag2} gemessen werden',
      keyResults: 'Key Results',
      objectives: 'Objectives',
      strategicPillars: 'Strategische Leitplanken',
      owner: 'Owner',
      initiatives: 'Initiativen',
      openDone: 'Offen/Erledigt',
    },
  },
  peekView: {
    peekModes: 'Ansichten',
    sidePeek: 'Seitliche Ansicht',
    centerPeek: 'Zentrierte Ansicht',
    productTour: {
      heading: 'Neue Seitenansicht',
      body: 'Ziel-Seiten öffnen jetzt standardmäßig in der neuen Seitenansicht. Wenn du die alte zentrierte Ansicht bevorzugst,'
          + ' kannst du hier zurückwechseln. Diese Einstellung gilt nur für dich und beeinflusst andere Nutzer:innen nicht.',
    },
  },
  afterAccountSpaceDemoModal: {
    congratulations: 'Geschafft!',
    description: 'Du hast die Produkttour von Mooncamp erfolgreich abgeschlossen!',
    goToOnboarding: 'Zum Onboarding Guide',
    explore: 'Selbst erkunden',
  },
  afterCheckinTourModal: {
    congratulations: 'Glückwunsch!',
    description: 'Du hast die Checkins-Tour von Mooncamp erfolgreich abgeschlossen!',
    cta: 'Als nächstes erstelle dein erstes Check-in:',
    create: 'Erstelle dein erstes Check-in',
    explore: 'Überspringen und selbst erkunden',
  },
  updateDivider: {
    lastWeek: 'Vergangene Woche',
    weekOf: 'Woche vom {date}',
  },
  mMentionMenu: {
    users: 'Personen',
    noItems: 'Keine Personen oder {title} gefunden.',
    noUsers: 'Keine Personen gefunden',
    noGoals: 'Keine {title} gefunden.',
    goalsTitle: 'Verlinke {title}',
  },
  goalMentionComponent: { hint: '{title} wurde entweder gelöscht oder du besitzt kein Leserecht.' },
  goalActivityForm: {
    referencedHint: 'Referenz auf ein vergangenes Update',
    done: 'Erledigt',
  },
  browserNotSupported: {
    title: 'Browser nicht unterstützt',
    hint: 'Du verwendest einen veralteten Browser, der von Mooncamp nicht unterstützt wird. Du kannst eines der folgenden Programme verwenden: Chrome, Edge, Firefox oder Safari.',
  },
  durationUnit: {
    minute: 'Minute | Minuten',
    hour: 'Stunde | Stunden',
    day: 'Tag | Tage',
  },
  afterUnit: {
    minute: 'Minute | Minuten',
    hour: 'Stunde | Stunden',
    day: 'Tag | Tagen',
  },
  reminderEditor: { after: 'Nach' },
  reminderList: {
    noReminders: 'Keine Erinnerungen vorhanden',
    add: 'Erinnerung hinzufügen',
  },
  plans: {
    title: 'Pläne und Rechnungen',
    enterprise: 'Enterprise',
    pro: 'Professional',
    business: 'Essential',
    short: {
      enterprise: 'Enterprise',
      pro: 'Pro',
      business: 'Essential',
    },
    learnMore: 'Erfahre mehr über unsere Pläne.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/unterschiede-essential-und-professional-plan',
    portalButton: 'Rechnungen verwalten',
  },
  pricingTable: {
    downgrade: 'Downgrade',
    upgrade: 'Upgrade',
    perMonthFlat: 'pro Monat (Festpreis)',
    perUserPerMonth: 'pro Nutzer pro Monat',
    currentPlan: 'Aktueller Plan',
    letsTalk: 'Kontaktiere uns',
    talkToSales: 'Vertrieb kontaktieren',
    workspace: 'Workspace',
    numberOfUsers: 'Maximale Anzahl der Nutzer',
    currentPlanHint: 'Dies ist dein aktueller Plan.',
    planDowngradeHint: 'Wenn du deinen Plan herabstufen möchtest, wende dich bitte an unser Vertriebsteam.',
    unlimited: 'Unbegrenzt',
    goalsAndOkrs: 'Ziele und OKRs',
    unlimited_okrs: 'Unbegrenzte Ziele, Initiativen, und Hierarchielevel',
    customizability: 'Komplett anpassbares Zielsystem',
    private_goals: 'Private Ziele und OKRs',
    messengers: 'Slack und Microsoft Teams Integration',
    integrations: 'Datenintegrationen (Jira, Asana, Hubspot, ...)',
    planningSpaces: 'OKR Plannings',
    planningSpacesTooltip: 'Dedizierte Bereiche für deine Teams, um Zielentwürfe für den nächsten Zyklus zu erstellen.',
    goalTree: 'Baumansicht für Ziele',
    goalTreeTooltip: 'Verschaffe allen einen umfassenden Überblick über die Unternehmensstrategie und die Ausrichtung von Zielen und OKRs.',
    okrRules: 'Regelsystem für Ziele und OKRs',
    okrRulesTooltip: 'Lege Regeln fest, um dein eigenes OKR-System und dessen Hierarchie zu konfigurieren, Felder als Pflichtfelder zu markieren und diverse andere Einstellungen vorzunehmen.',
    analytics: 'Dashboards & Analysen',
    dashboards: 'Progress Dashboard',
    premiumDashboards: 'Health Dashboard',
    premiumDashboardsTooltip: 'Ein umfassender Qualitätscheck für alle Ziele deiner Organisation.',
    privateCustomDashboards: 'Private Dashboards',
    privateCustomDashboardsTooltip: 'Dashboards bieten vielseitige Möglichkeiten, um deine Strategie oder den OKR-Prozess zu unterstützen, wie zum Beispiel Quarterly Business Reviews, wöchentliche Managementberichte, Vision/Mission/Werte-Boards, Unternehmens-KPIs und viele mehr.',
    publicCustomDashboards: 'Geteilte Dashboards',
    publicCustomDashboardsTooltip: 'Dashboards bieten vielseitige Möglichkeiten, um deine Strategie oder den OKR-Prozess zu unterstützen, wie zum Beispiel Quarterly Business Reviews, wöchentliche Managementberichte, Vision/Mission/Werte-Boards, Unternehmens-KPIs und viele mehr.',
    multiAlignment: 'Multi-Alignment von OKRs',
    multiAlignmentTooltip: 'Ein Ziel kann auf zwei oder mehrere Ziele einzahlen.',
    premiumIntegrations: 'Premium-Datenintegrationen (Salesforce, ...)',
    premiumIntegrationsTooltip: 'Kundenspezifische Integrationen können nach Absprache entwickelt werden.',
    tvMode: 'Öffentliche Links zu Views teilen (TV Modus)',
    tvModeTooltip: 'Teile einen öffentlichen Link zu einer Zieleansicht mit Personen außerhalb deines Workspaces.',
    goalWeights: 'Gewichtung von Zielen',
    goalWeightsTooltip: 'Weise Key Results oder Unterzielen Gewichte zu.',
    checkIns: 'Check-ins',
    reminders: 'Check-ins und Erinnerungen erstellen',
    checkInTemplates: 'Anpassbare Templates',
    checkInMessengers: 'Benachrichtigungen in Slack und Microsoft Teams',
    admin: 'Administration und Sicherheit',
    adminGoogleSso: 'Google Single Sign-On',
    adminMsSso: 'Microsoft Single Sign-On',
    customSaml: 'SAML-basiertes SSO',
    scimProvisioning: 'SCIM Provisioning',
    support: {
      title: 'Support',
      helpcenter: 'Self-Service Helpcenter',
      level: 'Support Level',
      levelStandard: 'Standard',
      levelPriority: 'Priority',
      levelPriorityTooltip: 'Wir bieten Priority Support für alle Accounts mit einem jährlichen Auftragsvolumen von 10.000 € oder höher an.',
      onboarding: 'Personalisiertes Onboarding',
      rollout: 'Persönliche Begleitung bei Implementierung und Rollout',
      sla: 'Service Level Agreement',
      account_manager: 'Eigener Account Manager',
      contract: 'Individuelle Verträge und Rechnungsstellung',
      dpa: 'Individueller AVV',
    },
    upgradeModal: {
      title: 'Workspace Upgrade',
      text: 'Wenn du einen Plan abonnieren möchtest oder Fragen zu Preisen hast, wende dich bitte an unser Vertriebsteam: {mailTo}',
    },
  },
  couponCodeAlert: {
    text: 'Hast du einen Partnercode für einen vorgebuchten Plan? Dann kannst du den Code hier eingeben, um deinen bereits bezahlten Plan freizuschalten.',
    applyCode: 'Partnercode eingeben',
    codeApplied: 'Gutschein erfolgreich angewendet',
    dialog: {
      header: 'Partnercode eingeben',
      placeholder: 'Partnercode eingeben...',
      submit: 'Partnercode anwenden',
      hint: 'Hast du Probleme mit deinem Partnercode?',
      contactSupport: 'Kontaktiere den Kundensupport',
    },
    notFound: 'Wir konnten den eingegebenen Partnercode nicht finden oder er wurde bereits eingelöst.',
  },
  planHint: {
    currentPlan: ' Dein Workspace ist zurzeit auf {currentPlan}.',
    expiresAt: 'Dein Abonnement läuft am {expirationDate} ab.',
    renewsAt: 'Dein Abonnement wird am {expirationDate} erneuert.',
    trial: 'dem Trial-Plan',
    pure: 'dem Pure Partner Deal',
    starter: 'dem Starter-Plan',
    business: 'dem Essential-Plan',
    pro: 'dem Pro-Plan',
    enterprise: 'dem Enterprise-Plan',
    trialExpiresAt: 'Deine Testphase läuft am {expirationDate} ab.',
  },
  redeemCouponCode: {
    placeholder: 'Gib deinen Gutscheincode ein...',
    continueWithoutCoupon: 'Ohne Gutscheincode fortfahren',
  },
  updateSchedules: { allSchedules: 'Alle Zeitpläne' },
  schedulesTable: {
    name: 'Name',
    schedule: 'Kadenz',
    participants: 'Anzahl Teilnehmer',
    template: 'Vorlage',
    participation: 'Teilnahmequote',
    participationHint: 'Teilnahmequote der aktuellen Ausführung.',
    createHeading: 'Zeitplan erstellen',
    editHeading: 'Zeitplan editieren',
    recipientsList: 'Teilnehmer',
    status: 'Status',
  },
  scheduleDetailPageHeader: {
    access: 'Zugang',
    creatorDescription: 'Ersteller dieses Zeitplans',
  },
  participationList: {
    missing: 'Ausstehend',
    done: 'Erledigt',
  },
  scheduleDetailPage: {
    reminders: 'Erinnerungen',
    noReminders: 'Keine vorhanden',
    participationRate: 'Teilnahmequote',
    backToAllSchedules: 'Zurück zu allen Zeitplänen',
    notFound: 'Zeitplan nicht gefunden',
    noSendOut: 'Geplante Benachrichtigungen liegen in der Vergangenheit.',
    nextSendOut: 'Nächste Benachrichtigung geplant am: {date}.',
    sendOutHint: 'Es ist wahrscheinlich, dass diese Benachrichtigung nicht versendet wird, da es etwa 20 Minuten dauert, bis die Benachrichtigung vollständig eingerichtet ist.',
    at: 'um',
  },
  participantsTable: {
    title: 'Teilnehmer',
    user: 'Name',
    participated: 'Status',
    actions: 'Aktionen',
    view: 'Anschauen',
  },
  participationChart: { participation: 'Teilnahme' },
  templateItem: {
    privateHint: 'Diese Vorlage ist nur für dich sichtbar.',
    sharedHint: 'Diese Vorlage ist für dich und andere sichtbar.',
    publicHint: 'Diese Vorlage ist für alle in deinem Workspace sichtbar.',
  },
  updateTemplates: { all: 'Alle Vorlagen' },
  scheduleDetailTop: {
    allRecipients: 'Alle Teilnehmer',
    allRecipientsDescription: 'Alle Teilnehmer dieses Zeitplans',
  },
  spaceSelector: {
    mySpaces: 'Meine {title}',
    activeSpaces: 'Aktive {title}',
    archivedSpaces: 'Archivierte {title}',
    selectSpace: 'Wähle {title}',
    further: '+{amount} weitere',
    empty: 'Keine {title} gefunden',
    searchPlaceholder: 'Nach {title} suchen...',
    selectPlaceholder: 'Wähle {title} aus...',
  },
  spaceSelectorItem: {
    selectAll: 'Alle auswählen',
    deselectAll: 'Alle abwählen',
  },
  supportForm: {
    heading: 'Kundenservice kontaktieren',
    description: 'Wir unterstützen dich gerne bei Fragen oder Problemen. Sende uns hierzu bitte eine E-Mail an {supportEmail}.',
    helpcenter: {
      tip: 'Kleiner Tipp: Viele Fragen lassen sich schnell durch einen Blick in unser Hilfe-Center lösen, schau doch mal rein 😊',
      action: 'Zum Helpcenter',
    },
  },
  upgradeForm: {
    heading: 'Vertrieb kontaktieren',
    description: 'Wir helfen dir bei der Auswahl des richtigen Plans und der Einrichtung deines Kontos.',
    firstNameLabel: 'Vorname',
    lastNameLabel: 'Nachname',
    emailLabel: 'Arbeits-E-Mail',
    licencesLabel: 'Anzahl an Lizenzen',
    plansLabel: 'Plan',
    messageLabel: 'Deine Nachricht',
    optional: '(optional)',
    questionLabel: 'Anfrage',
    submittedTitle: 'Erfolgreich versendet',
    submittedSubTitle: 'Deine Nachricht wurde erfolgreich versendet. Unser Sales-Team meldet sich in Kürze bei dir per E-Mail.',
    subscriptionExpired: 'Testzeitraum abgelaufen',
    subscriptionExpiredText: 'Wenn du einen unserer Pläne abonnieren, deinen Testzeitraum verlängern oder eine Frage zur Software von Mooncamp hast, kontaktiere uns.',
    required: 'Dieses Feld ist ein Pflichtfeld',
    scheduleDemo: 'Persönliche Demo anfragen',
    bookPlan: 'Lizenzen kaufen',
    other: 'Sonstiges',
    address: 'Firmenadresse',
    vatID: 'VAT ID (optional)',
    addressPlaceholder: 'Vollständige Firmenanschrift',
    bookPlanExplanation: 'Bitte wähle einen Plan und teile uns deine Rechnungsanschrift mit. Wir werden im Anschluss ein Angebot für dich vorbereiten, welches du elektronisch unterschreiben kannst. Sobald es unterschrieben ist, beginnt dein Abonnement und eine Rechnung wird versendet.',
    scheduleDemoExplanation: 'Wir würden uns sehr freuen, dich durch Mooncamp in einer persönlichen Demo zu begleiten. Fülle hierzu das Demo-Formular auf unserer Website aus und wähle einen Termin, der dir am besten passt. Wir freuen uns auf dich!',
    scheduleDemoButton: 'Termin finden',
    scheduleDemoLink: '@:general.websiteBaseUrl/kontakt/',
    invalid: 'Bitte fülle alle Pflichtfelder aus.',
  },
  spaceItem: {
    unpinHelp: 'Von Seitenleiste entfernen',
    pinHelp: 'Zur Seitenleiste hinzuzufügen',
    disabledHelp: 'Du kannst {name} nicht von der Seitenleiste entfernen, da du ein Mitglied bist',
  },
  spaceNavigation: { searchPlaceholder: 'Suche nach {title}...' },
  msError: {
    header: 'Fehler bei der Verbindung mit Microsoft Teams',
    message: 'Leider ist dieses Microsoft-Konto bereits mit dem Mooncamp-Benutzer {email} verbunden.',
    message2: 'Wenn du nicht der Besitzer dieses Mooncamp-Kontos bist, informiere uns bitte über {supportmail}. Ansonsten kannst du die Verbindung zwischen {email} und deinem Microsoft-Konto trennen, indem du zu deinem {email}-Konto wechselst und zu Einstellungen -> Benachrichtigungen navigierst. Hier kannst du auf \'Trennen\' klicken.',
    goBack: 'Zurück',
  },
  propertyLabel: {
    editProperty: 'Eigenschaft bearbeiten',
    hideProperty: 'Eigenschaft verstecken',
    customizePage: 'Ansicht anpassen',
    alwaysVisible: 'Immer sichtbar',
    hideWhenEmpty: 'Ausblenden wenn leer',
    alwaysHide: 'Immer ausgeblendet',
  },
  propertyValues: {
    hiddenProperties: 'weitere Eigenschaft | weitere Eigenschaften',
    hideProperties: '1 Eigenschaft ausblenden | {count} Eigenschaften ausblenden',
  },
  customizePage: {
    header: 'Detailansicht für',
    properties: 'Eigenschaft',
    goalChildrenProperties: 'Eigenschaften Unterelemente',
  },
  cascadeTableRow: {
    editTitle: 'Titel editieren',
    errorTitle: 'Validierungsfehler',
  },
  documentListHeader: {
    wrapCells: 'Zeilen umbrechen',
    wrapTitles: 'Ziel-Titel umbrechen',
    wrapTitlesTooltip: 'Ganze Titel von Zielen in Updates anzeigen.',
    expandNextLevel: 'Nächste Ebene ausklappen',
    expandAll: 'Alles ausklappen',
    collapseNextLevel: 'Nächste Ebene einklappen',
    collapseAll: 'Alles einklappen',
    locked: 'Gesperrt',
    lockedHint: 'Du hast nicht die benötigten Rechte, um diese Ansicht zu ändern.',
  },
  lookupForm: {
    relationTitle: 'Zieleigenschaft',
    propertyTitle: 'Nutzereigenschaft',
    from: 'von',
    title: 'Eigenschaft konfigurieren',
    relationInfo: 'Die Zieleigenschaft vom Typ Person (z.B. "Verantwortlich"), über welches der Wert ermittelt wird.',
    propertyInfo: 'Die Nutzereigenschaft dessen Wert bzw. Inhalt zurückgegeben wird.',
  },
  foreignSpaceReference: { insufficientPermissions: 'Du darfst nur Ziele für dein eigenes Team erstellen' },
  companyInfo: {
    title: 'Infoseite',
    help: 'Erfahre mehr darüber, wie du Infoseiten einrichten kannst.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/infoseite',
    actions: {
      activate: {
        title: 'Im Hilfebereich anzeigen',
        subTitle: 'Die Seite wird für alle Mitarbeiter im Hilfebereich angezeigt.',
      },
      onboarding: {
        title: 'Beim Onboarding anzeigen',
        subTitle: 'Die Seite wird allen Mitarbeitern beim ersten Anmelden angezeigt.',
      },
      open: 'Öffnen',
    },
    contentHeader: 'Inhalt',
    prompt: 'Du hast ungespeicherte Änderungen, willst du diese verwerfen?',
    saveChanges: 'Änderungen speichern',
  },
  goalStatusTag: {
    disabledAutoStatusUpdateDescription: 'Das automatische Status-Update ist deaktiviert. Wenn aktiviert, wird der Status auf Basis der Unterelemente aktualisiert.',
    enableAutomaticStatusUpdate: 'Automatisches Status-Update aktivieren',
    successfullyActivated: 'Automatisches Status-Update ist aktiviert.',
    successfullyDeactivated: 'Automatisches Status-Update ist deaktiviert.',
  },
  releaseNotesWrapper: {
    title: 'Was neu ist',
    open: 'Changelog öffnen',
    readMore: 'Mehr anzeigen',
    linkedInLabel: 'Für mehr Updates, folge uns auf:',
    readMoreLink: '{baseURL}/en/releases/page/2/',
    releaseNotesLink: '{baseURL}/en/releases',
  },
  planningList: {
    pageTitle: 'Plannings',
    helpText: 'Erfahre mehr über Plannings',
    helpLink: 'https://help.mooncamp.com/de/plannings',
    title: 'Name',
    creator: 'Erstellt von',
    createdAt: 'Erstellt am',
    goalCycle: 'Zyklus',
    modifiedAt: 'Zuletzt geändert',
    onboarding: {
      title: 'Was sind Plannings?',
      drafts: 'Zielentwürfe',
      description1: 'Ein Planning ist ein spezieller Bereich, in dem du {Titel} mit deinem Team erstellen kannst, bevor sie für alle sichtbar veröffentlicht werden.',
      description2: 'Plannings sind ein Kernelement des OKR-Prozesses und finden in der Regel statt, bevor der nächste Zyklus beginnt.',
      description3: 'Erfahre mehr über Plannings in unserem {helpCenter}.',
      helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/plannings',
      helpCenter: 'Helpcenter',
      createPlanning: 'Ein Planning erstellen',
      notNow: 'Nicht jetzt',
    },
  },
  plannings: {
    open: 'Offen',
    closed: 'Geschlossen',
    all: 'Alle',
  },
  planningForm: {
    createTitle: 'Erstelle ein Planning',
    titleLabel: 'Name',
    titlePlaceholder: 'Titel eingeben',
    cyclePlaceholder: 'Zyklus auswählen',
  },
  planningDetails: {
    planning: 'Planning',
    plannings: 'Plannings',
    publishGoals: '{title} veröffentlichen',
    publish: 'Veröffentlichen',
    publishSelected: '{amount} Elemente veröffentlichen',
    closePlanning: 'Planning schließen',
    markClosed: 'Als geschlossen markieren',
    reopen: 'Erneut öffnen',
    closed: 'Geschlossen',
    published: 'Deine {title} wurden veröffentlicht',
    nextStepsHint: 'Klicke hier, um zu sehen, wo deine {title} veröffentlicht wurden:',
    shareCreatorDescription: 'Ersteller dieses Plannings',
    importGoals: {
      initialModal: {
        title: '{title} selbst erstellen oder importieren',
        scratch: '{title} selbst erstellen',
        import: '{title} importieren',
      },
      button: '{title} importieren',
      modal: {
        title: '{title} duplizieren und importieren',
        button: '{amount} Elemente importieren',
      },
    },
    productTour: {
      step1: {
        heading: 'Team und Zyklus',
        content: 'Ein Planning ist normalerweise mit einem Zyklus und einem Team verbunden.\n\nZielentwürfe, die innerhalb dieses Plannings erstellt werden, bekommen diese Werte automatisch zugewiesen.',
      },
      step2: {
        heading: 'Freigabeeinstellungen',
        content: 'Standardmäßig wird ein Planning für das/die Team(s) freigegeben, dem/denen es zugeordnet ist. Du kannst hier jederzeit ändern, mit wem das Planning geteilt wird.',
      },
      step3: {
        heading: 'Zielentwürfe veröffentlichen',
        content: 'Wenn du mit dem Planning fertig bist, kannst du hier auswählen, welche Ziele veröffentlicht werden sollen.',
      },
    },
  },
  goalPage: {
    notPublishedHint: '{title} wurde noch nicht veröffentlicht.',
    openPlanning: 'Planning öffnen',
  },
  slack: {
    connectionError: {
      goBack: 'Zurück',
      header: 'Fehler bei der Verbindung mit Slack',
      missingAuth: 'Du scheinst nicht die erforderlichen Berechtigungen zu haben, um Mooncamp mit einem Slack-Workspace zu verbinden. Wenn du nur dein Mooncamp-Konto mit Slack verbinden willst, gehe zu deinem Slack-Workspace und klicke auf die Mooncamp-Slack-App.',
      defaultError: 'Leider ist bei der Verbindung mit Slack ein Fehler aufgetreten. Bitte kontaktiere uns über {supportmail}',
    },
  },
  appFeedback: {
    avatarName: 'Laura von Mooncamp',
    greeting: 'Hallo {name} 👋',
    npsQuestion: 'Auf einer Skala von 0 bis 10, wie wahrscheinlich würdest du Mooncamp einem anderen Unternehmen oder beruflichen Kontakt empfehlen?',
    npsScale: {
      left: 'Sehr unwahrscheinlich',
      right: 'Sehr wahrscheinlich ',
    },
    followUp: 'Was ist der wichtigste Grund für deine Auswahl?',
    hint: '(Optional) Schreib etwas...',
    referral: {
      first: 'Danke ️🙏❤',
      second: 'Vielleicht ist das zu viel verlangt, aber...',
      third: 'würdest du dem Mooncamp-Team mit einem Review auf {name} helfen?',
      noButton: 'Nein, danke.',
      yesButton: 'Ja, gerne.',
    },
    thankYou: {
      first: 'Danke für dein ehrliches Feedback 🙏',
      second: 'Das bedeutet uns sehr viel. (wirklich)',
    },
  },
  setParents: {
    prompt: 'Soll das Ziel auf den Fortschritt von {goals} einzahlen? | Sollen die Ziele auf den Fortschritt von {goals} einzahlen?',
    notSetPaysOnto: 'Nein',
    setPaysOnto: 'Ja',
  },
  goalCycles: {
    title: 'Zyklen',
    learnMore: 'Erfahre mehr über Zyklen.',
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/zyklen',
  },
  goalCyclesTable: {
    title: 'Titel',
    start: 'Startdatum',
    end: 'Enddatum',
  },
  goalType: {
    title: 'Zieltypen und Regeln',
    learnMore: 'Erfahre mehr über das Regelsystem für Zieltypen.',
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/regelsystem-fr-ziele-und-okrs',
    listTitle: 'Alle',
    editTitle: 'Typ editieren',
    singleRequired: 'Dieser Zieltyp ist erforderlich und kann nicht gelöscht werden.',
    oneOrMoreRequired: 'Ein oder mehrere ausgewählte Zieltypen sind erforderlich und können nicht gelöscht werden.',
  },
  goalStatus: {
    title: 'Status',
    learnMore: 'Erfahre mehr über Status.',
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/custom-goal-status',
    subTitle: 'Konfiguriere die Status für deine Ziele.',
  },
  propertyMenu: { goalTypeRequired: 'Editieren nur möglich, wenn ein Zieltyp gesetzt ist' },
  goalTypeForm: {
    properties: 'Eigenschaften',
    deleteEmptyPrompt: 'Der Zieltyp ist leer. Möchtest du den Zieltyp löschen?',
  },
  propertySettingsForm: {
    edit: '{title} editieren',
    visibility: 'Sichtbarkeit',
    canBeChildOf: 'Kann Kindelement sein von',
    required: 'Pflichtfeld',
    progressMeasurement: 'Messart',
  },
  propertyValidation: { invalidParentType: 'Erlaubter Typ ist: {types} | Erlaubte Typen sind: {types}' },
  validationErrors: {
    header: 'Es gibt {amount} Fehler:',
    canBeChildOf: 'Der Typ des übergeordneten Ziels "{title}" sollte {expectedTypes} sein, ist aber {actualTypes}.',
  },
  measurementTypeError: { allowedTypes: 'Erlaubte Messarten:' },
  canBeChildOfError: { allowedTypes: 'Erlaubter Zieltyp: | Erlaubte Zieltypen:' },
  requiredError: { title: 'Pflichtfeld' },
  mustBeEmptyError: { title: 'Muss leer sein.' },
  goalTypeList: {
    deletePrompt: 'Bist du sicher, dass du diesen Zieltyp löschen willst?',
    yes: 'Zieltyp löschen',
  },
  goalCreateDropdown: { title: 'Ziel erstellen' },
  rGoalsCascadeTable: { cannotChangeOrder: 'Reihenfolge kann nicht verändert werden, da Schreibrechte fehlen.' },
  goalMutationErrors: {
    circularReference: 'Die Ziele, die du zu neu anzuordnen versuchst, bilden eine Zirkelreferenz, was nicht erlaubt ist.',
    missingAccessRightsGoal: 'Fehlende Schreibrechte für Ziel "{title}"',
    missingAccessRightsView: 'Fehlende Schreibrechte für Ansicht "{title}"',
  },
  goalProperties: { noParentAllowed: 'Übergeordnetes Ziel muss leer sein.' },
  iconList: {
    icons: 'Icons',
    recent: 'Zuletzt verwendet',
    people: 'Menschen',
    nature: 'Natur & Tiere',
    food_drink: 'Essen & Trinken',
    activity: 'Aktivitäten',
    travel: 'Reisen & Orte',
    objects: 'Objekte',
    symbols: 'Symbole',
    flags: 'Flaggen',
    pickAColor: 'Farbe wählen',
  },
  iconPicker: {
    search: 'Suchen...',
    types: {
      emoji: 'Emojis',
      icon: 'Icons',
      custom: 'Custom',
    },
    remove: 'Entfernen',
    title: 'Emoji auswählen',
    placeholderTooltip: 'Wähle ein Icon aus',
    placeholderTooltipEmoji: 'Wähle ein Emoji aus',
    custom: {
      upload: 'Bild hochladen',
      recommended: 'Empfohlene Größe ist 280 x 280 Pixel',
      cropImage: 'Ausgewähltes Bild zuschneiden',
    },
  },
  userProvisioningStatus: {
    provisionedUserCount: 'Verfügbare Nutzer',
    userCount: 'Nutzer mit Mooncamp synchronisiert',
    updatesAvailable: 'Verfügbare Aktualisierungen',
    errors: 'Synchronisationsfehler',
    showErrors: 'Fehler anzeigen',
  },
  userProvisioningErrors: {
    givenName: 'givenName',
    familyName: 'familyName',
    email: 'email',
    error: 'Synchronisationsfehler',
    title: 'Synchronisationsfehler',
  },
  oauthCodeGrant: {
    actionTitle: 'Aktiviere OAuth-Authentifizierung',
    actionSubtitle: 'Activate OAuth-Authentifizierung für Azure User-Provisionierungs-Anwendungen ',
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
    redirectUrls: 'Redirect URLs',
    addRedirectUrl: 'Redirect URL hinzufügen',
    save: 'Speichern',
  },
  oauthCodeGrantRedirect: {
    header: 'Anmeldung',
    message: 'Leider gibt es Probleme bei der Anmeldung.',
    message2: 'Wenn das Problem weiterhin besteht melde dich bei uns unter {supportmail} mit der folgenden Fehlernachricht.',
    internalError: 'Internal error',
  },
  planTag: {
    activateHint: 'Aktivieren Sie dieses Funktion. Klicke, um mehr zu erfahren.',
    upgradeHint: 'Upgrade deinen Plan, um diese Funktion zu nutzen. Klicke, um mehr zu erfahren.',
  },
  featureGateDialogs: {
    healthDashboard: {
      subTitle: 'Health Dashboard: Der Qualitätscheck für all deine Ziele',
      description: 'Mit dem Health Dashboard kannst du einen vollständigen Qualitätscheck für alle Ziele deines Unternehmens durchführen. Es ist wie ein Cockpit, von dem aus du alle Mängel im Zusammenhang mit deinen Zielen schnell erkennen und lösen kannst. Auf diese Weise kannst du sicherstellen, dass dein gesamter Zielmanagement-Prozess reibungslos abläuft und dass sich jeder an die Ziel- und OKR-Regeln hält, die du in den Einstellungen konfiguriert hast.',
      actions: { learnMoreLink: '@:{\'general.helpCenterBaseUrl\'}/health-dashboard' },
      image: { alt: 'Health dashboard' },
    },
    customDashboards: {
      subTitle: 'Benutzerdefinierte Dashboards',
      description: 'Erstelle benutzerdefinierte Dashboards für eine Vielzahl von Anwendungsfällen, z. B.:',
      bullet1: 'Unternehmens-Cockpit: Überblick über Vision, Mission, Werte, KPIs, OKRs.',
      bullet2: 'Quarterly Business Review: Analyse von Erfolgen, Herausforderungen, Strategien.',
      bullet3: 'Persönliches Dashboard: Ansicht mit deinen OKRs, Check-ins, Notizen, Dateien.',
      bullet4: 'Team-Übersicht: Momentaufnahme von Zweck, Aufgabe, Kennzahlen, Zielen und Mitgliedern eines Teams.',
      bullet5: 'Plus viele weitere Anwendungsfälle...',
      actions: { learnMoreLink: '@:{\'general.helpCenterBaseUrl\'}/custom-dashboards' },
      image: { alt: 'Benutzerdefinierte Dashboards' },
    },
    goalTree: {
      subTitle: 'Baumansicht: Deine komplette Strategie aus der Vogelperspektive',
      bullet1: 'Verwende die Baumansicht als Strategie-Reißbrett, um all deine strategischen Eckpfeiler, Fokusbereiche, Ziele und Initiativen in einer hierarchischen Ansicht darzustellen.',
      bullet2: 'Verschaffe jedem einen Überblick über die Strategie deines Unternehmens und die Ausrichtung deiner Ziele und OKRs.',
      bullet3: 'Zoome auf die OKR-Sets von bestimmten Abteilungen oder Fokusbereichen.',
      bullet4: 'Entdecke schnell isolierte Ziele und ordne sie dem richtigen übergeordneten Ziel zu.',
      image: { alt: 'Baumansicht' },
    },
  },
  planGateDialogs: {
    shared: {
      title: 'Du brauchst ein Upgrade, um diese Funktion zu nutzen',
      actions: {
        upgradePlan: 'Upgrade auf den {plan} Plan',
        comparePlans: 'Alle Pläne vergleichen',
        comparePlansWebsiteLink: '@:{\'general.websiteBaseUrl\'}/preise/',
        activateFeature: 'Aktiviere dieses Feature',
        contactAdminToUpgrade: 'Interessiert? Bitte deinen Account-Admin um ein Upgrade deines Plans.',
        contactAdminToActivate: 'Interessiert? Bitte deinen Account-Admin, dieses Feature zu aktivieren.',
        learnMore: 'Erfahre mehr über dieses Feature',
      },
    },
    pro: {
      subTitle: 'Entdecke die Vorteile des Pro Plans',
      description: {
        bulletIntro: 'Das erwartet dich mit Mooncamp Pro.',
        bulletHealth: 'Health Dashboard: Ein umfassender Qualitätscheck all deiner Ziele',
        bulletOKRRule: 'Regelsystem für Ziele: Lege Regeln fest, um dein Zielsystem zu konfigurieren oder Pflichtfelder für Ziele hinzuzufügen',
        bulletGoalWeight: 'Gewichtung von Zielen: Weise Unterzielen oder Key Results Gewichte zu',
        bulletMultiAlignment: 'Multi-lignment von Zielen: Lass ein Ziel zu zwei oder mehr übergeordneten Zielen beitragen',
        bulletGoalTree: 'Ziele-Baum: Zeige deine Ziele in einer hierarchischen Baumansicht an und erhalte einen Überblick über deine Strategie aus der Vogelperspektive.',
        bulletMore: 'Und noch viel mehr.',
      },
      image: { alt: 'Pro plan' },
    },
    enterprise: {
      subTitle: 'Entdecke die Vorteile des Enterprise Plans',
      description: {
        bulletIntro: 'Das erwartet dich mit Mooncamp Enterprise. Alles aus dem Pro Plan, plus:',
        bulletPublishedViews: 'Öffentliche Links: Teile öffentliche Links mit Personen außerhalb deines Workspaces',
        bulletScim: 'SCIM Provisioning: Zugriff auf die Mooncamp SCIM API zur Bereitstellung und Verwaltung von Benutzern und Gruppen',
        bulletSaml: 'SAML SSO: Mitarbeiterzugriff skalierbar verwalten mit sicherem Single Sign-On',
        bulletSupport: 'Eigener Account Manager und Priority Support',
        bulletMore: 'Und noch viel mehr.',
      },
      image: { alt: 'Enterprise plan' },
    },
  },
  maintenance: {
    header: 'Wir sind bald wieder da',
    message: 'Entschuldigung für die Unannehmlichkeiten. Wir führen im Moment einige Wartungsarbeiten durch',
  },
  resourceCenter: { title: 'Ressourcen' },
  rcOverview: {
    videos: {
      title: 'Video-Tutorials',
      subTitle: 'Erste Schritte mit Mooncamp',
      watchDemoLink: 'https://mooncamp.com/de/watch-demo/',
    },
    videoFAQs: {
      title: 'Video FAQs',
      subTitle: 'FAQs in kurzen Videos beantwortet',
      link: 'https://help.mooncamp.com/de#block-311699f37a32400790b78bd48bc837e8',
    },
    onboardingGuide: { title: 'In-App Onboarding', subTitle: 'Erste Schritte & Kontoeinrichtung' },
    resources: {
      title: 'OKR Ressourcen',
      subTitle: 'Infos & Best Practices zu OKRs',
    },
    helpCenter: { title: 'Helpcenter', subTitle: 'Ausführliche Infos zu Funktionen und Account-Einstellungen' },
    gettingStarted: {
      title: 'Anleitung zum Einstieg',
      subTitle: 'Werde ein Mooncamp-Profi',
    },
    legalInformation: 'Rechtliche Informationen',
    legalInformationLink: '@:{\'general.websiteBaseUrl\'}/rechtliche-informationen',
  },

  progressTag: { completed: 'abgeschlossen' },
  okrResources: {
    hint: 'Bitte beachte: Jedes Unternehmen ist anders und verwendet sein individuelles OKR-Modell, mit unterschiedlichen Regeln und eigenem Vokabular. Bedenke daher beim Lesen der nachstehenden Ressourcen, dass sich der Inhalt von dem Verständnis deiner Organisation von OKRs und deren Umsetzung unterscheiden kann.',
    successStories: {
      title: 'Erfolgsgeschichten',
      subTitle: 'Wie unsere Kunden Mooncamp erfolgreich nutzen',
      href: '@:{\'general.websiteBaseUrl\'}/kunden',
    },
    okrGuide: {
      title: 'Der vollständige OKR-Leitfaden',
      subTitle: 'Ein Leitfaden zur OKR-Methode',
      href: '@:{\'general.websiteBaseUrl\'}/okr/',
    },
    howtoWriteOkrs: {
      title: 'Wie man OKRs schreibt',
      subTitle: 'Tipps für effektive OKRs',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-formulieren/',
    },
    okrRolloutGuide: {
      title: 'Der OKR-Rollout Guide',
      subTitle: 'Wie man OKRs einführt und ausrollt',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-einfuehren/',
    },
    okrCycle: {
      title: 'Der OKR-Zyklus',
      subTitle: 'Ein Überblick über all OKR-Events',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-zyklus/',
    },
    okrPlanning: {
      title: 'Das OKR Planning',
      subTitle: 'Wie man ein OKR Planning durchführt',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-planning/',
    },
    okrCheckin: {
      title: 'Das OKR Check-in',
      subTitle: 'Wie man ein OKR Check-in durchführt',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-check-in/',
    },
    okrRetrospective: {
      title: 'Die OKR Retrospektive',
      subTitle: 'Wie man eine OKR Retrospektive durchführt',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-retrospektive/',
    },
    review: {
      title: 'OKR Review',
      subTitle: 'Wie man ein OKR-Review durchführt',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-review/',
    },
    scoreOkrs: {
      title: 'OKR Scoring',
      subTitle: 'Wie man OKRs bewertet',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-scoring/',
    },
    benefits: {
      title: 'Die 9 Vorteile der OKR-Methode',
      subTitle: 'Warum OKRs so beliebt sind',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-vorteile/',
    },
    okrVsKpi: {
      title: 'OKR vs. KPI',
      subTitle: 'Was ist der Unterschied?',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-vs-kpi/',
    },
    outcomeVsOutput: {
      title: 'Outcome vs. Output',
      subTitle: 'Was ist der Unterschied?',
      href: '@:{\'general.websiteBaseUrl\'}/blog/output-vs-outcome/',
    },
    examples: {
      title: 'OKR-Beispiele',
      subTitle: 'Eine Sammlung von OKRs aus der Praxis',
      href: '@:{\'general.websiteBaseUrl\'}/okr-beispiele/',
    },
  },
  videos: { title: 'Videos' },
  productSurveyDialog: {
    icons: '🎄 🎁 ☃️',
    title: 'Jährliche Produktfeedback-Umfrage',
    content: 'Sag uns, welche Mooncamp-Funktionen du als Nächstes sehen möchtest 🚀',
    callToAction: 'Umfrage starten',
    hint: 'Dauert weniger als 5 Minuten.',
    cancel: 'Ich will nicht teilnehmen',
    surveyLink: 'https://mooncamp.typeform.com/to/z7PP1W2v',
  },
  onboardingSlideShow: {
    sorting: {
      heading: 'Sortieren und Ausrichten von Zielen per Drag & Drop',
      description: 'Ziele und sogar ganze OKR-Sets lassen sich manuell sortieren und neuausrichten. Klicke auf den linken Ziehpunkt und verschiebe die Ziele an die gewünschte Position.',
    },
    properties: {
      heading: 'Alles mit Eigenschaften anpassen',
      description: 'Eigenschaften sind eine der mächtigsten Funktionen von Mooncamp, die es deiner Organisation ermöglicht, die Mooncamp-Plattform vollständig an deine Bedürfnisse, internen Prozesse und deine Unternehmenssprache anzupassen.',
      link: '@:{\'general.helpCenterBaseUrl\'}/merkmale-in-mooncamp',
    },
    bulkEdit: {
      heading: 'Bulk-Edit-Ziele',
      description: 'Einfaches Bearbeiten mehrerer Ziele durch Markieren mit der Maus und Rechtsklick.',
    },
    expand: {
      heading: 'Alle Ziele zusammenklappen oder erweitern',
      description: 'Klicke mit der rechten Maustaste und wähle "Alles ausklappen/Alles einklappen", um alle Ziele in der Tabellenansicht schnell ein- oder auszuklappen.',
    },
    okrRules: {
      heading: 'Verwende Regeln, um das Zielsystem an deine Prozesse anzupassen',
      description: 'Du kannst nicht nur deine eigenen Zieltypen erstellen (z.B. Ziele, Schlüsselergebnisse oder Initiativen), sondern diese auch mit selbst definierten Regeln anpassen (z.B. "Key Results dürfen nur an Objectives ausgerichtet werden und müssen einen Owner haben").',
      link: '@:{\'general.helpCenterBaseUrl\'}/regelsystem-fr-ziele-und-okrs',
    },
  },
  goalCard: {
    addProperty: '{label} hinzufügen',
    summary: {
      comments: 'Keine Kommentare | Ein Kommentar | {count} Kommentare',
      lastCommentDate: 'Letzter Kommentar: {date}',
      updates: 'Keine Updates | Ein Update | {count} Updates',
      lastUpdateDate: 'Letztes Update: {date}',
    },
  },
  gridPage: {
    error: { loading: 'Fehler beim Laden des Inhalts.' },
    header: {
      addDescription: 'Beschreibung hinzufügen',
      addADescription: 'Beschreibung hinzufügen...',
    },
    empty: {
      title: 'Inhalt hinzufügen und loslegen.',
      readOnly: 'Dieses Dashboard ist leer und du hast keinen Zugriff, um Inhalte hinzuzufügen.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/custom-dashboards',
      learnMore: 'Erfahre mehr über Dashboards und Anwendungsfälle',
    },
    gridActions: {
      chart: 'Chart',
      singleGoalProgress: 'Zielfortschritt',
      text: 'Text',
      goalList: 'Ziel-Liste',
      checkinList: 'Check-in Liste',
      updateFeedList: 'Update-Feed Liste',
      memberList: 'Mitglieder-Liste',
      addTooltip: 'Inhalt zur Zeile hinzufügen',
      iframe: 'Einbetten',
    },
    addRowBtn: { addRow: 'Inhalt hinzufügen' },
    tile: {
      header: {
        editTitle: 'Titel ändern',
        addTitle: 'Titel hinzufügen',
        editDescription: 'Beschreibung ändern',
        addDescription: 'Beschreibung hinzufügen',
        showBorder: 'Rahmen anzeigen',
        duplicate: 'Duplizieren',
        compareTo: {
          base: 'Verglichen mit {relativeDate}',
          one_day_ago: 'gestern',
          previous_week: 'letzte Woche',
          previous_month: 'letzter Monat',
        },
        singleGoal: {
          settings: 'Einstellungen',
          openGoal: 'Ziel öffnen',
        },
      },
    },
    duplicate: { titlePrefix: 'Kopie von' },
  },
  gridPageSlideShow: {
    general: {
      heading: 'Dashboards',
      description: 'Benutzerdefinierte Dashboards können für verschiedenste Zwecke eingesetzt werden, wie z. B. eine Zusammenfassung der Kernprinzipien eines Unternehmens, Quarterly Business Reviews, wöchentliche Management-Berichte oder einen umfassenden Überblick über die wichtigsten Funktionen und Ziele einer Abteilung.',
    },
    addContentActions: {
      heading: 'Inhalt hinzufügen',
      description: 'Der Inhalt ist in <b>Zeilen</b> organisiert. Um eine <b>Karte</b> zu einer Zeile hinzuzufügen, bewege den Mauszeiger über die Zeile und klicke auf das "+" auf der linken Seite. Um eine neue Zeile hinzuzufügen, klicke auf "+ Inhalt hinzufügen" unter der letzten Zeile des Dashboards. Karten können alles sein: ein Text oder ein Bild, eine Liste von Zielen, Check-Ins, Updates oder Mitgliedern und sogar von dir definierte Zielkennzahlen oder KPIs.',
    },
    draggingActions: {
      heading: 'Verschieben und Größe anpassen',
      description: 'Um Karten innerhalb einer Zeile <b>zu verschieben oder ihre Größe zu ändern</b>, klicke einfach mit der Maus in den oberen Teil einer Karte, halte sie gedrückt und ziehe sie an ihre neue Position. Die Karten füllen automatisch die Breite einer Zeile aus. Die Breite und Höhe der Karten kann durch Klicken und Ziehen des Steuerelements zwischen den Karten angepasst werden.',
    },
  },
  treeItem: { noTypeAllowed: 'Kein erlaubter Zieltyp gefunden' },
  cardWidthSelector: {
    title: 'Kartengröße',
    small: 'Klein',
    medium: 'Mittel',
    large: 'Groß',
  },
  appFeedbackDropdown: {
    feedback: 'Feedback',
    tree: { label: 'Wie können wir die Baumansicht verbessern?' },
    customPages: { label: 'Wie können wir die Dashboards weiter verbessern?' },
    goalStatus: { label: 'Wie können wir die Ziel-Status-Funktion weiter verbessern?' },
    general: { label: 'Eine Funktion anfordern' },
    placeholder: 'Was gefällt dir, was gefällt dir nicht...',
    success: 'Vielen Dank für dein Feedback!',
  },
  gridPageSingleGoal: {
    goal: { expected: 'Erwarteter Wert:' },
    empty: { noGoal: 'Kein Ziel ausgewählt' },
  },
  gridPageTileSingleGoalEditor: {
    title: 'Fortschritt vom Ziel',
    goalPicker: 'Ausgewähltes Ziel',
    goalPickerPlaceholder: 'Wählen Sie ein Ziel',
    visualizeAs: 'Anzeige als',
    compareTo: 'Vergleichen mit',
    dateRange: 'Zeitrahmen',
  },
  gridPageTileSingleGoalType: {
    number: 'Metrik',
    line: 'Liniendiagramm',
  },
  gridPageList: {
    systemDashboards: '{title} progress und health',
    progress: {
      title: 'Progress dashboard',
      subtitle: 'Überblick über deinen Ziel-Fortschritt',
    },
    health: {
      title: 'Health Dashboard',
      subtitle: 'Der Qualitätscheck für all deine Ziele',
    },
    customDashboards: 'Custom Dashboards',
    columns: {
      title: 'Name',
      access: 'Zugriff',
      creator: 'Erstellt von',
      createdAt: 'Erstellt am',
    },
    privacy: {
      private: 'Privat',
      public: 'Geteilt',
      all: 'Alle',
    },
    noAllowanceToCreate: 'Du bist nicht berechtigt weitere private Dashboards zu erstellen.',
  },
  allowanceAlert: {
    messagePrivate: 'Dein Account hat {pagesCount} von {pagesAllowance} privaten Dashboards erstellt.',
    messagePublic: 'Dein Account hat {pagesCount} von {pagesAllowance} geteilten Dashboards erstellt.',
    subMessage: 'Schalte mehr frei, indem du deinen Account upgradest.',
    action: 'Mehr freischalten',
  },
  gridPagesContextMenu: {
    cannotDeleteMultiple: 'Du bist nicht berechtigt die ausgewählten Elemente zu löschen.',
    deleteHint: 'Du bist nicht berechtigt alle markierten Elemente zu löschen.',
    cannotEditMultiple: 'Du bist nicht berechtigt die ausgewählten Elemente zu bearbeiten',
    cannotMakePublicMultiple: 'Du bist nicht berechtigt mehr geteteilte Elemente zu haben.',
    duplicateHint: 'Du bist nicht berechtigt alle markierten Elemente zu duplizieren, da einige geteilt sind.',
    cannotDuplicateAll: 'Du bist nicht berechtigt alle markierten Elemente zu duplizieren.',
    changeAccess: 'Zugriffsrechte bearbeiten',
    makePublicHint: 'Du bist nicht berechtigt alle markierten Elemente zu teilen.',
    changeAccessHint: 'Du bist nicht berechtigt die Zugriffsrechte aller ausgewählten Elemente zu bearbeiten.',
  },
  gridPagesShareDropdown: {
    creatorDescription: 'Ersteller dieses Dashboards',
    cannotShareNoAllowance: 'Du bist nicht berechtigt mehr geteilte Elemente zu haben.',
  },
  addToGridEditor: {
    selectPage: 'Dashboard auswählen',
    successAction: 'Zum Dashboard',
  },
  goalTree: {
    fitToScreen: 'An Bildschirm anpassen',
    disabledView: 'Diese Ansicht ist deaktiviert.',
    empty: {
      hasFilter: 'Keine Filterergebnisse.',
      noFilter: 'Leer.',
      cta: 'Klicke, um ein Ziel hinzuzufügen.',
    },
  },
  treeViewSlideShow: {
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/goal-tree',
    navigation: {
      heading: 'Scrollen und Zoomen',
      description: `<ul>
<li>Um vertikal zu scrollen, verwende einfach das <b>Scrollrad</b> deiner Maus.</li>
<li>Um horizontal zu scrollen, halte die <b>Umschalttaste</b> auf deiner Tastatur gedrückt und verwende dann das <b>Scrollrad</b>.</li>
<li>Um hinein- oder herauszuzoomen, drücke und halte die <b>CMD-Taste</b> oder <b>Strg-Taste</b> gedrückt und verwende dann das <b>Scrollrad</b>.</li>
        </ul>`,
    },
    edit: {
      heading: 'Bearbeiten und Ausrichten von Zielen',
      description: `<ul>
<li>Um Ziele zu bearbeiten, ohne diese Seite zu verlassen, klicke auf das Bearbeiten-Symbol in der oberen rechten Ecke einer Zielkarte.</li>
<li>Um ein Ziel neu auszurichten, ziehe es einfach an die gewünschte Position.</li>
        </ul>`,
    },
    viewControls: {
      heading: 'Anzeigeeinstellungen',
      description: `<ul><li>Eigenschaften anzeigen und ausblenden</li>
        <li>Ändere die Kartenbreite, um die Baumansicht kompakter oder umfangreicher zu gestalten</li></ul>`,
    },
    handAndSelectTool: {
      heading: 'Auswahl- und Hand-Tool',
      description: `<ul><li>Verwende das Auswahlwerkzeug, um Ziele neu auszurichten oder Massenaktionen durchzuführen.</li>
        <li>Verwende das Hand-Tool, um den Inhalt zu navigieren, indem du die linke Maustaste drückst und die Maus bewegst.</li></ul>`,
    },
  },
  trashItem: { deletedAt: 'Gelöscht am' },
  goalStatusSettings: {
    name: 'Name',
    archived: 'Archivierte Optionen',
    cannotDeletePermanently: 'Option kann nicht gelöscht werden, da sie noch verwendet wird.',
    scoreTooltip: 'Der Schwellenwert wird verwendet, um automatisch einen Status basierend auf dem zeitgewichteten Fortschritt eines Ziels auszuwählen. Der zeitgewichtete Fortschritt misst, wie weit ein Ziel im Verhältnis zur verstrichenen Zeit vorangeschritten ist. Wenn der zeitgewichtete Fortschritt den Schwellenwert überschreitet, empfiehlt das System den Status, der diesem Fortschritt am nächsten kommt. Geschlossene Status werden nie empfohlen.',
    defaultFlagToolTip: 'Dieser Status wird automatisch ausgewählt wird, wenn das Ziel noch nicht aktualisiert wurde.',
    title: 'Erstelle einen neuen Status...',
  },
  statusOptionEditor: {
    color: 'Farbe',
    setAsDefault: 'Als Standard festlegen',
    group: 'Gruppe',
    score: 'Schwellenwert',
    cannotArchiveDefaultOption: 'Standardoptionen können nicht archiviert werden.',
  },
  statusChartSettingsDropdown: {
    title: 'Diagrammtyp',
    average: 'Durchschnitt',
    distribution: 'Verteilung',
  },
  dashboards: {
    list: 'Liste',
    reports: 'Berichte',
    progressDashboard: {
      helpText: 'Erfahre mehr über das Progress Dashboard.',
      helpLink: 'https://help.mooncamp.com/de/ziele-dashboard',
    },
    healthDashboard: {
      helpText: 'Erfahre mehr über das Health Dashboard.',
      helpLink: 'https://help.mooncamp.com/de/health-dashboard',
    },
  },
  goalProgressMenu: {
    progressDisplayOptions: {
      [progressDisplayOption.absolute]: 'Absolut',
      [progressDisplayOption.relative]: 'Relativ',
      [progressDisplayOption.full]: 'Vollständig',
    },
    optionLabel: 'Anzeige',
  },
  gridPageTileIframe: {
    empty: 'Bette alles Mögliche ein (Power BI, Tableau, Excel, PPT, Google Docs, Youtube, ...)',
    hint: 'Füge eine URL oder einen Embed-Code ein, um externe Inhalte einzubinden.',
    embedLink: 'Link einbetten',
    placeholder: 'Bitte einfügen: https://...',
  },
  reportsTable: {
    scheduleReport: 'Bericht erstellen',
    recipients: 'Empfänger',
    schedule: 'Kadenz',
    lastUpdatedAt: 'Zuletzt aktualisiert',
    createdBy: 'Erstellt von',
    deletePrompt: 'Bericht wirklich löschen?',
    pause: 'Pausieren',
    activate: 'Aktivieren',
    subscribed: 'Abonniert',
    subject: 'Name',
    printable: 'Dashboard',
  },
  reportEditor: {
    dashboard: 'Dashboard',
    schedule: 'Kadenz',
    addRecipient: 'Empfänger hinzufügen',
    recipients: 'Empfänger',
    invalidEmail: 'Ungültige E-Mail-Adresse',
    alreadyAdded: 'E-Mail bereits hinzugefügt',
    subject: 'Betreff',
    message: 'Nachricht (optional)',
    tooManyRecipients: 'Du kannst maximal 50 Empfänger hinzufügen.',
    tooManyRecipientsTrial: 'Bitte upgrade deinen Account, um mehr Empfänger hinzuzufügen zu können.',
    privateDashboardRecipients: 'Du kannst private Dashboard-Berichte nur an dich selbst senden.',
    [printablePage.healthDashboard]: 'Health Dashboard',
    [printablePage.progressDashboard]: 'Progress Dashboard',
  },
  dashboardDetails: {
    manageReports: 'Berichte verwalten',
    scheduleReport: 'Senden',
  },
  gridPageChartEditor: {
    title: 'Data',
    source: 'Datenquelle',
    goal: 'Ziele',
    checkin: 'Check-ins',
    goalActivity: 'Ziel-Updates',
    filter: 'Filter',
    cycle: 'Zyklus',
    chart: 'Chart',
    xAxis: 'X-Achse',
    yAxis: 'Y-Achse',
    groupBy: 'Gruppieren nach',
    whatToShow: 'Was soll angezeigt werden',
    count: 'Anzahl',
    none: 'Keine',
    representation: 'Jede Teilmenge repräsentiert',
    pie: 'Kreis',
    bar: 'Balken',
    line: 'Linie',
    metric: 'Metrik',
    titlePlaceholder: 'Titel eingeben...',
    descriptionPlaceholder: 'Beschreibung eingeben...',
    addDescription: 'Beschreibung hinzufügen',
    data: 'Daten',
    bucket: 'Bereich',
  },
  detailHeader: {
    close: 'Schließen',
    openAsPage: 'Als Seite öffnen',
    switchPeekMode: 'Vorschau-Modus wechseln',
  },
  groupByOption: {
    day: 'Tag',
    week: 'Woche',
    month: 'Monat',
    quarter: 'Quartal',
    year: 'Jahr',
    unique: 'Eindeutige Werte',
    none: 'Keine',
    bucket: 'Bereiche',
    sum: 'Summe',
    avg: 'Durchschnitt',
    min: 'Minimum',
    max: 'Maximum',
    count: 'Anzahl',
    count_distinct: 'Eindeutige Werte',
  },
  gridPageChart: {
    count: 'Anzahl',
    goal: 'Ziele',
    update: 'Check-ins',
    goalActivity: 'Ziel-Updates',
    countDistinct: 'Eindeutige Werte von {edge}',
    unique: 'Eindeutige Werte von {edge}',
    sum: 'Summe von {edge}',
    avg: 'Durchschnitt von {edge}',
    min: 'Minimum von {edge}',
    max: 'Maximum von {edge}',
    total: 'Gesamt',
  },
  bucketSelector: {
    bucketType: 'Gruppierung',
    none: 'Keine',
    automatic: 'Automatisch',
    custom: 'Benutzerdefiniert',
    minValue: 'Min. Wert',
    maxValue: 'Max. Wert',
    bucketSize: 'Gruppengröße',
    minValueError: 'Der Min. Wert muss kleiner als der Max. Wert sein.',
  },
};

export default mergeLangObjects(globalLang, lang);
