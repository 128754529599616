<template>
  <div
    class="leaf-node"
  >
    <div class="_blocks">
      <div
        v-if="!hideOp"
        class="_op _block"
      >
        <m-select
          v-model:value="selectedOp"
          :items="opItems"
          :read-only="readOnly || !canSelectOp"
          :disabled="disabled"
          :placeholder="$t('leafNode.placeholder')"
        />
      </div>
      <div class="_property _block">
        <m-select
          v-model:value="selectedScopeItem"
          :items="scopeItems"
          return-object
          :read-only="readOnly"
          :disabled="disabled"
          full-width
        />
      </div>
      <div class="_scope _block">
        <number-scope
          v-if="show(propertyType.number)"
          :key="selectedScopeItem.value"
          :value="scope"
          :read-only="readOnly"
          :disabled="disabled"
          :hide-op="hideLeafOp"
          :number-format="propertyNumberFormat"
          @change="updateScope"
        />
        <date-scope
          v-if="show(propertyType.date)"
          :key="selectedScopeItem.value"
          :value="scope"
          :read-only="readOnly"
          :disabled="disabled"
          :hide-op="hideLeafOp"
          :has-dynamic-values="hasDynamicDates"
          @change="updateScope"
        />
        <space-scope
          v-if="show(propertyType.space)"
          :key="selectedScopeItem.value"
          :value="scope"
          :is-not="isNot"
          :read-only="readOnly"
          :disabled="disabled"
          :hide-op="hideLeafOp"
          :hide-is-empty="hideIsEmpty"
          :max-tag-text-length="maxTagTextLength"
          @change="updateScope"
          @change-not="updateNotOperator"
        />
        <option-scope
          v-if="show(propertyType.options) || show(propertyType.singleSelect)"
          :key="selectedScopeItem.value"
          :value="scope"
          :read-only="readOnly"
          :disabled="disabled"
          :hide-op="hideLeafOp"
          :is-not="isNot"
          :options="options"
          :hide-is-empty="hideIsEmpty"
          :max-tag-text-length="maxTagTextLength"
          @change="updateScope"
          @change-not="updateNotOperator"
        />
        <status-scope
          v-if="show(propertyType.status)"
          :key="selectedScopeItem.value"
          :value="scope"
          :read-only="readOnly"
          :disabled="disabled"
          :hide-op="hideLeafOp"
          :is-not="isNot"
          :max-tag-text-length="maxTagTextLength"
          @change="updateScope"
          @change-not="updateNotOperator"
        />
        <user-scope
          v-if="show(propertyType.user)"
          :key="selectedScopeItem.value"
          :value="scope"
          :read-only="readOnly"
          :disabled="disabled"
          :hide-op="hideLeafOp"
          :is-not="isNot"
          :max-tag-text-length="maxTagTextLength"
          :hide-is-empty="hideIsEmpty"
          :field-name="userScopeConfig.edges.users"
          @change="updateScope"
          @change-not="updateNotOperator"
        />
        <user-scope
          v-if="show(userScopeType.staticUsers)"
          :key="selectedScopeItem.value"
          :value="scope"
          :read-only="readOnly"
          :disabled="disabled"
          :hide-op="hideLeafOp"
          :is-not="isNot"
          :max-tag-text-length="maxTagTextLength"
          hide-is-empty
          :field-name="userScopeConfig.edges.staticUsers"
          @change="updateScope"
          @change-not="updateNotOperator"
        />
        <goal-scope
          v-if="show(GOAL_RELATION)"
          :key="selectedScopeItem.value"
          :value="scope"
          :read-only="readOnly"
          :disabled="disabled"
          :hide-op="hideLeafOp"
          :is-not="isNot"
          :field-name="userScopeConfig.edges.relation"
          @change="updateScope"
          @change-not="updateNotOperator"
        />
        <lookup-scope
          v-if="show(propertyType.lookup)"
          :key="selectedScopeItem.value"
          :value="scope"
          :read-only="readOnly"
          :disabled="disabled"
          :hide-op="hideLeafOp"
          :is-not="isNot"
          :max-tag-text-length="maxTagTextLength"
          :property="selectedScopeItem.property"
          :options="options"
          @change="updateScope"
          @change-not="updateNotOperator"
        />
      </div>
    </div>
    <div class="_delete">
      <m-tooltip
        v-if="canDelete && !readOnly && !disabled"
        :mouse-enter-delay=".7"
      >
        <m-btn
          icon="close"
          fab
          hide-border
          small
          @click="$emit('delete')"
        />
        <template #title>
          {{ $t('leafNode.deleteTooltip') }}
        </template>
      </m-tooltip>
    </div>
  </div>
</template>

<script>
import DateScope from '@/components/filter/DateScope.vue';
import GoalScope from '@/components/filter/GoalScope.vue';
import LookupScope from '@/components/filter/LookupScope.vue';
import NumberScope from '@/components/filter/NumberScope.vue';
import OptionScope from '@/components/filter/OptionScope.vue';
import SpaceScope from '@/components/filter/SpaceScope.vue';
import StatusScope from '@/components/filter/StatusScope.vue';
import UserScope from '@/components/filter/UserScope.vue';
import useProperties from '@/composables/property/property';
import { GOAL_RELATION } from '@/lib/props/custom-types';
import { copy } from 'shared/lib/copy';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { numberFormat, propertyType, userScopeOperator, userScopeType } from 'shared/constants.json';
import { userScope as userScopeConfig } from 'shared/api/query/configs.json';

export default {
  name: 'LeafNode',
  props: {
    // A node (parent) is expected to have exactly one child, the childNode.
    // The parent node is used to set the not-operator
    node: {
      type: Object,
      required: true,
    },
    op: {
      type: String,
      default: '',
    },
    canSelectOp: {
      type: Boolean,
      default: false,
    },
    hideOp: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    hideLeafOp: {
      type: Boolean,
      default: false,
    },
    scopeItems: {
      type: Array,
      default: () => [],
    },
    maxTagTextLength: {
      type: Number,
      default: 0,
    },
    hasDynamicDates: {
      type: Boolean,
      default: false,
    },
    multiModel: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { properties } = useProperties();
    return { properties };
  },
  emits: ['delete', 'change', 'change-operator'],
  components: { SpaceScope, GoalScope, UserScope, OptionScope, DateScope, NumberScope, StatusScope, LookupScope },
  data() {
    return {
      propertyType,
      opItems: [
        {
          text: this.$t('general.and'),
          value: userScopeOperator.and,
        },
        {
          text: this.$t('general.or'),
          value: userScopeOperator.or,
        },
      ],
      userScopeType,
      GOAL_RELATION,
      userScopeConfig,
    };
  },
  computed: {
    scope() {
      return this.node.children[0].scope;
    },
    isNot() {
      return this.node.op === userScopeOperator.not;
    },
    selectedScopeType() {
      if (this.scope.type !== undefined) {
        return this.scope.type;
      }
      if (!isNullOrUndefined(this.scope.staticUsers)) {
        return userScopeType.staticUsers;
      }
      if (!isNullOrUndefined(this.scope.directProperty)) {
        return userScopeType.directProperty;
      }
      return userScopeType.property;
    },
    selectedScopeItem: {
      get() {
        switch (this.selectedScopeType) {
          case userScopeType.staticUsers:
            return this.scopeItems.find((item) => item.type === this.selectedScopeType);
          case userScopeType.directProperty:
            return this.scopeItems.find((item) => item.type === this.selectedScopeType && item.directProperty.edgeName === this.scope.directProperty.edgeName);
          case userScopeType.property:
            return this.scopeItems.find((item) => item.type === this.selectedScopeType && item.property.uid === this.scope.property.uid);
          default:
            throw new Error('unsupported user scope type');
        }
      },
      set(val) {
        this.updateScope(this.scopeFromScopeItem(val));
      },
    },
    selectedOp: {
      get() {
        return this.op;
      },
      set(val) {
        if (this.op === val) {
          return;
        }
        this.$emit('change-operator', val);
      },
    },
    propertyNumberFormat() {
      if (this.selectedScopeType === userScopeType.directProperty) {
        return numberFormat.number;
      }
      return this.selectedScopeItem.property.numberFormat;
    },
    selectedType() {
      switch (this.selectedScopeType) {
        case userScopeType.directProperty:
          return this.selectedScopeItem.directProperty.type;
        case userScopeType.property:
          return this.selectedScopeItem.property.type;
        case userScopeType.staticUsers:
        default:
          return undefined;
      }
    },
    hideIsEmpty() {
      switch (this.selectedScopeType) {
        case userScopeType.directProperty:
          return this.selectedScopeItem.directProperty.noIsEmptyFilter;
        case userScopeType.staticUsers:
        case userScopeType.property:
        default:
          return false;
      }
    },
    options() {
      if (this.selectedType === undefined) {
        return [];
      }
      if (this.selectedType === propertyType.lookup) {
        return this.properties.filter((p) => p.uid === this.selectedScopeItem.property.lookupProperty.uid)[0].options;
      }
      if (![propertyType.options, propertyType.singleSelect].includes(this.selectedType)) {
        return [];
      }
      return this.properties.filter((p) => p.uid === this.selectedScopeItem.property.uid)[0].options;
    },
  },
  methods: {
    show(type) {
      switch (this.selectedScopeType) {
        case userScopeType.staticUsers:
          return type === userScopeType.staticUsers;
        case userScopeType.property:
        case userScopeType.directProperty:
          return type === this.selectedType;
        default:
          return false;
      }
    },
    scopeFromScopeItem(scopeItem) {
      const scope = { type: scopeItem.type };
      const propertyScope = {
        users: [],
        selectedOptions: [],
        spaces: [],
        numberRange: null,
        timeRange: null,
        relation: null,
        isEmpty: false,
      };
      switch (scope.type) {
        case userScopeType.staticUsers:
          return { ...scope, staticUsers: [] };
        case userScopeType.directProperty:
          return {
            ...scope,
            ...propertyScope,
            directProperty: scopeItem.directProperty,
          };
        case userScopeType.property:
          return {
            ...scope,
            ...propertyScope,
            property: scopeItem.property,
          };
        default:
          return scope;
      }
    },
    updateScope(scope) {
      const cp = copy(this.node);
      cp.children[0].scope = scope;
      if (JSON.stringify(this.node) === JSON.stringify(cp)) {
        return;
      }
      this.$emit('change', cp);
    },
    updateNotOperator(op) {
      this.$emit('change', { ...this.node, op });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .leaf-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: inherit;
    gap: .8rem;

    ._blocks {
      display: flex;
      width: inherit;
      gap: .4rem;

      ._scope {
        width: inherit;
      }
    }
  }

</style>
