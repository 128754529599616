<template>
  <div class="access-policy">
    <div :class="['_scopes', loading ? '-loading':'']">
      <template v-if="creator !== null">
        <access-policy-scope-user-row
          :user="creator"
          :access-type="accessPolicyType.full"
          :access-types="accessTypes"
          disabled
        >
          <template #description>
            {{ creatorDescription === '' ? $t('accessPolicy.creatorDescription') : creatorDescription }}
          </template>
        </access-policy-scope-user-row>
        <m-divider none />
      </template>
      <access-policy-scope-row
        :image="companyImage"
        icon="global"
      >
        <template #name>
          {{ account.companyName }}
        </template>
        <template #description>
          {{ $t('accessPolicy.organizationDescription') }}
        </template>
        <template #access-policy-type-selector>
          <access-policy-type-selector
            :value="value.accountAccess"
            :disabled="disabled"
            :access-types="accessTypes"
            show-disable
            @change="updateAccount"
          />
        </template>
      </access-policy-scope-row>
      <m-divider none />
      <div
        v-for="scope in sortedScopes"
        :key="scope.uid"
      >
        <template v-if="canRenderAccessPolicyScope(scope)">
          <access-policy-scope-user-row
            v-if="isStaticUserScope(scope)"
            :user="userFromStaticUserScope(scope)"
            :access-type="scope.accessType"
            :access-types="accessTypes"
            :disabled="disabled"
            @change="scopeTypeChanged(scope, $event)"
          />
          <access-policy-scope-group-row
            v-else
            :scope="scope"
            :access-type="scope.accessType"
            :access-types="accessTypes"
            :disabled="disabled"
            @change="scopeTypeChanged(scope, $event)"
          />
          <m-divider none />
        </template>
      </div>
      <slot name="after-access-scope" />
    </div>
    <slot name="before-buttons" />
    <m-content
      padding-xs
      class="_actions"
    >
      <div class="_btn _invite">
        <m-btn
          :color="buttonColor"
          :disabled="disabled || loading"
          block
          @click.stop="showScopeSelector"
        >
          {{ addUserText === '' ? $t('accessPolicy.inviteButton') : addUserText }}
        </m-btn>
      </div>
      <div
        v-if="pageLink !== ''"
        class="_btn -page-link"
      >
        <m-btn
          v-clipboard="pageLink"
          v-clipboard:success="clipboardSuccessHandler"
          v-clipboard:error="logCatch(onError)"
          block
        >
          {{ $t('accessPolicy.copyLink') }}
        </m-btn>
      </div>
    </m-content>
    <m-dialog
      v-model:value="showModal"
      hide-footer
      no-padding
    >
      <access-policy-scope-selector
        :button-label="addGroupButtonText"
        :access-types="accessTypes"
        @submit="scopeSubmitted"
      />
    </m-dialog>
  </div>
</template>

<script>
import AccessPolicyScopeGroupRow from '@/components/access-policy/AccessPolicyScopeGroupRow.vue';
import AccessPolicyScopeRow from '@/components/access-policy/AccessPolicyScopeRow.vue';
import AccessPolicyScopeSelector from '@/components/access-policy/AccessPolicyScopeSelector.vue';
import AccessPolicyScopeUserRow from '@/components/access-policy/AccessPolicyScopeUserRow.vue';
import AccessPolicyTypeSelector from '@/components/access-policy/AccessPolicyTypeSelector.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import useSnackBar from '@/composables/snackbar';
import { DateTime } from 'luxon';
import { UserScopeTreeHasher } from '@/lib/user-scope-tree_hasher';
import { accessPolicyType, userScopeType } from 'shared/constants.json';
import { addOrUpdate, updateObjectInArray } from 'shared/lib/array/write';
import { canRenderAccessPolicyScope } from '@/lib/access-policy-scope';
import { copy, shallowCopy } from 'shared/lib/copy';
import { getAccessTypeOfUser, spreadAccessPolicyScopes } from '@/lib/access-policy';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { logCatch } from '@/lib/logger/logger';

const REMOVE = 'remove';

export default {
  name: 'AccessPolicy',
  props: {
    value: {
      type: Object,
      required: true,
    },
    creator: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pageLink: {
      type: String,
      default: '',
    },
    addUserText: {
      type: String,
      default: '',
    },
    accessTypes: {
      type: Array,
      default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
    },
    creatorDescription: {
      type: String,
      default: '',
    },
    suppressConfirm: {
      type: Boolean,
      default: false,
    },
    addGroupButtonText: {
      type: String,
      default: '',
    },
    buttonColor: {
      type: String,
      default: 'primary',
    },
  },
  emits: ['input', 'update:value', 'close'],
  components: { AccessPolicyScopeGroupRow, AccessPolicyScopeUserRow, AccessPolicyScopeRow, AccessPolicyTypeSelector, AccessPolicyScopeSelector },
  setup() {
    const snackbar = useSnackBar();
    const { userProperties } = useProperties();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { snackbar, userProperties, loggedInUser, userLang, account: loggedInUserAccount };
  },
  data() {
    return {
      showModal: false,
      logCatch,
      accessPolicyType,
    };
  },
  computed: {
    companyImage() {
      if (this.account.companyImage === null) {
        return '';
      }
      return this.account.companyImage.getURL;
    },
    sortedScopes() {
      return shallowCopy(this.value.scopes).filter((s) => s?.deletedAt === undefined).sort((a, b) => {
        const aStatic = this.isStaticUserScope(a);
        const bStatic = this.isStaticUserScope(b);
        if (aStatic === bStatic) return 0;
        if (aStatic === true) return 1;
        return -1;
      });
    },
  },
  methods: {
    canRenderAccessPolicyScope,
    isStaticUserScope(accessPolicyScope) {
      return accessPolicyScope.scope?.children[0]?.children[0]?.scope.type === userScopeType.staticUsers || false;
    },
    userFromStaticUserScope(accessPolicyScope) {
      return accessPolicyScope.scope?.children[0]?.children[0]?.scope.staticUsers[0];
    },
    onError() {
      this.snackbar.error(this.$t('error.duringCopying'));
    },
    updateAccount(value) {
      const updated = {
        ...this.value,
        accountAccess: value,
      };

      const emitUpdate = () => {
        this.$emit('input', updated);
        this.$emit('update:value', updated);
      };
      if (this.suppressConfirm || this.hasFullAccessAfterChange({}, value)) {
        emitUpdate();
        return;
      }

      this.$confirm({
        title: this.$t('accessPolicy.changeText'),
        okText: this.$t('general.change'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          emitUpdate();
        },
      });
    },
    showScopeSelector() {
      if (this.disabled) {
        return;
      }
      this.showModal = true;
    },
    scopeSubmitted(scope) {
      this.showModal = false;
      this.updateScopes(spreadAccessPolicyScopes(scope));
    },
    scopeTypeChanged(scope, newType) {
      const newScope = copy(scope);
      newScope.accessType = newType;

      if (newType === REMOVE) {
        newScope.accessType = accessPolicyType.disabled;
        this.removeScope(newScope);
        return;
      }

      if (this.suppressConfirm || this.hasFullAccessAfterChange(newScope, this.value.accountAccess)) {
        this.updateScopes([newScope]);
        return;
      }
      this.confirmChange([newScope]);
    },
    hasFullAccessAfterChange(newScope, newAccountAccess) {
      const accessPolicy = copy(this.value);
      accessPolicy.accountAccess = newAccountAccess;
      updateObjectInArray(accessPolicy.scopes, newScope);
      return getAccessTypeOfUser({
        accessPolicy,
        creator: this.creator,
      }, this.loggedInUser) === accessPolicyType.full;
    },
    confirmChange(newScope) {
      const update = this.updateScopes;
      this.$confirm({
        title: this.$t('accessPolicy.changeText'),
        okText: this.$t('general.change'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          update(newScope);
        },
      });
    },
    removeScope(scope) {
      const deletedAt = DateTime.local().toISO();
      const scopesToDelete = [{ ...scope, deletedAt }];

      if (this.suppressConfirm || this.hasFullAccessAfterChange(scope, this.value.accountAccess)) {
        this.updateScopes(scopesToDelete);
        return;
      }
      this.confirmChange(scopesToDelete);
    },
    updateScopes(newScopes) {
      const hasher = new UserScopeTreeHasher();
      const fillHashesToAccessPolicyScope = (aps) => {
        if (!isNullOrUndefined(aps.scope)) {
          const hash = hasher.treeHash(aps.scope);
          return { ...aps, scopeHash: hash, scope: { ...aps.scope, treeHash: hash } };
        }
        return aps;
      };
      let scopes = this.value.scopes.map(fillHashesToAccessPolicyScope);
      const newSc = newScopes.map(fillHashesToAccessPolicyScope);
      scopes = addOrUpdate(scopes, newSc, 'scopeHash');
      const updated = {
        ...this.value,
        scopes,
      };
      this.$emit('input', updated);
      this.$emit('update:value', updated);
    },
    clipboardSuccessHandler() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/loading";

  .access-policy {
    min-width: 40rem;
    max-width: 60rem;

    ._scopes {
      max-height: 75vh;
      overflow: auto;

      &.-loading {
        pointer-events: none;

        @include loading;
      }
    }

    ._global {
      width: 3rem;
    }

    ._title {
      font-weight: $font-weight-semibold;
    }

    ._subtitle {
      font-size: $font-size-2;
      color: $font-color-secondary;
    }

    ._list-item {
      display: flex;
      flex: 1 1 auto;
      flex-wrap: wrap;
      align-items: center;

      &.-scope {
        min-height: 5.4rem;
      }

      ._left {
        margin-right: 1.2rem;
      }

      ._middle {
        flex: 1 1 0;
      }

      ._right {
        margin-left: auto;
      }
    }

    ._actions {
      display: flex;

      ._btn {
        flex: 1 1 50%;

        &.-page-link {
          margin-left: .8rem;
        }
      }
    }
  }
</style>
