<template>
  <m-context-menu
    ref="contextMenu"
    class="goals-context-menu"
    :relocate-key="showMenu"
    @hide="reset"
  >
    <m-card
      v-if="showMenu === 'menu'"
      list
      no-padding
    >
      <m-card-item
        icon="delete"
        :disabled="editableGoals.length === 0"
        :tooltip="editableGoalsTooltip"
        :loading="deleteLoading"
        @click="handleDelete"
      >
        {{ $t('general.delete') }}
        <template
          v-if="cannotEditAllGoals && editableGoals.length > 0"
          #right
        >
          <div class="_right">
            <m-tooltip>
              <m-icon
                type="warning"
                class="_icon"
              />
              <template #title>
                {{
                  $t('goalsContextMenu.deleteHint', { title: goalSettings.featureNamePlural })
                }}
              </template>
            </m-tooltip>
          </div>
        </template>
      </m-card-item>
      <m-card-item
        v-if="canCreate"
        icon="copy"
        :loading="duplicateLoading"
        @click="handleDuplicate"
      >
        {{ $t('general.duplicate') }}
      </m-card-item>
      <m-card-item
        v-if="canCreate && canDuplicateToCycle"
        icon="retweet"
        @click="duplicateToCycle"
      >
        {{ $t('goalContextMenu.duplicateToCycle') }}
      </m-card-item>
      <edit-goal-property-item
        :disabled="editableGoals.length === 0"
        :tooltip="editableGoalsTooltip"
        trigger="hover"
        @edit-property="editProperty"
      />
      <m-card-item
        icon="lock"
        :disabled="fullAccessGoals.length === 0"
        :tooltip="editableGoalsTooltip"
        @click="changeAccess({ goals: editableGoals })"
      >
        {{ $t('goalsContextMenu.changeAccess') }}
        <template
          v-if="cannotChangeAccessAllGoals && fullAccessGoals.length > 0"
          #right
        >
          <div class="_right">
            <m-tooltip>
              <m-icon
                type="warning"
                class="_icon"
              />
              <template #title>
                {{
                  $t('goalsContextMenu.changeAccessHint', { title: goalSettings.featureNamePlural })
                }}
              </template>
            </m-tooltip>
          </div>
        </template>
      </m-card-item>
      <template v-if="addableToDashboardPublishedItems.length !== 0">
        <m-divider
          xxs
        />
        <m-card-item
          emoji="ReconciliationOutlined"
          :tooltip="addableToDashboardItemsTooltip"
          :disabled="addableToDashboardItems.length === 0"
          @click="handleAddToDashboard"
        >
          {{ $t('goalsContextMenu.addToDashboard') }}
          <template
            v-if="cannotAddToDashboardAllGoals && addableToDashboardItems.length > 0"
            #right
          >
            <div class="_right">
              <m-tooltip>
                <m-icon
                  type="warning"
                  class="_icon"
                />
                <template #title>
                  {{ $t('goalsContextMenu.addToDashboardHint', { title: goalSettings.featureNamePlural }) }}
                </template>
              </m-tooltip>
            </div>
          </template>
        </m-card-item>
      </template>
      <template v-if="showExpandButtons">
        <m-divider
          xxs
        />
        <m-card-item
          icon="expand-plus"
          @click="handleExpandAll"
        >
          {{ $t('documentListHeader.expandAll') }}
        </m-card-item>
        <m-card-item
          icon="expand-minus"
          @click="handleCollapseAll"
        >
          {{ $t('documentListHeader.collapseAll') }}
        </m-card-item>
      </template>
    </m-card>
    <m-card
      v-else-if="showMenu === 'prop-editor'"
      padding-xs
    >
      <property-editor
        :prop="prop"
        :goals="toEdit"
        :success-message="successMessage"
        :goal-base-filter="goalBaseFilter"
        @close="hideMenu"
        @edited="$emit('prop-edited', prop)"
      />
    </m-card>
    <m-card
      v-else-if="showMenu === 'access-editor'"
      no-padding
    >
      <access-editor
        :items="toEdit"
        :default-access-policy="newGoalDefaultAccessPolicy"
        :update-entities-fn="updateGoalsAccessPolicy"
        :success-message="successMessage"
        :access-types="[accessPolicyType.read, accessPolicyType.comment, accessPolicyType.write, accessPolicyType.full]"
        @close="hideMenu"
        @edited="$emit('access-edited')"
      />
    </m-card>
    <m-card
      v-else-if="showMenu === 'add-to-grid-editor'"
      padding-xs
    >
      <add-to-grid-editor
        :items="selectedGoals"
        @close="hideMenu"
      />
    </m-card>
  </m-context-menu>
</template>

<script>
import AccessEditor from '@/components/access-policy/AccessEditor.vue';
import AddToGridEditor from '@/components/custom-grid/AddToGridEditor.vue';
import EditGoalPropertyItem from '@/components/goal/EditGoalPropertyItem.vue';
import PropertyEditor from '@/components/goal/PropertyEditor.vue';
import useAccess from '@/composables/access/access';
import useDeleteGoal from '@/composables/goal/delete-goal';
import useDuplicateGoal from '@/composables/goal/duplicate-goal';
import useGoalAccessPolicy from '@/composables/goal/access-policy';
import useGoalPickerFilter from '@/composables/goal/goal-picker-filter';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { accessPolicyType, goalProgressMeasurement, propertyType } from 'shared/constants.json';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'GoalsContextMenu',
  props: {
    goalIds: {
      type: Array,
      required: true,
    },
    showExpandButtons: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['prop-edited', 'access-edited', 'expand-all', 'collapse-all', 'goals-deleted', 'goals-duplicated'],
  setup() {
    const { accountHasFeature } = useAccess();
    const { userLang } = useLoggedInUser();
    const { goalPickerFilter } = useGoalPickerFilter();
    const { deleteLoading, showDeleteGoalModal } = useDeleteGoal();
    const { updateAccessPolicy } = useGoalAccessPolicy();
    const { duplicate, prepareDuplicate, duplicateLoading } = useDuplicateGoal();
    const { goalSettings, newGoalDefaultAccessPolicy } = useGoalSettings();
    const { selectMultiple } = useGoals();
    return {
      accountHasFeature,
      goalBaseFilter: goalPickerFilter,
      updateGoalsAccessPolicy: updateAccessPolicy,
      duplicate,
      prepareDuplicate,
      duplicateLoading,
      goalSettings,
      selectMultiple,
      newGoalDefaultAccessPolicy,
      deleteLoading,
      showDeleteGoalModal,
      userLang,
    };
  },
  components: {
    AddToGridEditor,
    EditGoalPropertyItem,
    PropertyEditor,
    AccessEditor,
  },
  data() {
    return {
      showMenu: 'menu',
      prop: null,
      toEdit: [],
      successMessage: '',
      accessPolicyType,
    };
  },
  computed: {
    selectedGoals() {
      return this.selectMultiple(this.goalIds);
    },
    editableGoals() {
      return this.selectedGoals.filter((g) => [accessPolicyType.write, accessPolicyType.full].includes(g.accessRight));
    },
    fullAccessGoals() {
      return this.selectedGoals.filter((g) => g.accessRight === accessPolicyType.full);
    },
    cannotEditAllGoals() {
      return this.editableGoals.length !== this.selectedGoals.length;
    },
    cannotChangeAccessAllGoals() {
      return this.fullAccessGoals.length !== this.selectedGoals.length;
    },
    editableGoalsTooltip() {
      if (this.editableGoals.length > 0) {
        return '';
      }

      return this.$t('goalsContextMenu.cannotDeleteMultiple', { title: this.goalSettings.featureNamePlural });
    },
    canDuplicateToCycle() {
      return !this.editableGoals.some((g) => g.publishedAt === null);
    },
    addableToDashboardPublishedItems() {
      return this.selectedGoals.filter((g) => g.publishedAt !== null && g.publishedAt !== undefined);
    },
    addableToDashboardItems() {
      return this.selectedGoals.filter((g) => {
        const validProgressMeasurement = [goalProgressMeasurement.alignedItems, goalProgressMeasurement.threshold, goalProgressMeasurement.continuous].includes(g.progressMeasurement);
        const isPublished = g.publishedAt !== null && g.publishedAt !== undefined;
        return validProgressMeasurement && isPublished;
      });
    },
    cannotAddToDashboardAllGoals() {
      return this.addableToDashboardItems.length !== this.selectedGoals.length;
    },
    addableToDashboardItemsTooltip() {
      if (this.addableToDashboardItems.length > 0) {
        return '';
      }

      return this.$t('goalContextMenu.cannotAddToDashboard');
    },
  },
  methods: {
    handleDelete() {
      this.showDeleteGoalModal({ goals: this.editableGoals }).then(() => {
        this.onDelete();
      });
    },
    handleDuplicate() {
      this.duplicate(this.selectedGoals).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.duplicated') });
        this.$emit('goals-duplicated');
        this.onDuplicate();
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    handleExpandAll() {
      this.$emit('expand-all');
      this.hideMenu();
    },
    handleCollapseAll() {
      this.$emit('collapse-all');
      this.hideMenu();
    },
    hideMenu() {
      if (this.$refs.contextMenu === null) {
        return;
      }
      this.$refs.contextMenu.hide();
    },
    show(event) {
      this.$refs.contextMenu.show(event);
    },
    onDelete() {
      this.$emit('goals-deleted');
      this.hideMenu();
    },
    onDuplicate() {
      this.$refs.contextMenu.hide();
    },
    reset() {
      this.showMenu = 'menu';
    },
    editProperty({ prop }) {
      this.prop = prop;
      this.toEdit = this.editableGoals;
      this.showMenu = 'prop-editor';
      this.successMessage = this.$t('success.updated');
    },
    changeAccess() {
      this.toEdit = this.fullAccessGoals;
      this.showMenu = 'access-editor';
      this.successMessage = this.$t('success.updated');
    },
    duplicateToCycle() {
      this.prop = {
        label: { [this.userLang]: this.$t('goalsFilter.cycle') },
        edgeName: goalConfig.edges.goalCycle,
        type: propertyType.options,
      };
      this.toEdit = this.prepareDuplicate(this.editableGoals);
      this.successMessage = this.$t('success.duplicated');
      this.showMenu = 'prop-editor';
    },
    handleAddToDashboard() {
      this.selectedGoals = this.addableToDashboardItems;
      this.showMenu = 'add-to-grid-editor';
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
