<template>
  <m-dropdown
    v-model:value="show"
    :block="block"
    class="goal-create-dropdown"
    :trigger="trigger"
    :title="$t('goalCreateDropdown.title')"
    :match-trigger-width="matchTriggerWidth"
    :placement="placement"
    @hide="$emit('hide')"
  >
    <div
      :style="{ width: '100%' }"
      @click.stop="handleClick"
    >
      <slot :disabled="allowedSubItemGoalTypes.length === 0" />
    </div>
    <template #overlay>
      <m-card
        list
      >
        <m-card-item
          v-for="(item, i) in allowedSubItemGoalTypes"
          :key="i"
          @click="handleItemClick(item)"
        >
          {{ getLabel(item.label) }}
        </m-card-item>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { depth, idFromIndex } from '@/lib/sort-operation';
import {
  getAllowedParentItemGoalTypes,
  getAllowedSubItemGoalTypes,
  goalTypeOption,
} from '@/composables/goal/allowed-sub-items';
import { intersectionBy } from 'lodash-es';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'GoalCreateDropdown',
  props: {
    matchTriggerWidth: {
      type: Boolean,
      default: false,
    },
    goal: {
      type: Object,
      default: () => null,
    },
    index: {
      type: String,
      default: '',
    },
    block: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottomCenter',
    },
    insertPosition: {
      type: String,
      default: 'bottom',
    },
    trigger: {
      type: String,
      default: 'click',
    },
  },
  emits: ['hide', 'create-beneath'],
  setup() {
    const { loggedInUser } = useLoggedInUser();

    const { goalTypeProperty } = useGoalTypeProperty();
    const goalSelector = useGoals();

    return {
      userLang: loggedInUser.value.language,
      goalTypeProperty,
      goalSelector,
    };
  },
  data() {
    return { show: false };
  },
  computed: {
    parent() {
      const d = depth(this.index);
      if (d === 0) {
        return null;
      }
      const parentId = idFromIndex(this.index, d - 1);
      return this.goalSelector.selectSingle(parentId);
    },
    allowedSubItemGoalTypes() {
      if (this.goal === null) {
        return getAllowedSubItemGoalTypes(null, this.goalTypeProperty);
      }
      if (this.insertPosition === 'top') {
        if (this.parent === null) {
          return getAllowedParentItemGoalTypes(goalTypeOption(this.goal, this.goalTypeProperty), this.goalTypeProperty);
        }
        const allowedChildren = getAllowedSubItemGoalTypes(goalTypeOption(this.parent, this.goalTypeProperty), this.goalTypeProperty);
        const allowedParents = getAllowedParentItemGoalTypes(goalTypeOption(this.goal, this.goalTypeProperty), this.goalTypeProperty);
        return intersectionBy(allowedChildren, allowedParents, 'uid');
      }
      if (['right', 'left'].includes(this.insertPosition)) {
        // if parent is null, we can use all types
        if (this.parent === null) {
          return this.goalTypeProperty.options.map((o) => ({ ...o, property: this.goalTypeProperty }));
        }
        return getAllowedSubItemGoalTypes(goalTypeOption(this.parent, this.goalTypeProperty), this.goalTypeProperty);
      }
      return getAllowedSubItemGoalTypes(goalTypeOption(this.goal, this.goalTypeProperty), this.goalTypeProperty);
    },
  },
  methods: {
    getLabel(label) {
      const res = textByLang(label, this.userLang);
      if (res === '') {
        return this.$t('general.untitled');
      }
      return res;
    },
    handleClick() {
      if (this.allowedSubItemGoalTypes.length === 0) {
        return;
      }

      if (this.allowedSubItemGoalTypes.length === 1) {
        this.handleItemClick(this.allowedSubItemGoalTypes[0]);
        return;
      }

      this.show = true;
    },
    handleItemClick(item) {
      this.$emit('create-beneath', { parent: this.goal, option: item });
      this.show = false;
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>
