<template>
  <div
    class="m-avatar"
  >
    <div
      :style="style"
      class="_content"
    >
      <img
        v-if="showImage"
        class="_img"
        :src="src"
        @error="imgLoadFailed = true"
      >
      <m-icon
        v-else-if="showIcon"
        :size="`${iconSize}`"
        :type="icon"
      />
      <div v-else-if="showInitials">
        {{ initial }}
      </div>
    </div>
  </div>
</template>

<script>
import { automaticColor, rgbaToHex } from 'shared/lib/color';
import { getColor } from 'shared/lib/color-map';
import { isLightColor, shadeColor } from 'shared/lib/style';
import { optionColor } from 'shared/constants.json';

export default {
  name: 'MAvatar',
  props: {
    color: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 16,
    },
    src: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    shape: {
      type: String,
      default: 'circle',
    },
    amountChars: {
      type: Number,
      default: 2,
    },
    text: {
      type: String,
      default: '',
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: Number,
      default: -1,
    },
    colorSet: {
      type: Array,
      default: () => [],
    },
    blackAndWhite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { imgLoadFailed: false };
  },
  computed: {
    showIcon() {
      return this.icon !== '';
    },
    showImage() {
      return this.src !== '' && !this.imgLoadFailed;
    },
    showInitials() {
      return !this.showIcon && !this.showImage;
    },
    iconSize() {
      if (this.shape === 'circle' && this.backgroundColor !== 'transparent') {
        return this.size * 0.5;
      }
      return this.size * 0.8;
    },
    style() {
      let fontSize = Math.floor(this.size * 0.5);
      if (this.fontSize !== -1) {
        fontSize = this.fontSize;
      }

      const style = {
        backgroundColor: this.backgroundColor,
        width: `${this.size}px`,
        height: `${this.size}px`,
        fontSize: `${fontSize}px`,
        lineHeight: 1,
        color: 'white',
      };

      if (isLightColor(this.backgroundColor)) {
        style.color = rgbaToHex(this.$colors.grey.darken4);
      }

      if (this.blackAndWhite) {
        style.backgroundColor = 'white';
        style.color = rgbaToHex(this.$colors.grey.darken4);
      }

      if (this.blackAndWhite && this.showInitials) {
        style.border = `1px solid ${rgbaToHex(this.$colors.grey.darken4)}`;
        if (this.size > 50) {
          style.border = `3px solid ${rgbaToHex(this.$colors.grey.darken4)}`;
        }
      }

      if (this.showImage) {
        style.backgroundColor = 'transparent';
      }

      if (this.bordered && !this.blackAndWhite && this.showInitials) {
        style.border = `1px solid ${shadeColor(this.backgroundColor, -15)}`;
      }
      if (this.shape === 'circle') {
        style.borderRadius = '100%';
      }
      if (this.shape === 'square') {
        const borderRadius = this.size / 6;
        if (borderRadius <= 15 && borderRadius >= 2) {
          style.borderRadius = `${borderRadius}px`;
        }
        if (borderRadius < 2) {
          style.borderRadius = '2px';
        }
        if (borderRadius > 15) {
          style.borderRadius = '15px';
        }
      }
      return style;
    },
    initial() {
      if (this.amountChars === 1) {
        return this.text.substring(0, 1).toUpperCase();
      }
      if (this.text.trim().indexOf(' ') > -1) {
        const s = this.text.split(' ');
        return `${s[0].substring(0, 1).toUpperCase()}${s[1].substring(0, 1).toUpperCase()}`;
      }
      return this.text.substring(0, this.amountChars).toUpperCase();
    },
    colors() {
      if (this.colorSet.length > 0) {
        return this.colorSet;
      }

      return optionColor.all.map((c) => getColor(c));
    },
    backgroundColor() {
      if (this.color !== '') {
        return this.color;
      }

      return automaticColor(this.text, this.colors);
    },
  },
  watch: {
    src() {
      this.imgLoadFailed = false;
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  .m-avatar {
    ._content {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      font-weight: $font-weight-medium;
      text-align: center;
      user-select: none;

      ._img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
</style>
