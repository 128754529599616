import { chartGroupByOption } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

const CACHED_VALUE_SEPARATOR = '___COMMA___';

export const parseCachedValue = (cachedValue) => {
  const s = `${cachedValue}`.split(CACHED_VALUE_SEPARATOR);
  return s.join(', ');
};

export const getDayFromISOString = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

export const getWeekFromISOString = (dateString) => {
  const date = new Date(dateString);

  // The use of Thursday to calculate the week number is based on the ISO 8601 standard for week numbering. Here's why Thursday is used:
  // ISO 8601 Standard: The ISO 8601 standard defines the first week of the year as the week with the first Thursday in it. This means that the week starts on Monday, and Thursday is used as a reference point to determine the week number.
  // Set the date to the nearest Thursday: current date + 4 - current day number
  // Make Sunday (0) the last day of the week
  date.setDate(date.getDate() + 4 - (date.getDay() || 7));

  // Get the first day of the year
  const yearStart = new Date(date.getFullYear(), 0, 1);

  // Calculate full weeks to the nearest Thursday
  const weekNumber = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);

  // Return the year and week number
  return `${date.getFullYear()}-W${weekNumber.toString().padStart(2, '0')}`;
};

export const getMonthFromISOString = (dateString, locale = 'en') => {
  const date = new Date(dateString);
  const month = date.toLocaleString(locale, { month: 'short' }); // Get abbreviated month name
  const year = date.getFullYear();
  return `${month} ${year}`;
};

export const getYearFromISOString = (dateString) => {
  const date = new Date(dateString);
  return date.getFullYear();
};

export const getQuarterFromISOString = (dateString) => {
  const date = new Date(dateString);
  const quarter = Math.ceil((date.getMonth() + 1) / 3);
  return `Q${quarter} ${date.getFullYear()}`;
};

export const dateGroupingFunctions = {
  [chartGroupByOption.day]: getDayFromISOString,
  [chartGroupByOption.week]: getWeekFromISOString,
  [chartGroupByOption.month]: getMonthFromISOString,
  [chartGroupByOption.quarter]: getQuarterFromISOString,
  [chartGroupByOption.year]: getYearFromISOString,
};

export const edgeName = (groupBy, t, userLang) => {
  if (!groupBy.isProperty) {
    return t(`edges.${groupBy.edge}`);
  }
  return textByLang(groupBy.property.label, userLang);
};

export const edgeNameWithAggregator = (groupBy, t, userLang) => {
  const edgeName = edgeName(groupBy, t, userLang);
  const val = groupBy.groupBy === undefined ? groupBy.aggregator : groupBy.groupBy;
  return `${t(`gridPageChart.${val}`)} (${edgeName})`;
};
