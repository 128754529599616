<template>
  <div
    v-if="error !== null"
    class="_alert"
  >
    <m-alert
      type="error"
      :message="error"
    />
  </div>
  <MetricDisplay
    v-else-if="chart.chartType === gridPageChartType.metric"
    :value="metricResult"
    :unit="metricUnit"
    :loading="loading"
    :size="size"
  />
  <chart
    v-else
    :options="chartOptions"
    class="_chart"
  />
</template>

<script setup>
import MetricDisplay from '@/components/custom-grid/chart/MetricDisplay.vue';
import useChartDataQuery from '@/composables/chart-data-query';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePieChart from '@/composables/grid-page/chart/pie-chart';
import { Chart } from 'highcharts-vue';
import { aggregatorType, gridPageChartType } from 'shared/constants.json';
import { computed, toRef } from 'vue';
import { edgeName } from '@/composables/grid-page/chart/utils';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  chart: { type: Object, required: true },
  size: { type: String, default: 'medium', validator: (value) => ['small', 'medium', 'large'].includes(value) },
});

const { loading, result, error } = useChartDataQuery(toRef(props, 'chart'));

const { chartOptions: pieChartOptions } = usePieChart(result, toRef(props, 'chart'));

const { userLang } = useLoggedInUser();

const chartOptions = computed(() => {
  const baseOptions = { chart: { animation: false } };

  switch (props.chart.chartType) {
    case 'bar':
      return {
        ...baseOptions,
        chart: { ...baseOptions.chart, type: 'bar' },
        series: [{ data: [1, 2, 3, 4, 5] }],
      };
    case 'line':
      return {
        ...baseOptions,
        chart: { ...baseOptions.chart, type: 'line' },
        series: [{ data: [1, 2, 3, 4, 5] }],
      };
    case 'pie':
      return pieChartOptions.value;
    default:
      return baseOptions;
  }
});

const metricResult = computed(() => {
  if (props.chart.chartType !== gridPageChartType.metric || result.value === null) {
    return null;
  }

  return Number(result.value.toFixed(2));
});

const { t } = useI18n();

const metricUnit = computed(() => {
  if (props.chart.chartType !== gridPageChartType.metric) {
    return null;
  }

  switch (props.chart.aggregationConfig.aggregation.aggregator) {
    case aggregatorType.count: {
      return t(`gridPageChart.${props.chart.source}`);
    }
    case aggregatorType.sum: {
      return `${t('gridPageChart.sum', { edge: edgeName(props.chart.aggregationConfig.aggregation, t, userLang.value) })}`;
    }
    case aggregatorType.avg: {
      return `${t('gridPageChart.avg', { edge: edgeName(props.chart.aggregationConfig.aggregation, t, userLang.value) })}`;
    }
    case aggregatorType.min: {
      return `${t('gridPageChart.min', { edge: edgeName(props.chart.aggregationConfig.aggregation, t, userLang.value) })}`;
    }
    case aggregatorType.max: {
      return `${t('gridPageChart.max', { edge: edgeName(props.chart.aggregationConfig.aggregation, t, userLang.value) })}`;
    }
    case aggregatorType.countDistinct: {
      return `${t('gridPageChart.countDistinct', { edge: edgeName(props.chart.aggregationConfig.aggregation, t, userLang.value) })}`;
    }
    default:
      return null;
  }
});
</script>

<style scoped lang="scss">
._alert {
  width: 100%;
}
</style>
