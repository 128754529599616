import colors from 'shared/colors';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { aggregatorType, gridPageChartType } from 'shared/constants.json';
import { computed, nextTick } from 'vue';
import { edgeNameWithAggregator, parseCachedValue } from '@/composables/grid-page/chart/utils';
import { useI18n } from 'vue-i18n';

export default function usePieChart(result, chart) {
  const { userLang } = useLoggedInUser();
  const { t } = useI18n();

  const series = computed(() => {
    if (chart.value.chartType !== gridPageChartType.pie) {
      return [];
    }

    if (result.value === null) {
      return [];
    }

    return result.value.map((item) => ({
      name: parseCachedValue(item.val),
      y: item.count,
    }));
  });

  const total = computed(() => {
    if (result.value === null) {
      return 0;
    }
    switch (chart.value.aggregationConfig.aggregation.aggregator) {
      case aggregatorType.count:
        return result.value.reduce((acc, item) => acc + item.count, 0);
      default:
        return result.value.length;
    }
  });

  const totalLabel = computed(() => {
    switch (chart.value.aggregationConfig.aggregation.aggregator) {
      case aggregatorType.count:
        return t('gridPageChart.total');
      default:
        return '';
    }
  });

  const chartOptions = computed(() => ({
    chart: {
      animation: false,
      type: 'pie',
      events: {
        render() {
          nextTick(() => {
            const chart = this;
            const center = chart.series[0].center;
            const centerX = chart.plotLeft + center[0];
            const centerY = chart.plotTop + center[1];

            const radius = center[2] / 2;
            const titleFontSize = Math.min(Math.max(radius * 0.3, 12), 52);
            const labelDistance = Math.max(titleFontSize * 0.5, 13);

            if (chart.customText) chart.customText.destroy();
            if (chart.customLabel) chart.customLabel.destroy();

            chart.customText = chart.renderer
              .text(total.value, centerX, centerY)
              .css({
                fontSize: `${titleFontSize}px`,

                fontWeight: 'bold',
                textAlign: 'center',
              })
              .attr({ align: 'center' })
              .add();

            chart.customLabel = chart.renderer
              .text(totalLabel.value, centerX, centerY + labelDistance)
              .css({
                fontSize: '12px',
                color: colors.grey.darken2,
              })
              .attr({ align: 'center' })
              .add();
          });
        },
      },
    },
    legend: { itemStyle: { fontSize: '14px' } },
    plotOptions: { pie: { innerSize: '80%' } },
    credits: { enabled: false },
    series: [
      { data: series.value },
    ],
    tooltip: {
      formatter() {
        return `
              <b>${edgeNameWithAggregator(chart.value.groupBy, t, userLang.value)} ${this.point.name}:</b> ${this.point.y}
            `;
      },
    },
  }));

  return { chartOptions };
}
