import { ALL_ALIAS, AND, HAS, NOT, RESULT, TYPE, UID, UID_IN, VAR } from 'shared/api/query/constants';
import { OR } from '@/lib/filter/scope-tree';
import { UNASSIGNED } from '@/lib/constants';
import { buildCustomFuncProgressCoursePred } from '@/api/query/custom-func-helper';
import { combine } from 'shared/api/query/filter';
import { getChildren } from '@/api/query/nebula/goal';
import { goal } from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';
import { searchTermFilter } from '@/lib/filter/search-term';

export const createGoalCycleFilter = (selectedGoalCycles) => {
  if (selectedGoalCycles.length === 0) {
    return null;
  }

  const cycles = selectedGoalCycles.filter((c) => c.uid !== UNASSIGNED);
  const hasUnassigned = selectedGoalCycles.find((c) => c.uid === UNASSIGNED) !== undefined;
  if (!hasUnassigned) {
    return { func: { name: UID_IN, attr: goal.edges.goalCycle, args: cycles.map((c) => ({ value: `${c.uid}` })) } };
  }

  if (cycles.length === 0) {
    return {
      op: NOT,
      child: [
        { func: { name: HAS, attr: goal.edges.goalCycle } },
      ],
    };
  }

  return {
    op: OR,
    child: [
      { func: { name: UID_IN, attr: goal.edges.goalCycle, args: cycles.map((c) => ({ value: `${c.uid}` })) } },
      {
        op: NOT,
        child: [
          { func: { name: HAS, attr: goal.edges.goalCycle } },
        ],
      },
    ],
  };
};

const getChildrenFilter = (filter, searchTermFilter, goalCycleFilter, applyFilterOnFirstLevelOnly) => {
  if (applyFilterOnFirstLevelOnly) {
    return combine(AND, [searchTermFilter, goalCycleFilter]);
  }
  return combine(AND, [filter, searchTermFilter, goalCycleFilter]);
};

// When an order exists, sort by the defined order otherwise use the default sorting.
// Default sorting groups first by goal types and then uses the children order.
export const goalCascadeList = ({
  pagination,
  goalCycles = [],
  filter = null,
  varBlocks = [],
  searchTerm = '',
  progressCourse = [],
  progressCourseAlias = '',
  order = [],
  alias = RESULT,
  allAlias = ALL_ALIAS,
  childrenPagination = { itemsPerPage: 10, page: 1 },
  excludedGoalIds = [],
  applyFilterOnFirstLevelOnly = false,
  includeChildren = true,
  includeParents = true,
  includeGrandparents = false,
}) => {
  const cycleGoalsVar = 'cycle_goals';
  const titleFilter = searchTermFilter({ searchTerm, edgeName: goal.edges.title });
  const goalCycleFilter = createGoalCycleFilter(goalCycles);
  const firstLevelFilter = combine(AND, [
    titleFilter,
    goalCycleFilter,
    filter,
  ]);

  const childrenOrder = order.filter((o) => o.attr !== goal.edges.cachedParents);

  const childrenFilter = getChildrenFilter(filter, titleFilter, goalCycleFilter, applyFilterOnFirstLevelOnly);

  const getVars = () => {
    const vars = [{ name: 'result_goals', typ: 1 }];
    if (includeChildren) {
      vars.push(...[
        { name: 'goal_children', typ: 1 },
        { name: 'parents_of_children_goals', typ: 1 },
      ]);
    }
    if (includeParents) {
      vars.push({ name: 'parent_goals', typ: 1 });
    }
    if (includeGrandparents) {
      vars.push({ name: 'grandparent_goals', typ: 1 });
    }
    return vars;
  };

  const children = () => {
    const children = [{ attr: UID, var: 'result_goals' }];
    if (includeChildren) {
      children.push({
        attr: reverseEdge(goal.edges.parents),
        model: goal.model,
        pagination: childrenPagination,
        filter: applyFilterOnFirstLevelOnly ? null : childrenFilter,
        order,
        children: [
          { attr: UID, var: 'goal_children' },
          { attr: goal.edges.parents, model: goal.model, children: [{ attr: UID, var: 'parents_of_children_goals' }] },
        ],
      });
    }
    if (includeParents) {
      const parents = { attr: goal.edges.parents, model: goal.model, children: [{ attr: UID, var: 'parent_goals' }] };
      if (includeGrandparents) {
        parents.children.push({ attr: goal.edges.parents, model: goal.model, children: [{ attr: UID, var: 'grandparent_goals' }] });
      }
      children.push(parents);
    }
    return children;
  };

  const customFuncs = [];
  if (progressCourseAlias !== '') {
    customFuncs.push(buildCustomFuncProgressCoursePred({ alias: progressCourseAlias, attr: reverseEdge(goal.edges.paysOnto), timeSeries: progressCourse }));
  }

  return [
    {
      alias: VAR,
      model: goal.model,
      func: { name: TYPE, args: [{ value: goal.model }] },
      filter: firstLevelFilter,
      children: [{ attr: UID, var: cycleGoalsVar }],
    },
    {
      alias: VAR,
      model: goal.model,
      needsVar: [{ name: cycleGoalsVar, typ: 1 }],
      func: { name: UID, needsVar: [{ name: cycleGoalsVar, typ: 1 }] },
      default: [],
      children: [
        {
          attr: goal.edges.parents,
          model: goal.model,
          filter: combine(AND, [firstLevelFilter, { func: { name: UID, needsVar: [{ name: cycleGoalsVar, typ: 1 }] } }]),
          children: [
            {
              alias: 'children',
              attr: reverseEdge(goal.edges.parents),
              model: goal.model,
              var: 'goals_with_parent',
            },
          ],
        },
      ],
    },
    {
      model: goal.model,
      alias,
      needsVar: [
        { name: cycleGoalsVar, typ: 1 },
      ],
      func: { name: UID, needsVar: [{ name: cycleGoalsVar, typ: 1 }] },
      filter: {
        op: AND,
        child: [
          {
            op: NOT,
            child: [
              { func: { name: UID, needsVar: [{ name: 'goals_with_parent', typ: 1 }] } },
            ],
          },
        ],
      },
      order,
      pagination,
      default: [],
      children: children(),
    },
    {
      model: goal.model,
      alias: allAlias,
      needsVar: getVars(),
      func: {
        name: UID,
        needsVar: getVars(),
      },
      filter: excludedGoalIds.length > 0 ? { op: NOT, child: [{ func: { name: 'uid', uid: excludedGoalIds } }] } : null,
      order,
      default: [],
      children: getChildren({
        childrenFilter,
        childrenOrder,
        customFuncs,
      }),
    },
    ...varBlocks,
  ];
};
