import { CREATED_AT } from 'shared/api/query/constants';
import { propertyType } from 'shared/constants.json';

export const directProperties = ($t) => ([
  {
    label: $t('general.createdAt'),
    edgeName: CREATED_AT,
    type: propertyType.date,
    isTitle: false,
    hideInProps: true,
    noFilter: false,
    showByDefault: true,
  },
]);
