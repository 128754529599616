<template>
  <access-policy-scope-row
    :access-type="accessType"
    :access-types="accessTypes"
    :disabled="disabled"
    @change="emit('change', $event)"
  >
    <template #display>
      <user-display
        :user="user"
        large
      >
        <template #subName>
          <div class="_subtitle">
            <slot name="description">
              {{ user.email }}
            </slot>
          </div>
        </template>
      </user-display>
    </template>
    <template #access-policy-type-selector>
      <slot name="access-policy-type-selector" />
    </template>
  </access-policy-scope-row>
</template>
<script setup>

import AccessPolicyScopeRow from '@/components/access-policy/AccessPolicyScopeRow.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useUsers from '@/composables/user/users';
import { accessPolicyType } from 'shared/constants.json';
import { computed } from 'vue';

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  accessType: {
    type: String,
    required: true,
  },
  accessTypes: {
    type: Array,
    default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['change']);

const userSvc = useUsers();
const user = computed(() => (props.user.uid ? userSvc.selectSingle(props.user.uid) : props.user));

</script>

<style scoped lang="scss" type="text/scss">
._subtitle {
  font-size: $font-size-2;
  color: $font-color-secondary;
}
</style>
