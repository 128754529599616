<template>
  <div
    class="user-list-card"
  >
    <m-table
      :columns="columns"
      :data-source="rows"
      class="_table"
      :custom-row="handleRowClick"
      :pagination="{ pageSize: 50 }"
      rows-clickable
      show-count
      hide-vertical-border
    >
      <template #name="{ row }">
        <user-display
          :user="row"
          xl
        >
          <template #subName>
            <div class="_subtitle">
              {{ row.email }}
            </div>
          </template>
        </user-display>
      </template>
      <template #status="{ status, row }">
        <div class="_status">
          <template v-if="status === USER_DEMO">
            <m-icon
              type="dot"
              size="28"
              :color="getIconColor(status)"
              :style="{ width: '1.6rem', marginRight: '.4rem', marginLeft: '.6rem' }"
            />
            <div>{{ $t(`userList.${status}`) }}</div>
          </template>
          <m-dropdown
            v-else
            :title="$t('userList.status')"
            close-on-click
            @click.stop
          >
            <m-btn
              hide-border
              xs
            >
              <m-icon
                type="dot"
                size="28"
                :color="getIconColor(status)"
                :style="{ width: '1.6rem', marginRight: '.4rem' }"
              />
              {{ $t(`userList.${status}`) }}
            </m-btn>
            <template #overlay>
              <m-card list>
                <m-card-item
                  v-if="status !== USER_STATUS_ACTIVE"
                  icon="mail"
                  hide-border
                  @click="confirmInviteMail(row)"
                >
                  {{ $t('userListCard.sendInvitationMail') }}
                </m-card-item>
                <m-card-item
                  v-else
                  :clickable="false"
                  @click.stop
                >
                  {{ $t('userList.activatedAt') }} {{ formatDate(row.firstSessionCreatedAt) }}
                </m-card-item>
                <template
                  v-if="status === USER_STATUS_INVITE_PENDING"
                >
                  <m-divider xxs />
                  <m-card-item
                    class="_invited-by-at"
                    light
                    :clickable="false"
                  >
                    {{ getInvitedByAt(row.invitation) }} {{ formatDate(row.invitation.createdAt) }}
                  </m-card-item>
                </template>
              </m-card>
            </template>
          </m-dropdown>
        </div>
      </template>
      <template #space-property="{ row }">
        <template
          v-if="row.spaces.length === 0"
        >
          <div :style="{ color: $colors.grey.base }">
            -
          </div>
        </template>
        <template
          v-else-if="row.spaces.length === 1"
        >
          <div :style="{ display: 'flex' }">
            <m-tag
              :custom-color="{ color: $colors.grey.darken4, backgroundColor: getColor(row.spaces[0].color) }"
              :title="row.spaces[0].title"
              :icon="buildIconFromEntity(row.spaces[0])"
              :style="{ maxWidth: '15rem' }"
            />
          </div>
        </template>
        <m-dropdown
          v-else
          :title="pluralize(textByLang(spaceProperty.label, userLang), userLang)"
          @click.stop
        >
          <m-btn
            hide-border
            small
          >
            {{ row.spaces.length }} {{ pluralize(textByLang(spaceProperty.label, userLang), userLang) }}
            <m-icon
              :style="{ marginLeft: '.4rem' }"
              size="12"
              :color="$colors.grey.lighten1"
              type="down"
            />
          </m-btn>
          <template #overlay>
            <m-card
              list
              no-padding
            >
              <m-card-item
                v-for="space in row.spaces"
                :key="space.uid"
                :clickable="false"
              >
                <m-tag
                  :custom-color="{ color: $colors.grey.darken4, backgroundColor: getColor(space.color) }"
                  :title="space.title"
                  :icon="buildIconFromEntity(space)"
                />
              </m-card-item>
            </m-card>
          </template>
        </m-dropdown>
      </template>
      <template #accessGroups="{ accessGroups }">
        {{ accessGroups }}
      </template>
      <template #actions="{ row }">
        <div class="_actions">
          <m-dropdown
            :title="$t('general.actions')"
            close-on-click
            @click.stop
          >
            <m-btn
              icon="ellipsis"
              fab
              hide-border
              small
            />
            <template #overlay>
              <m-card
                class="_user-actions"
                no-padding
                list
              >
                <m-card-item
                  icon="edit"
                  hide-border
                  @click="editUser(row.uid)"
                >
                  {{ $t('general.edit') }}
                </m-card-item>
                <m-card-item
                  v-if="row.status !== USER_STATUS_ACTIVE"
                  icon="mail"
                  hide-border
                  @click="confirmInviteMail(row)"
                >
                  {{ $t('userListCard.sendInvitationMail') }}
                </m-card-item>
                <m-card-item
                  icon="delete"
                  class="_delete-btn"
                  :disabled="row.uid === loggedInUser.uid"
                  hide-border
                  @click="confirmDelete(row)"
                >
                  {{ $t('general.delete') }}
                </m-card-item>
              </m-card>
            </template>
          </m-dropdown>
        </div>
      </template>
    </m-table>
    <m-drawer
      :title="$t('userListCard.editUser')"
      :value="showDrawer"
      @close="hide"
    >
      <edit-user-form
        :key="selectedUserId"
        v-model:dirty="modalDirty"
        :user="selectedUser"
        :account="account"
        show-cancel
        @update="hide"
        @create="hide"
        @cancel="hide"
        @go-to-access-groups="goToAccessGroups"
        @updated="showDrawer = false"
      />
    </m-drawer>
  </div>
</template>

<script>

import EditUserForm from '@/components/EditUserForm.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useAccessGroup from '@/composables/access/access-group';
import useInvitations from '@/composables/user/invitations/invitations';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import useUsers from '@/composables/user/users';
import { DateTime } from 'luxon';
import { USER_DEMO, USER_STATUS_ACTIVE, USER_STATUS_INVITE_PENDING, USER_STATUS_NO_INVITATION } from '@/lib/constants';
import { buildIconFromEntity } from 'shared/lib/icon';
import { getColor } from 'shared/lib/color-map';
import { logCatch } from '@/lib/logger/logger';
import { pluralize, textByLang } from 'shared/lib/language';
import { propertyType, routeName } from 'shared/constants.json';
import { sortChain } from 'shared/lib/sort';

export default {
  name: 'UserListCard',
  props: {
    account: {
      type: Object,
      required: true,
    },
    entities: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
  },
  components: { EditUserForm, UserDisplay },
  setup() {
    const accessGroupSvc = useAccessGroup();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { getInvitationStatus, deleteUser } = useUsers();
    const { inviteUser } = useInvitations();
    const { spaceProperty } = useProperties();
    return {
      loggedInUser,
      userLang,
      deleteUser,
      getInvitationStatus,
      inviteUser,
      spaceProperty,
      accessGroupSvc,
    };
  },
  data() {
    return {
      searchText: '',
      searchInput: null,
      showDrawer: false,
      selectedUser: null,
      getColor,
      textByLang,
      pluralize,
      USER_STATUS_ACTIVE,
      USER_STATUS_INVITE_PENDING,
      USER_DEMO,
      modalDirty: false,
    };
  },
  computed: {
    selectedUserId() {
      if (this.selectedUser === null) {
        return 0;
      }
      return this.selectedUser.uid;
    },
    columns() {
      const columns = [
        {
          key: 'name',
          dataIndex: 'name',
          title: this.$t('userListCard.name'),
          sorter: this.sorter('firstName'),
          scopedSlots: { customRender: 'name' },
        },
      ];

      if (!this.$store.state.breakpoint.smAndDown) {
        columns.push({
          key: 'status',
          dataIndex: 'status',
          title: this.$t('userList.status'),
          sorter: this.sorter('status'),
          scopedSlots: { customRender: 'status' },
        });

        if (this.spaceProperty !== undefined) {
          columns.push({
            key: 'spaces',
            dataIndex: 'spaces',
            title: textByLang(this.spaceProperty.label, this.userLang),
            scopedSlots: { customRender: 'space-property' },
          });
        }

        columns.push({
          key: 'accessGroups',
          dataIndex: 'accessGroups',
          title: this.$t('userList.accessGroups'),
          sorter: sortChain([this.sorter('accessGroups'), this.sorter('firstName')]),
          scopedSlots: { customRender: 'accessGroups' },
        });
      }

      columns.push({
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        width: '30',
        scopedSlots: { customRender: 'actions' },
      });

      return columns;
    },
    rows() {
      return this.entities.filter((u) => {
        if (this.search === '') {
          return true;
        }
        const s = this.search.toLowerCase();
        return u.firstName.toLowerCase().includes(s) || u.lastName.toLowerCase().includes(s) || u.email.toLowerCase().includes(s);
      }).map((user) => {
        let values = [];
        const status = this.getInvitationStatus(user);
        const accessGroups = this.getAccessGroups(user);
        if (user.values !== undefined) {
          values = user.values.filter((v) => v.property.type === propertyType.space);
        }
        if (values.length === 0) {
          return {
            ...user,
            status,
            accessGroups,
            spaces: [],
          };
        }

        return {
          ...user,
          status,
          accessGroups,
          spaces: values[0].spaces,
        };
      });
    },
  },
  methods: {
    buildIconFromEntity,
    goToAccessGroups() {
      const next = () => this.$router.push({ name: routeName.accessGroupList });
      this.hide(next);
    },
    hide(next = () => {}) {
      const hideFn = () => {
        this.showDrawer = false;
        next();
      };

      if (!this.modalDirty) {
        hideFn();
        return;
      }

      const title = this.$t('general.discardEditPrompt');
      const okText = this.$t('general.yesDiscard');
      const cancelText = this.$t('general.close');
      this.$confirm({
        title,
        okText,
        okType: 'danger',
        cancelText,
        maskClosable: true,
        onOk() {
          hideFn();
        },
      });
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
    },
    getIconColor(status) {
      switch (status) {
        case USER_STATUS_ACTIVE:
          return this.$colors.green.base;
        case USER_STATUS_INVITE_PENDING:
          return this.$colors.yellow.base;
        case USER_STATUS_NO_INVITATION:
          return this.$colors.red.base;
        default:
          return '';
      }
    },
    getAccessGroups(user) {
      const res = this.accessGroupSvc.entityList.value
        .filter((ag) => ag.accessPolicy.scopes.some((next) => !!(next.scopeUsers.map((u) => u.uid).includes(user.uid))))
        .map((ag) => ag.name);

      if (res.length === 0) {
        res.push(this.$t('accessGroupForm.defaultGroupTitle'));
      }
      return res.join(', ');
    },
    getInvitedByAt(invitation) {
      const actor = this.entities.find((u) => u.uid === invitation.actor.uid);
      if (actor === undefined) {
        return this.$t('userList.invitedAt');
      }
      if (actor.uid === this.loggedInUser.uid) {
        return this.$t('userList.invitedByYouAt');
      }
      return this.$t('userList.invitedByAt', { name: `${actor.firstName} ${actor.lastName}`.trim() });
    },
    editUser(userId) {
      this.selectedUser = this.rows.find((user) => user.uid === userId);
      this.showDrawer = true;
    },
    sorter(key) {
      return (a, b) => a[key].localeCompare(b[key]);
    },
    confirmInviteMail(user) {
      const send = () => this.inviteUser(user).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('userListCard.invitationSuccess') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));

      this.$confirm({
        title: this.$t('userListCard.sendInvitationMailConfirm', {
          firstname: user.firstName,
          lastname: user.lastName,
        }),
        okText: this.$t('general.send'),
        cancelText: this.$t('general.cancel'),
        maskClosable: true,
        onOk() {
          send();
        },
      });
    },
    confirmDelete(user) {
      const deleteMethod = () => this.deleteUser(user).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });

      this.$confirm({
        title: this.$t('userList.deleteUserTitle'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        cancelText: this.$t('general.cancel'),
        maskClosable: true,
        onOk() {
          deleteMethod();
        },
      });
    },
    handleRowClick(record) {
      return {
        on: {
          click: () => {
            this.editUser(record.uid);
          },
        },
      };
    },
  },
};
</script>

<style
    lang="scss"
    type="text/scss"
>
  ._subtitle {
    font-size: $font-size-4;
    color: $font-color-secondary;
  }

  ._invited-by-at {
    font-size: $font-size-2;
  }

  ._status {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>
