import { UID } from 'shared/api/query/constants';
import {
  customBucket as customBucketConfig,
} from 'shared/api/query/configs.json';

export const customBucketChildren = [
  { attr: UID },
  { attr: customBucketConfig.edges.bucketType },
  { attr: customBucketConfig.edges.bucketSize },
  { attr: customBucketConfig.edges.min },
  { attr: customBucketConfig.edges.max },
];
