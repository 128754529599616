<template>
  <div class="account-scope-filter">
    <m-spinner
      v-if="loading"
      size="sm"
    />
    <template v-else>
      <m-radio-group
        v-model:value="selectedOption"
        :options="radioOptions"
        default-value="account"
        :disabled="disabled"
        inline
        class="_radio"
      />
      <m-transition-expand>
        <scope-filter
          v-if="isCustom"
          v-model:value="userScopeTree"
          :account="account"
          :props="props"
          :disabled="disabled"
          :show-static-user-selection="showStaticUserSelection"
          :open-with-static-user-selection="openWithStaticUserSelection"
          :max-tag-text-length="staticUsersMaxTagTextLength"
          hide-op
          hide-leaf-op
          class="_filter"
        />
      </m-transition-expand>
    </template>
  </div>
</template>

<script>
import ScopeFilter from '@/components/filter/ScopeFilter.vue';
import { copy } from 'shared/lib/copy';
import { isValidTree } from '@/lib/filter/scope-tree';
import { userScopeOperator } from 'shared/constants.json';

const ACCOUNT = 'account';
const CUSTOM = 'custom';

export default {
  name: 'AccountScopeFilter',
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    props: {
      type: Array,
      default: () => [],
    },
    account: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showStaticUserSelection: {
      type: Boolean,
      default: false,
    },
    openWithStaticUserSelection: {
      type: Boolean,
      default: false,
    },
    staticUsersMaxTagTextLength: {
      type: Number,
      default: 0,
    },
    allText: {
      type: String,
      default: '',
    },
    segmentText: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'update:value'],
  components: { ScopeFilter },
  data() {
    return {
      userScopeTree: null,
      temp: null,
    };
  },
  computed: {
    radioOptions() {
      return [
        { label: this.allLabel, value: ACCOUNT },
        { label: this.segmentLabel, value: CUSTOM },
      ];
    },
    allLabel() {
      if (this.allText === '') {
        return this.$t('accountScopeFilter.all');
      }
      return this.allText;
    },
    segmentLabel() {
      if (this.segmentText === '') {
        return this.$t('accountScopeFilter.segments');
      }
      return this.segmentText;
    },
    isCustom() {
      return this.selectedOption === CUSTOM;
    },
    selectedOption: {
      get() {
        if (this.userScopeTree.scope === null && this.userScopeTree.children.length === 0) {
          return ACCOUNT;
        }
        return CUSTOM;
      },
      set(val) {
        if (val === ACCOUNT) {
          this.temp = this.userScopeTree;
          this.init();
          return;
        }
        if (this.temp === null) {
          this.userScopeTree = {
            account: { uid: this.account.uid },
            op: userScopeOperator.or,
            children: [],
          };
          return;
        }
        this.userScopeTree = this.temp;
      },
    },
  },
  methods: {
    init() {
      this.userScopeTree = {
        account: { uid: this.account.uid },
        op: userScopeOperator.or,
        scope: null,
        children: [],
      };
    },
    emitUpdate(userScopeTree) {
      if (isValidTree(userScopeTree)) {
        this.$emit('input', copy(userScopeTree));
        this.$emit('update:value', copy(userScopeTree));
      }
    },
  },
  watch: {
    userScopeTree: {
      handler(val) {
        this.emitUpdate(val);
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (JSON.stringify(this.userScopeTree) === JSON.stringify(val)) {
          return;
        }
        this.userScopeTree = copy(val);
      },
      deep: true,
    },
  },
  created() {
    if (this.value === null || Object.keys(this.value).length === 0) {
      this.init();
      return;
    }
    this.userScopeTree = copy(this.value);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .account-scope-filter {
    ._filter {
      margin-top: 2rem;
    }
  }
</style>
