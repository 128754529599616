import { BaseHandler } from '@/lib/filter/base-translator/handler';
import { goalActivity } from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

const childrenFn = (varName) => ([
  {
    attr: reverseEdge(goalActivity.edges.properties),
    model: goalActivity.model,
    var: varName,
  },
]);

export class GoalActivityFilterHandler {
  constructor() {
    this.baseHandler = new BaseHandler({
      model: goalActivity.model,
      childrenFn,
      isFilterMode: true,
    });
  }

  Translate(tree) {
    return this.baseHandler.Translate(tree);
  }
}
