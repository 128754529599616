<template>
  <m-tooltip
    :disabled="canChangeAccess"
    placement="bottom"
  >
    <share-dropdown
      :access-policy="gridPage.accessPolicy"
      :creator="gridPage.creator"
      :icon="isPublic ? 'global': 'lock'"
      entity-type="gridPage"
      :button-text="$t('general.share')"
      :access-types="[accessPolicyType.read, accessPolicyType.write, accessPolicyType.full]"
      :page-link="pageLink"
      :creator-description="$t('gridPagesShareDropdown.creatorDescription')"
      :on-update="updateAccessPolicy"
      :disabled="props.disabled || !canChangeAccess"
      :loading="updateAccessPolicyLoading"
    >
      <template
        v-if="!canChangeAccess"
        #before-buttons
      >
        <m-content
          padding-xs
          :padding-bottom="0"
        >
          <allowance-alert
            v-if="!canChangeAccessPrivateToPublic"
            :pages-count="publicPagesCount"
            :pages-allowance="publicPagesAllowance"
            main-message-key="allowanceAlert.messagePublic"
          />
        </m-content>
      </template>
    </share-dropdown>
    <template #title>
      {{ $t('gridPagesShareDropdown.cannotShareNoAllowance') }}
    </template>
  </m-tooltip>
</template>

<script setup>
import AllowanceAlert from '@/components/custom-grid/AllowanceAlert.vue';
import ShareDropdown from '@/components/access-policy/ShareDropdown.vue';
import useGridPage from '@/composables/grid-page/grid-page';
import useGridPageAllowance from '@/composables/grid-page/grid-page-allowance';
import { accessPolicyType } from 'shared/constants.json';
import { computed } from 'vue';

const gridPageSvc = useGridPage();
const { publicPagesCount, publicPagesAllowance, hasPublicAllowance } = useGridPageAllowance();

const props = defineProps({
  gridPage: { type: Object, required: true },
  disabled: { type: Boolean, default: false },
});

const gridPage = computed(() => gridPageSvc.selectSingleGridPage(props.gridPage.uid));

const isPublic = computed(() => gridPage.value.isPublic);

const canChangeAccessPrivateToPublic = computed(() => !isPublic.value && hasPublicAllowance.value);
const canChangeAccessPublicToPrivate = computed(() => isPublic.value);
const canChangeAccess = computed(() => canChangeAccessPublicToPrivate.value || canChangeAccessPrivateToPublic.value);

const pageLink = computed(() => `${window.location.origin}/#/dashboards/${gridPage.value.uid}`);

const { updateAccessPolicyLoading } = gridPageSvc;
const updateAccessPolicy = (val) => {
  const entity = {
    uid: gridPage.value.uid,
    accessPolicy: val,
  };
  gridPageSvc.updateGridPageAccessPolicy(entity);
};

</script>
