<template>
  <m-dropdown
    v-model:value="open"
    class="group-by-selector"
    match-trigger-width
    :title="$t('general.actions')"
    block
  >
    <m-focusable
      type="clickable"
      full-width
    >
      <template v-if="selectedAggregation === null">
        {{ $t('general.empty') }}
      </template>
      <template v-else>
        <m-tag
          :icon="getIcon(selectedAggregation)"
          :title="getValueText(selectedAggregation)"
          color="none"
        />
      </template>
      <template #suffix>
        <m-icon
          :type="open ? 'down' : 'right'"
          :color="$colors.grey.lighten1"
          size="11"
        />
      </template>
    </m-focusable>
    <template #overlay>
      <m-card
        no-padding
        list
      >
        <m-card-item @click="select(null, aggregatorType.count)">
          {{ $t('gridPageChartEditor.count') }}
          <template
            v-if="selectedAggregation.aggregator === aggregatorType.count"
            #right
          >
            <m-icon type="check" />
          </template>
        </m-card-item>
        <template
          v-for="(property, index) in allProperties"
          :key="index"
        >
          <m-divider
            v-if="property.length > 0"
            xxs
          />
          <template
            v-for="option in property"
            :key="option.key"
          >
            <m-card-item
              v-if="getType(option) !== propertyType.number"
              @click="select(option, aggregatorType.countDistinct)"
            >
              {{ getLabel(option) }}
              <template
                v-if="isSelected(option, aggregatorType.countDistinct)"
                #right
              >
                <m-icon type="check" />
              </template>
            </m-card-item>
            <m-dropdown
              v-else
              block
              placement="topRight"
              trigger="hover"
              :title="getLabel(option)"
            >
              <m-card-item
                :icon="getIcon(option)"
                @click="select(option)"
              >
                {{ getLabel(option) }}
                <template #right>
                  <m-icon
                    type="right"
                    :color="$colors.grey.lighten1"
                    size="11"
                  />
                </template>
              </m-card-item>
              <template #overlay>
                <m-card
                  no-padding
                  list
                >
                  <m-card-item
                    v-for="aggregationFn in aggregationOptions"
                    :key="aggregationFn"
                    @click="select(option, aggregationFn)"
                  >
                    {{ t(`groupByOption.${aggregationFn}`) }}
                    <template
                      v-if="isSelected(option, aggregationFn)"
                      #right
                    >
                      <m-icon type="check" />
                    </template>
                  </m-card-item>
                </m-card>
              </template>
            </m-dropdown>
          </template>
        </template>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script setup>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { aggregatorType, propertyType } from 'shared/constants.json';
import { computed, ref } from 'vue';
import { iconByType } from '@/lib/property';
import { textByLang } from 'shared/lib/language';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  value: {
    type: Object,
    default: () => ({}),
  },
  properties: {
    type: Array,
    required: true,
  },
  hiddenAggregatorFunctions: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:value']);

const open = ref(false);

const selectedAggregation = ref(props.value);

const numberProperties = computed(() => props.properties.filter((e) => getType(e) === propertyType.number));
const nonNumberProperties = computed(() => props.properties.filter((e) => getType(e) !== propertyType.number));
const allProperties = computed(() => [numberProperties.value, nonNumberProperties.value]);

const { t } = useI18n();

const getIcon = (option) => {
  if (option.isProperty) {
    return iconByType({ type: option.property.type });
  }

  return iconByType({ type: option.edgeType, edgeName: option.edge });
};

const getType = (option) => {
  if (option.isProperty) {
    return option.property.type;
  }
  return option.edgeType;
};

const { userLang } = useLoggedInUser();

const getLabel = (option) => {
  if (option.isProperty) {
    return textByLang(option.property.label, userLang.value);
  }
  return t(`edges.${option.edge}`);
};

const isSelected = (option, aggregator) => {
  if (option.isProperty !== selectedAggregation.value.isProperty) {
    return false;
  }
  if (aggregator !== selectedAggregation.value.aggregator) {
    return false;
  }
  if (option.isProperty) {
    return selectedAggregation.value.property.uid === option.property.uid;
  }
  return selectedAggregation.value.edge === option.edge;
};

const getValueText = (option) => {
  if (option.aggregator === aggregatorType.count) {
    return t('groupByOption.count');
  }
  const label = getLabel(option);
  if (!props.hiddenAggregatorFunctions.includes(option.aggregator)) {
    return `${label} (${t(`groupByOption.${option.aggregator}`)})`;
  }
  return label;
};

const aggregationOptions = computed(() => [aggregatorType.sum, aggregatorType.avg, aggregatorType.min, aggregatorType.max, aggregatorType.countDistinct]);

function select(option, aggregator = aggregatorType.countDistinct) {
  if (option === null) {
    selectedAggregation.value = { aggregator };
  } else {
    selectedAggregation.value = { ...option, aggregator };
  }
  emit('update:value', selectedAggregation.value);
  open.value = false;
}

</script>

<style scoped>
</style>
