<template>
  <m-content
    padding-xs
    class="access-right-source"
  >
    <m-alert
      class="_source"
      type="info"
      hide-icon
      :style="{padding: '1rem', marginBottom: 0}"
    >
      <user-avatar
        :user="loggedInUser"
        :size="20"
      />
      <div class="_text">
        <div>{{ accessRightText }}</div>
        <m-tooltip
          v-if="via !== null"
          placement="bottom"
          :disabled="!isViaNameTruncated"
          :mouse-enter-delay="0.5"
        >
          <template #title>
            {{ via.name }}
          </template>
          <div class="_via">
            <div class="_icon">
              <m-icon-display
                v-if="!isEmptyIcon(via.avatar)"
                :icon="via.avatar"
                :size="18"
              />
              <m-icon
                v-else
                :type="via.icon"
              />
            </div>
            <div
              ref="titleRef"
              class="_name"
            >
              {{ via.name }}
            </div>
          </div>
        </m-tooltip>
      </div>
    </m-alert>
  </m-content>
</template>
<script setup>
import UserAvatar from 'shared/components/UserAvatar.vue';
import useAccessGroupsLoggedInUser from '@/composables/logged-in-user/access-groups-logged-in-user';
import useElementsTruncate from 'shared/composables/element-truncate';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import usePropertyOptions from '@/composables/property-option/property-option';
import useSpaces from '@/composables/space/spaces';
import { accessPolicyType, propertyType, userScopeType } from 'shared/constants.json';
import { buildIcon, isEmptyIcon } from 'shared/lib/icon';
import { computed, ref } from 'vue';
import { getHighestAccessRight } from '@/lib/access-policy';
import { iconByType } from '@/lib/property';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { textByLang } from 'shared/lib/language';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  entity: {
    type: Object,
    required: true,
  },
  entityType: {
    type: String,
    default: '',
  },
});

const titleRef = ref(null);
const { isTruncated: isViaNameTruncated } = useElementsTruncate([titleRef]);

const { loggedInUser, userLang } = useLoggedInUser();
const { loggedInUserAccount: account } = useLoggedInUserAccount();

const { accessGroups } = useAccessGroupsLoggedInUser();
const superAccessMap = {
  goal: 'goal',
  gridPage: 'dashboard',
};

const isCreator = !isNullOrUndefined(props.entity.creator) ? loggedInUser.value.uid === props.entity.creator.uid : false;

const accessPolicy = computed(() => props.entity.accessPolicy);

const propertySvc = useProperties();
const propertyOptionSvc = usePropertyOptions();
const spaceSvc = useSpaces();

const viaAccessPolicyScope = (aps) => {
  const userScope = aps.scope.children[0].children[0].scope;

  if (userScope.type === userScopeType.staticUsers) {
    return null;
  }

  const property = propertySvc.selectSingle(userScope.property.uid);
  const scopeIcon = iconByType(property);

  let scopeName;
  switch (property.type) {
    case propertyType.options:
    case propertyType.singleSelect:
    {
      const propertyOption = propertyOptionSvc.selectSingle(userScope.selectedOptions[0].uid);
      scopeName = textByLang(propertyOption.label, userLang);
      break;
    }
    case propertyType.space:
    {
      const space = spaceSvc.selectSingle(userScope.spaces[0].uid);
      scopeName = space.title;
      break;
    }
    default:
  }

  return { icon: scopeIcon, name: scopeName };
};

const viaAccessRight = computed(() => {
  let res = { via: null, accessRight: props.entity.accessRight ? props.entity.accessRight : accessPolicyType.disabled };
  if (isCreator) {
    res.accessRight = accessPolicyType.full;
    return res;
  }

  if (superAccessMap[props.entityType] !== undefined) {
    for (let i = 0; i < accessGroups.value.length; i++) {
      const group = accessGroups.value[i];
      if (group[`${superAccessMap[props.entityType]}SuperAccess`] === true) {
        return {
          via: { icon: 'lock', name: group.name },
          accessRight: accessPolicyType.full,
        };
      }
    }
  }

  if (accessPolicy.value.accountAccess !== accessPolicyType.disabled) {
    res = {
      via: { name: account.value.companyName },
      accessRight: accessPolicy.value.accountAccess,
    };
    if (account.value.companyImage !== null) {
      res.via.avatar = buildIcon(account.value.companyImage);
    } else {
      res.via.icon = 'global';
    }
  }
  if (res.accessRight === accessPolicyType.full) { return res; }

  // TODO: Once we have space-based AP linking, via can be the space (or some other resource that is part of the chain link)

  for (let i = 0; i < accessPolicy.value.scopes.length; i++) {
    const aps = accessPolicy.value.scopes[i];
    if (aps.userIsInScope) {
      if (res.accessRight !== aps.accessType && getHighestAccessRight([res.accessRight, aps.accessType]) === aps.accessType) {
        res = {
          via: viaAccessPolicyScope(aps),
          accessRight: aps.accessType,
        };
      }
    }
    if (res.accessRight === accessPolicyType.full) { return res; }
  }
  return res;
});

const via = computed(() => viaAccessRight.value.via);
const accessRight = computed(() => viaAccessRight.value.accessRight);

const accessRightText = computed(() => {
  let tKey = 'accessRightSource.default';
  if (via.value !== null) {
    tKey = 'accessRightSource.via';
  }
  return t(tKey, {
    entityType: t(`accessRightSource.entityType.${props.entityType !== '' ? props.entityType : 'default'}`),
    accessRight: t(`accessRightSource.accessRight.${accessRight.value}`),
  });
});

</script>
<style scoped lang="scss">
.access-right-source {
  max-width: 60rem;

  ._source {
    display: flex;
    gap: .8rem;

    ._text {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      gap: .6rem;
      font-size: $font-size-2;
    }

    ._via {
      display: flex;
      align-items: center;
      gap: .6rem;
    }
  }
}
</style>
