import { CREATED_AT } from 'shared/api/query/constants';
import {
  GOAL_PROGRESS,
  GOAL_RELATION,
  TIME_DIFF,
} from '@/lib/props/custom-types';
import { goal } from 'shared/api/query/configs.json';
import { propertyType } from 'shared/constants.json';
import { reverseEdge } from 'shared/api/query/edges';

export const referencesForeignSpace = ({ propertyValues, user }) => {
  const userTeams = user.values.filter((pv) => pv.property.type === propertyType.space).map((pv) => pv.spaces).reduce((res, cur) => [...res, ...cur], []).map((s) => s.uid);
  const spacePvs = propertyValues.filter((pv) => pv.property.type === propertyType.space);
  const foreign = spacePvs.filter((pv) => pv.spaces.filter((s) => !userTeams.includes(s.uid)).length > 0);

  return foreign.length > 0;
};

export const directProperties = ($t, goalCycles) => ([
  {
    label: $t('goalsFilter.title'),
    edgeName: goal.edges.title,
    type: propertyType.text,
    isTitle: true,
    hideInProps: false,
    disableInProps: true,
    noSortInProps: true,
    noFilter: true,
    showByDefault: true,
    width: 450,
  },
  {
    label: $t('goalsFilter.description'),
    edgeName: goal.edges.description,
    type: propertyType.richText,
    hideInProps: false,
    width: 200,
  },
  {
    label: $t('goalsFilter.cycle'),
    edgeName: goal.edges.goalCycle,
    type: propertyType.options,
    showByDefault: false,
    noFilter: true,
    width: 120,
    options: goalCycles.map((c) => ({
      text: c.title,
      color: c.color,
      value: c.uid,
    })),
  },
  {
    label: $t('general.createdAt'),
    type: propertyType.date,
    edgeName: CREATED_AT,
    showByDefault: false,
    width: 120,
    component: TIME_DIFF,
  },
  {
    label: $t('goalsFilter.lastUpdatedAt'),
    type: propertyType.date,
    edgeName: goal.edges.cachedLastUpdatedAt,
    showByDefault: false,
    width: 130,
    component: TIME_DIFF,
  },
  {
    label: $t('goalsFilter.parent'),
    type: GOAL_RELATION,
    edgeName: goal.edges.parents,
    showByDefault: false,
    hideInProps: true,
    width: 150,
  },
  {
    label: $t('goalsFilter.children'),
    type: GOAL_RELATION,
    edgeName: reverseEdge(goal.edges.parents),
    edgeAlias: 'children',
    showByDefault: false,
    hideInProps: true,
    width: 150,
  },
  {
    label: $t('goalsFilter.progress'),
    edgeName: goal.edges.cachedCalculatedCurrent,
    type: propertyType.number,
    component: GOAL_PROGRESS,
    showByDefault: true,
    width: 150,
  },
]);

export const goalChartProperties = ($t) => ([
  {
    label: $t('goalsFilter.title'),
    edgeName: goal.edges.title,
    type: propertyType.text,
    isTitle: true,
    hideInProps: false,
    disableInProps: true,
    noSortInProps: true,
    noFilter: true,
    showByDefault: true,
    width: 450,
  },
  {
    label: $t('goalsFilter.description'),
    edgeName: goal.edges.description,
    type: propertyType.richText,
    hideInProps: false,
    width: 200,
  },
  {
    label: $t('general.createdAt'),
    type: propertyType.date,
    edgeName: CREATED_AT,
    showByDefault: false,
    width: 120,
    component: TIME_DIFF,
  },
  {
    label: $t('goalsFilter.lastUpdatedAt'),
    type: propertyType.date,
    edgeName: goal.edges.cachedLastUpdatedAt,
    showByDefault: false,
    width: 130,
    component: TIME_DIFF,
  },
  {
    label: $t('goalsFilter.progress'),
    edgeName: goal.edges.cachedCalculatedCurrent,
    type: propertyType.number,
    component: GOAL_PROGRESS,
    showByDefault: true,
    width: 150,
  },
]);
