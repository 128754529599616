<template>
  <table-header
    class="goal-table-header"
    :props="propsWithHeaderActions"
    :has-empty-row="hasEmptyRow"
    :filler-column-width="fillerColumnWidth"
    :read-only="readOnly"
    @show-menu="$emit('show-menu', $event)"
    @cell-dragstart="$emit('cell-dragstart', $event)"
    @cell-drag="$emit('cell-drag', $event)"
    @cell-dragend="$emit('cell-dragend', $event)"
    @click-action="handleClickAction($event)"
    @select-all-clicked="$emit('select-all-clicked')"
  >
    <template
      v-if="showCheckBox"
      #beforeCells
    >
      <select-all-checkbox
        class="_select-all-checkbox"
        :selection-state="selectionState"
        @select-all-clicked="$emit('select-all-clicked')"
      />
    </template>
    <template #custom-menu-action="{ item }">
      <template v-if="item.headerActions.includes('compareTo')">
        <compare-to-previous
          :value="compareToValue"
          component="card-item"
          @input="setCompareTo"
        />
      </template>
      <template v-if="item.headerActions.includes('progressDisplayOption')">
        <goal-progress-menu
          :progress-display-option="progressDisplayOption"
          @input="setProgressDisplayOption"
        />
      </template>
    </template>
  </table-header>
</template>

<script>

import CompareToPrevious from '@/components/list/CompareToPrevious.vue';
import GoalProgressMenu from '@/components/goal/GoalProgressMenu.vue';
import SelectAllCheckbox from '@/components/SelectAllCheckbox.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalsSorting from '@/composables/goal/sort';
import usePlanningDetailsCtx from '@/composables/planning/planning-details-context';
import useViewParamsProps from '@/composables/view-params/view-params-props';
import { DIRECT_PROPERTY_CYCLE_KEY, DIRECT_PROPERTY_DESCRIPTION_KEY, VIEWS_SERVICE } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { SELECTION_STATE_NONE_SELECTED } from '@/composables/select';
import { computed, inject } from 'vue';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { propertyType } from 'shared/constants.json';

export default {
  name: 'GoalTableHeader',
  props: {
    props: {
      type: Array,
      required: true,
    },
    hasEmptyRow: {
      type: Boolean,
      default: false,
    },
    fillerColumnWidth: {
      type: Number,
      default: 40,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showCheckBox: {
      type: Boolean,
      default: false,
    },
    selectionState: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { inCtx: inPlanningDetailsCtx } = usePlanningDetailsCtx();
    const { properties: goalProperties } = useGoalProperty();
    const { sortOptions } = useGoalsSorting(goalProperties);

    const viewsService = inject(VIEWS_SERVICE);
    const { props: viewProps } = useViewParamsProps(viewsService.currentView);

    const progressDisplayOption = computed(() => viewsService.currentView.value.params.props.find((p) => p.key === goalConfig.edges.cachedCalculatedCurrent).progressDisplayOption);

    const compareToValue = computed(() => viewsService.currentView.value.params.compare);
    return {
      inPlanningDetailsCtx,
      sortOptions,
      viewsService,
      viewProps,
      progressDisplayOption,
      compareToValue,
    };
  },
  components: { SelectAllCheckbox, CompareToPrevious, GoalProgressMenu, TableHeader },
  emits: ['cell-dragstart', 'cell-drag', 'cell-dragend', 'show-menu', 'scroll-top', 'select-all-clicked'],
  data() {
    return { SELECTION_STATE_NONE_SELECTED };
  },
  computed: {
    propsWithHeaderActions() {
      const headerActions = (prop) => {
        const aa = [];
        // standard headerActions
        if (!prop.disableInProps) {
          aa.push('hide');
        }
        if (![DIRECT_PROPERTY_DESCRIPTION_KEY].includes(prop.key)) {
          aa.push('sort');
        }
        if (!this.inPlanningDetailsCtx
            && ![DIRECT_PROPERTY_DESCRIPTION_KEY].includes(prop.key)) {
          aa.push('filter');
        }

        // custom headerActions
        if (prop.key === goalConfig.edges.cachedCalculatedCurrent) {
          aa.push(...['compareTo', 'progressDisplayOption']);
        }
        return aa;
      };
      return this.props.map((p) => ({ ...p, headerActions: headerActions(p) }));
    },
  },
  methods: {
    updateViewParams(value, key) {
      this.viewsService.setParams(
        this.viewsService.currentView.value,
        {
          ...this.viewsService.currentView.value.params,
          [key]: value,
        },
      );
    },
    setCompareTo(val) {
      this.updateViewParams(val, 'compare');
    },
    setProgressDisplayOption(option) {
      const updatedProps = this.viewsService.currentView.value.params.props.map((p) => {
        if (p.key !== goalConfig.edges.cachedCalculatedCurrent) {
          return p;
        }

        return { ...p, progressDisplayOption: option };
      });
      this.updateViewParams(updatedProps, 'props');
    },
    handleSortClicked(prop, desc) {
      const findCorrespondingAttribute = (key, haystack) => {
        if (key === goalConfig.edges.goalCycle) {
          key = goalConfig.edges.cachedGoalCycle;
        }
        for (let i = 0; i < haystack.length; i++) {
          if (haystack[i].attr === key) {
            return haystack[i];
          }
          if (haystack[i].langs !== undefined && haystack[i].langs[0].includes(key)) {
            return haystack[i];
          }
        }
        return null;
      };

      const val = findCorrespondingAttribute(prop.key, this.sortOptions);
      if (val === null) {
        return;
      }
      this.updateViewParams([{ attr: val.attr, desc, langs: val.langs }], 'order');
    },
    handleFilterClicked(prop) {
      this.$emit('scroll-top');
      if (prop.key === DIRECT_PROPERTY_CYCLE_KEY) {
        EventBus.$emit('applyCycle');
        return;
      }
      if (prop.property.type === propertyType.text || prop.property.type === propertyType.richText) {
        EventBus.$emit('applyText');
        return;
      }
      EventBus.$emit('applyFilter', prop);
    },
    handleHideClicked(prop) {
      const updatedProps = this.viewsService.currentView.value.params.props.map((p) => {
        if (p.key !== prop.key) {
          return p;
        }

        return { ...prop, show: false };
      });
      this.updateViewParams(updatedProps, 'props');
    },
    handleClickAction({ item, action, input }) {
      switch (action) {
        case 'sort':
          this.handleSortClicked(item, input);
          break;
        case 'filter':
          this.handleFilterClicked(item);
          break;
        case 'hide':
          this.handleHideClicked(item);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
.goal-table-header {
  ._select-all-checkbox {
    position: absolute;
    left: -2rem;
  }
}
</style>
