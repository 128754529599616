import { isNullOrUndefined } from 'shared/lib/object/object';
import { propertyType, userScopeType } from 'shared/constants.json';

const hasUid = (object) => object !== null && object.uid !== undefined;

const hasValidProperty = (scope) => scope.type === userScopeType.property
  && !isNullOrUndefined(scope.property)
  && hasUid(scope.property)
  && scope.property.type !== undefined;
const hasValidDirectProperty = (scope) => scope.type === userScopeType.directProperty
  && !isNullOrUndefined(scope.directProperty)
  && !isNullOrUndefined(scope.directProperty.edgeName)
  && !isNullOrUndefined(scope.directProperty.type);

const canRenderSpaceScope = (scope) => hasValidProperty(scope)
  && scope.property.type === propertyType.space
  && Array.isArray(scope.spaces)
  && scope.spaces.length > 0;

const canRenderDirectSpaceScope = (scope) => hasValidDirectProperty(scope)
  && scope.directProperty.type === propertyType.space
  && Array.isArray(scope.spaces)
  && scope.spaces.length > 0;

const canRenderOptionScope = (scope) => hasValidProperty(scope)
  && [propertyType.options, propertyType.singleSelect].includes(scope.property.type)
  && Array.isArray(scope.selectedOptions)
  && scope.selectedOptions.length > 0;

const canRenderDirectOptionScope = (scope) => hasValidDirectProperty(scope)
  && [propertyType.options, propertyType.singleSelect].includes(scope.directProperty.type)
  && Array.isArray(scope.selectedOptions)
  && scope.selectedOptions.length > 0;

const hasValidStaticUsers = (scope) => scope.type === userScopeType.staticUsers
  && !isNullOrUndefined(scope.staticUsers);
const canRenderStaticUsers = (scope) => hasValidStaticUsers(scope)
  && Array.isArray(scope.staticUsers)
  && scope.staticUsers.length > 0;

const canRenderScope = (scope) => {
  if (isNullOrUndefined(scope)) {
    return false;
  }

  return (
    canRenderStaticUsers(scope)
    || canRenderSpaceScope(scope)
    || canRenderOptionScope(scope)
    || canRenderDirectSpaceScope(scope)
    || canRenderDirectOptionScope(scope)
  );
};

const canRenderTree = (tree) => {
  if (isNullOrUndefined(tree)) {
    return false;
  }
  if (!Array.isArray(tree.children) || tree.children.length === 0) {
    return canRenderScope(tree.scope);
  }
  return tree.children.every((next) => canRenderTree(next));
};

export const canRenderAccessPolicyScope = (aps) => {
  if (isNullOrUndefined(aps)) {
    return false;
  }
  return canRenderTree(aps.scope);
};
