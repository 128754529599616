<template>
  <m-card
    list
    no-padding
    @mouseenter="showVisibility = false"
  >
    <m-card-item
      v-if="showEditLink"
      icon="setting"
      @click="goToEditPage"
    >
      {{ $t('propertyLabel.editProperty') }}
    </m-card-item>
    <m-dropdown
      placement="topRight"
      trigger="hover"
      close-on-click
      :title="$t('propertyLabel.hideProperty')"
    >
      <m-card-item
        icon="eye-invisible"
        :disabled="!canCustomize"
        :tooltip="!canCustomize ? $t('propertyMenu.goalTypeRequired') : ''"
        tooltip-placement="left"
      >
        {{ $t('propertyLabel.hideProperty') }}
        <template #right>
          <m-icon
            type="chevron-right"
            :color="$colors.grey.lighten2"
          />
        </template>
      </m-card-item>
      <template #overlay>
        <m-card
          list
          no-padding
          :style="{ minWidth: '20rem' }"
        >
          <m-card-item
            @click="setVisibility(property, propertyVisibility.alwaysVisible)"
          >
            {{ $t('propertyLabel.alwaysVisible') }}
            <template #right>
              <m-icon
                v-if="property.goalVisibility === propertyVisibility.alwaysVisible"
                type="check-mark"
              />
            </template>
          </m-card-item>
          <m-card-item
            v-if="canBeEmpty"
            @click="setVisibility(property, propertyVisibility.hideWhenEmpty)"
          >
            {{ $t('propertyLabel.hideWhenEmpty') }}
            <template #right>
              <m-icon
                v-if="property.goalVisibility === propertyVisibility.hideWhenEmpty"
                type="check-mark"
              />
            </template>
          </m-card-item>
          <m-card-item
            @click="setVisibility(property, propertyVisibility.alwaysHidden)"
          >
            {{ $t('propertyLabel.alwaysHide') }}
            <template #right>
              <m-icon
                v-if="property.goalVisibility === propertyVisibility.alwaysHidden"
                type="check-mark"
              />
            </template>
          </m-card-item>
        </m-card>
      </template>
    </m-dropdown>
    <m-divider xxs />
    <m-dropdown
      block
      :title="$t('propertyLabel.customizePage')"
      @hide="handleHide"
    >
      <m-card-item
        icon="file-text"
        :disabled="!canCustomize"
        :tooltip="!canCustomize ? $t('propertyMenu.goalTypeRequired') : ''"
        tooltip-placement="left"
      >
        {{ $t('propertyLabel.customizePage') }}
      </m-card-item>
      <template #overlay>
        <m-card
          no-padding
          list
        >
          <customize-page :goal="goal" />
        </m-card>
      </template>
    </m-dropdown>
  </m-card>
</template>

<script>
import CustomizePage from '@/components/goal/CustomizePage.vue';
import { EventBus } from '@/lib/event-bus';
import { propertyVisibility, routeName } from 'shared/constants.json';

export default {
  name: 'PropertyMenu',
  props: {
    property: {
      type: Object,
      required: true,
    },
    showEditLink: {
      type: Boolean,
      default: false,
    },
    goal: {
      type: Object,
      required: true,
    },
    canCustomize: {
      type: Boolean,
      default: false,
    },
    canBeEmpty: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['set-visibility', 'hide-customize-page'],
  components: { CustomizePage },
  data() {
    return { propertyVisibility };
  },
  methods: {
    goToEditPage() {
      if (this.property.isGoalType) {
        this.$router.push({ name: routeName.goalTypes });
        return;
      }
      this.$router.push({ name: routeName.propertysNoSelection, query: { propertyId: this.property.uid } });
    },
    setVisibility(property, visibility) {
      this.$emit('set-visibility', property, visibility);
    },
    handleHide() {
      EventBus.$emit('hide-customize-page');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
