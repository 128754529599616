import { AND, NOT } from '@/lib/filter/scope-tree';

const intersection = (uidSets) => uidSets.reduce((a, b) => a.filter((v) => b.includes(v)), uidSets[0]);

const union = (uidSets) => [...new Set(uidSets.flat())];

// extractSpaceIds is an approximation of spaces represented by a goal
// filter. Approximation is achieved, by only looking at space based
// filters. Any other filter type is ignored.
//
// ust = user scope tree
export const extractSpaceIds = ({ filter, properties, spaces }) => {
  if (filter === null) {
    return [];
  }

  const usts = filter.children.map((ust) => ust.children.map((inner) => ({ ...inner, op: ust.op }))).flat()
    .filter((ust) => ust.scope.spaces !== undefined)
    .filter((ust) => ust.scope.spaces.length > 0)
    .filter((ust) => ust.scope.property !== undefined)
    .filter((ust) => properties.find((p) => p.uid === ust.scope.property.uid) !== undefined);

  const ustSpaces = usts.map((ust) => {
    const scopeSpaces = ust.scope.spaces;

    if (ust.op === NOT) {
      return spaces.filter((space) => !scopeSpaces.map((sp) => sp.uid).includes(space.uid));
    }

    return scopeSpaces;
  });

  if (ustSpaces.length === 0) {
    return [];
  }

  switch (filter.op) {
    case AND:
      return intersection(ustSpaces.map((spaces) => spaces.map((space) => space.uid)));
    default:
      return union(ustSpaces.map((spaces) => spaces.map((space) => space.uid)));
  }
};
