<template>
  <component
    :is="componentType"
    ref="contextMenu"
    class="goal-context-menu"
    :relocate-key="relocateKey"
    @hide="hide"
  >
    <m-card
      v-if="showMenu === 'menu'"
      list
      no-padding
    >
      <template v-if="!readOnly">
        <m-card-item
          :disabled="!userCanWrite"
          icon="delete"
          :tooltip="userCanWriteTooltip"
          :loading="deleteLoading"
          @click="handleDelete"
        >
          {{ $t('general.delete') }}
        </m-card-item>
        <m-card-item
          v-if="canCreate"
          icon="copy"
          :loading="duplicateLoading"
          @click="handleDuplicate"
        >
          {{ $t('general.duplicate') }}
        </m-card-item>
        <m-card-item
          v-if="canCreate && canDuplicateToCycle"
          icon="retweet"
          @click="duplicateToCycle({ goal })"
        >
          {{ $t('goalContextMenu.duplicateToCycle') }}
        </m-card-item>
        <goal-create-dropdown
          v-if="canCreate && allowedSubItemGoalTypes.length > 0"
          :goal="goal"
          block
          placement="topRight"
          trigger="hover"
          @create-beneath="createBeneath"
        >
          <m-card-item
            icon="apartment"
            :loading="createLoading"
          >
            {{ $t('goalContextMenu.addBeneath') }}
            <template
              v-if="allowedSubItemGoalTypes.length > 1"
              #right
            >
              <m-icon
                type="chevron-right"
                :color="$colors.grey.lighten1"
              />
            </template>
          </m-card-item>
        </goal-create-dropdown>
        <edit-goal-property-item
          :disabled="!userCanWrite"
          :tooltip="userCanWriteTooltip"
          trigger="hover"
          @edit-property="editProperty"
        />
        <m-card-item
          v-if="canChangeAccess"
          icon="lock"
          @click="changeAccess"
        >
          {{ $t('goalsContextMenu.changeAccess') }}
        </m-card-item>
        <m-card-item
          v-if="goal.progressMeasurement === goalProgressMeasurement.alignedItems"
          icon="sync"
          :style="{ minWidth: '30rem' }"
          :tooltip="$t('goal.detailHeader.statusUpdateTooltip')"
          :loading="automaticStatusUpdateLoading"
          tooltip-placement="left"
          @click="toggleStatusUpdate(goal).then(hideMenu)"
        >
          <m-switch
            :value="!goal.disableStatusAutoUpdate"
            small
            :disabled="!userCanWrite"
            :label="$t('goal.detailHeader.disableStatusAutoUpdate')"
          />
        </m-card-item>
        <template v-if="canAddToDashboardsIsPublished">
          <m-divider
            xxs
          />
          <m-card-item
            emoji="ReconciliationOutlined"
            :tooltip="canAddToDashboardsTooltip"
            :disabled="!canAddToDashboards"
            @click="handleAddToDashboard"
          >
            {{ $t('goalsContextMenu.addToDashboard') }}
          </m-card-item>
        </template>
        <m-divider
          v-if="showExpandButtons"
          xxs
        />
      </template>
      <template v-if="showExpandButtons">
        <m-card-item
          icon="expand-plus"
          @click="handleExpandAll"
        >
          {{ $t('documentListHeader.expandAll') }}
        </m-card-item>
        <m-card-item
          icon="expand-minus"
          @click="handleCollapseAll"
        >
          {{ $t('documentListHeader.collapseAll') }}
        </m-card-item>
      </template>
    </m-card>
    <m-card
      v-else-if="showMenu === 'prop-editor'"
      padding-xs
    >
      <property-editor
        :prop="prop"
        :goals="selectedGoals"
        :ignore-prefilled="ignorePrefilled"
        :success-message="successMessage"
        :goal-base-filter="goalBaseFilter"
        @close="hideMenu"
        @edited="$emit('prop-edited', prop)"
      />
    </m-card>
    <m-card
      v-else-if="showMenu === 'access-editor'"
      no-padding
    >
      <access-editor
        :items="selectedGoals"
        :default-access-policy="newGoalDefaultAccessPolicy"
        :update-entities-fn="updateGoalsAccessPolicy"
        :success-message="successMessage"
        :access-types="[accessPolicyType.read, accessPolicyType.comment, accessPolicyType.write, accessPolicyType.full]"
        @input="relocateKey += 1"
        @close="hideMenu"
        @edited="$emit('access-edited')"
      />
    </m-card>
    <m-card
      v-else-if="showMenu === 'add-to-grid-editor'"
      padding-xs
    >
      <add-to-grid-editor
        :items="selectedGoals"
        :success-message="successMessage"
        @close="hideMenu"
      />
    </m-card>
  </component>
</template>

<script>
import AccessEditor from '@/components/access-policy/AccessEditor.vue';
import AddToGridEditor from '@/components/custom-grid/AddToGridEditor.vue';
import ComponentList from '@/components/editor/ComponentList';
import EditGoalPropertyItem from '@/components/goal/EditGoalPropertyItem.vue';
import GoalCreateDropdown from '@/components/goal/GoalCreateDropdown.vue';
import PropertyEditor from '@/components/goal/PropertyEditor.vue';
import useAccess from '@/composables/access/access';
import useDeleteGoal from '@/composables/goal/delete-goal';
import useDuplicateGoal from '@/composables/goal/duplicate-goal';
import useGoalAccessPolicy from '@/composables/goal/access-policy';
import useGoalPickerFilter from '@/composables/goal/goal-picker-filter';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useStatusAutoUpdate from '@/composables/goal/status-auto-update';
import { accessPolicyType, goalProgressMeasurement, propertyType } from 'shared/constants.json';
import { getAllowedSubItemGoalTypes, goalTypeOption } from '@/composables/goal/allowed-sub-items';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'GoalContextMenu',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    createLoading: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    componentType: {
      type: String,
      default: 'm-context-menu',
    },
    showExpandButtons: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['prop-edited', 'access-edited', 'expand-all', 'collapse-all', 'hide', 'goals-deleted', 'create-beneath', 'goals-duplicated', 'relocate'],
  setup(props) {
    const { accountHasFeature } = useAccess();
    const { goalPickerFilter } = useGoalPickerFilter(props.goal);
    const { goalTypeProperty } = useGoalTypeProperty();
    const { deleteLoading, showDeleteGoalModal } = useDeleteGoal();
    const { updateAccessPolicy } = useGoalAccessPolicy();
    const { duplicateSingle, prepareDuplicate, duplicateLoading } = useDuplicateGoal();
    const { userLang } = useLoggedInUser();
    const { automaticStatusUpdateLoading, toggleStatusUpdate } = useStatusAutoUpdate();

    const { goalSettings, newGoalDefaultAccessPolicy } = useGoalSettings();
    return {
      automaticStatusUpdateLoading,
      toggleStatusUpdate,
      goalSettings,
      newGoalDefaultAccessPolicy,
      accountHasFeature,
      goalBaseFilter: goalPickerFilter,
      goalTypeProperty,
      updateGoalsAccessPolicy: updateAccessPolicy,
      duplicateSingle,
      prepareDuplicate,
      duplicateLoading,
      deleteLoading,
      showDeleteGoalModal,
      userLang,
    };
  },
  components: { AddToGridEditor, GoalCreateDropdown, ComponentList, PropertyEditor, EditGoalPropertyItem, AccessEditor },
  data() {
    return {
      showMenu: 'menu',
      prop: null,
      selectedGoals: [],
      ignorePrefilled: false,
      successMessage: '',
      goalProgressMeasurement,
      accessPolicyType,
      relocateKey: 0,
    };
  },
  computed: {
    allowedSubItemGoalTypes() {
      if (this.goal === null) {
        return [];
      }
      return getAllowedSubItemGoalTypes(goalTypeOption(this.goal, this.goalTypeProperty), this.goalTypeProperty);
    },
    userCanWrite() {
      return [accessPolicyType.write, accessPolicyType.full].includes(this.goal.accessRight);
    },
    userCanWriteTooltip() {
      if (this.userCanWrite) {
        return '';
      }

      return this.$t('goalContextMenu.cannotDelete', { title: this.goalSettings.featureNameSingular });
    },
    canChangeAccess() {
      return this.goal.accessRight === accessPolicyType.full;
    },
    canDuplicateToCycle() {
      return this.goal.publishedAt !== null;
    },
    canAddToDashboardsValidProgressMeasurement() {
      return [goalProgressMeasurement.alignedItems, goalProgressMeasurement.threshold, goalProgressMeasurement.continuous].includes(this.goal.progressMeasurement);
    },
    canAddToDashboardsIsPublished() {
      return this.goal.publishedAt !== null && this.goal.publishedAt !== undefined;
    },
    canAddToDashboards() {
      return this.canAddToDashboardsValidProgressMeasurement && this.canAddToDashboardsIsPublished;
    },
    canAddToDashboardsTooltip() {
      if (this.canAddToDashboards) {
        return '';
      }

      return this.$t('goalContextMenu.cannotAddToDashboard');
    },
  },
  methods: {
    handleDelete() {
      this.showDeleteGoalModal({ goals: [this.goal] }).then(() => {
        this.onDelete();
      });
    },
    handleDuplicate() {
      this.duplicateSingle(this.goal).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.duplicated') });
        this.$emit('goals-duplicated');
        this.onDuplicate();
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    handleExpandAll() {
      this.$emit('expand-all');
      this.hideMenu();
    },
    handleCollapseAll() {
      this.$emit('collapse-all');
      this.hideMenu();
    },
    hideMenu() {
      if (this.$refs.contextMenu !== null && typeof this.$refs.contextMenu !== 'undefined' && typeof this.$refs.contextMenu.hide === 'function') {
        this.$refs.contextMenu.hide();
        return;
      }

      this.$emit('hide');
    },
    hide() {
      this.showMenu = 'menu';
    },
    show(event) {
      this.$refs.contextMenu.show(event);
    },
    onDelete() {
      this.$emit('goals-deleted');
      this.hideMenu();
    },
    onDuplicate() {
      this.hideMenu();
    },
    editProperty({ prop }) {
      this.selectedGoals = [this.goal];
      this.prop = prop;
      this.ignorePrefilled = false;
      this.showMenu = 'prop-editor';
      this.successMessage = this.$t('success.updated');
    },
    changeAccess() {
      this.selectedGoals = [this.goal];
      this.showMenu = 'access-editor';
      this.successMessage = this.$t('success.updated');
    },
    duplicateToCycle() {
      this.selectedGoals = this.prepareDuplicate([this.goal]);
      this.prop = {
        label: { [this.userLang]: this.$t('goalsFilter.cycle') },
        edgeName: goalConfig.edges.goalCycle,
        type: propertyType.options,
      };
      this.ignorePrefilled = true;
      this.showMenu = 'prop-editor';
      this.successMessage = this.$t('success.duplicated');
    },
    createBeneath(payload) {
      this.$emit('create-beneath', payload);
      this.hideMenu();
    },
    handleAddToDashboard() {
      this.selectedGoals = [this.goal];
      this.showMenu = 'add-to-grid-editor';
      this.successMessage = this.$t('success.updated');
    },
  },
  watch: {
    createLoading(newVal, oldVal) {
      if (!this.showMenu) {
        return;
      }

      if (newVal === false && oldVal === true) {
        this.hideMenu();
      }
    },
    showMenu() {
      this.relocateKey += 1;
    },
    relocateKey(val) {
      this.$emit('relocate', val);
    },
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
</style>
