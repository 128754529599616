import { debounce } from 'lodash-es';
import { onBeforeUnmount, ref } from 'vue';

export default function useDebounce({ onBeforeUnmountCancel = false, onBeforeUnmountFlush = false } = { onBeforeUnmountCancel: false, onBeforeUnmountFlush: false }) {
  const dMap = {};

  const debounceFn = (fn, wait, key = null) => {
    const k = key !== null ? key : fn;
    if (!Object.prototype.hasOwnProperty.call(dMap, k)) {
      dMap[k] = ref(null);
    }

    if (dMap[k].value !== null) {
      dMap[k].value.cancel();
    }

    dMap[k].value = debounce(fn, wait);
    dMap[k].value();
  };

  const cancel = (key) => {
    dynamicInvoke('cancel', key);
  };
  const flush = (key) => {
    dynamicInvoke('flush', key);
  };

  const cancelAll = () => {
    dynamicInvoke('cancel');
  };
  const flushAll = () => {
    dynamicInvoke('flush');
  };

  const dynamicInvoke = (fn, key = null) => {
    let dd = dMap;
    if (key) {
      dd = {};
      if (dMap[key] !== undefined) {
        dd[key] = dMap[key];
      }
    }
    Object.values(dd).forEach((d) => {
      if (d.value !== null) {
        d.value[fn]();
      }
    });
  };

  onBeforeUnmount(() => {
    if (onBeforeUnmountCancel === true) {
      cancelAll();
    }
    if (onBeforeUnmountFlush === true) {
      flushAll();
    }
  });

  return {
    debounce: debounceFn,
    cancel,
    cancelAll,
    flush,
    flushAll,
  };
}
