<template>
  <m-content
    class="access-policy-scope-selector"
    padding
  >
    <div class="_content">
      <scope-filter
        v-model:value="localScope"
        :account="account"
        :props="allowedProps"
        show-static-user-selection
        open-with-static-user-selection
        :max-tag-text-length="40"
        hide-op
        hide-leaf-op
        :add-btn-label="$t('accessPolicyScopeSelector.addMore')"
        class="_filter"
      />
    </div>
    <div class="_actions">
      <access-policy-type-selector
        v-if="!hideTypeSelector"
        v-model:value="localAccessType"
        :access-types="accessTypes"
      />
      <m-btn
        color="primary"
        class="_invite"
        @click="submit"
      >
        <template
          v-if="buttonLabel === ''"
        >
          {{ $t('accessPolicyScopeSelector.buttonLabel') }}
        </template>
        <template v-else>
          {{ buttonLabel }}
        </template>
      </m-btn>
    </div>
  </m-content>
</template>

<script>
import AccessPolicyTypeSelector from '@/components/access-policy/AccessPolicyTypeSelector.vue';
import ScopeFilter from '@/components/filter/ScopeFilter.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import { accessPolicyScope as accessPolicyScopeConfig, user as userConfig } from 'shared/api/query/configs.json';
import { accessPolicyType, propertyType, userScopeOperator } from 'shared/constants.json';
import { createProp } from '@/lib/props';

export default {
  name: 'AccessPolicyScopeSelector',
  props: {
    buttonLabel: {
      type: String,
      default: '',
    },
    hideTypeSelector: {
      type: Boolean,
      default: false,
    },
    accessTypes: {
      type: Array,
      default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
    },
  },
  setup() {
    const { userProperties } = useProperties();
    const { userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { userProperties, account: loggedInUserAccount, userLang };
  },
  emits: ['submit'],
  components: { AccessPolicyTypeSelector, ScopeFilter },
  data() {
    return {
      localAccessType: accessPolicyType.read,
      localScope: {
        account: { uid: this.account.uid },
        op: userScopeOperator.or,
        children: [],
      },
    };
  },
  computed: {
    allowedProps() {
      return this.userProperties.filter((p) => [propertyType.singleSelect, propertyType.options, propertyType.space].includes(p.type))
        .map((p) => createProp(p, false, true, true, this.userLang, userConfig.model));
    },
  },
  methods: {
    submit() {
      this.$emit('submit', {
        [accessPolicyScopeConfig.edges.accessType]: this.localAccessType,
        [accessPolicyScopeConfig.edges.scope]: this.localScope,
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .access-policy-scope-selector {
    ._content {
      margin-top: 2rem;

      ._filter {
        margin-bottom: 2rem;
      }
    }

    ._actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;

      ._invite {
        margin-left: .8rem;
      }
    }
  }

</style>
