<template>
  <div
    class="_items"
  >
    <div
      v-for="option in advancedOptions"
      :key="option.title"
      class="_item"
    >
      <m-tooltip>
        <settings-switch-item
          :title="option.title"
          :value="option.value"
          @click="option.action"
        />
        <template #title>
          {{ option.tooltip }}
        </template>
      </m-tooltip>
    </div>
  </div>
</template>

<script setup>
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import useGoals from '@/composables/goal/goals';
import { aggregationMethod } from 'shared/constants.json';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({ goal: { type: Object, required: true } });

const expanded = ref(false);
watch(expanded, (val) => {
  emit('expand', val);
});

const emit = defineEmits(['expand']);

const goalsSvc = useGoals();

const unboundedProgress = computed(() => {
  if (props.goal.unboundedProgress === undefined) {
    return false;
  }

  return props.goal.unboundedProgress;
});

// We want to inverse this, meaning we display the negation to the user.
// TODO: write a migration for disableStatusAutoUpdate -> enableStatusAutoUpdate
const disableStatusAutoUpdate = computed(() => {
  if (props.goal.disableStatusAutoUpdate === undefined) {
    return false;
  }

  return props.goal.disableStatusAutoUpdate;
});

const updateUnboundedProgress = () => goalsSvc.updateSingle({ uid: props.goal.uid, unboundedProgress: !unboundedProgress.value });
const updateDisableStatusAutoUpdate = () => goalsSvc.updateSingle({ uid: props.goal.uid, disableStatusAutoUpdate: !disableStatusAutoUpdate.value });

const { t } = useI18n();

const advancedOptions = computed(() => {
  const res = [
    {
      title: t('alignedItemAdvancedOptions.settings.unboundedProgress'),
      value: unboundedProgress.value,
      action: updateUnboundedProgress,
      tooltip: t('alignedItemAdvancedOptions.tooltips.unboundedProgress'),
    },
    {
      title: t('alignedItemAdvancedOptions.settings.disableStatusAutoUpdate'),
      value: !disableStatusAutoUpdate.value,
      action: updateDisableStatusAutoUpdate,
      tooltip: t('alignedItemAdvancedOptions.tooltips.disableStatusAutoUpdate'),
    },
  ];

  if (props.goal.aggregationMethod === aggregationMethod.absolute) {
    return [res[1]];
  }
  return res;
});
</script>

<style scoped lang="scss" type="text/scss">
  .trigger {
    display: flex;
    align-items: center;
    width: 100%;
    padding: .4rem 0;
    color: $font-color-secondary;
    cursor: pointer;

    ._left {
      flex: 1 1 auto;
      max-width: calc(100% - 15rem);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ._right {
      display: flex;
      flex: 0 0 13rem;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
    }
  }

  ._items {
    margin-top: .4rem;

    ._item:not(:last-child) {
      margin-bottom: .8rem;
    }
  }

  ._metric-form-range {
    margin: 1.2rem 0 .8rem 0;
  }

</style>
