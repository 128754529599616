import {
  accessPolicy as accessPolicyConfig,
  accessPolicyScope as accessPolicyScopeConfig,
  userScopeTree as userScopeTreeConfig,
} from 'shared/api/query/configs.json';
import { accessPolicyType, userScopeOperator } from 'shared/constants.json';
import { isNullOrUndefined } from 'shared/lib/object/object';

export const getHighestAccessRight = (rights) => {
  const orderedRights = [
    accessPolicyType.full,
    accessPolicyType.write,
    accessPolicyType.comment,
    accessPolicyType.read,
  ];
  for (let i = 0; i < orderedRights.length; i++) {
    if (rights.includes(orderedRights[i])) {
      return orderedRights[i];
    }
  }
  return accessPolicyType.disabled;
};

export const getAccessTypeOfUser = (entity, user) => {
  if (!isNullOrUndefined(entity.creator) && !isNullOrUndefined(user) && entity.creator.uid === user.uid) {
    return accessPolicyType.full;
  }

  const { accessPolicy } = entity;
  if (isNullOrUndefined(accessPolicy)) {
    return accessPolicyType.disabled;
  }

  if (accessPolicy[accessPolicyConfig.edges.accountAccess] === accessPolicyType.full) {
    return accessPolicyType.full;
  }

  if (accessPolicy[accessPolicyConfig.edges.scopes].length === 0) {
    return accessPolicy[accessPolicyConfig.edges.accountAccess];
  }

  const rights = accessPolicy[accessPolicyConfig.edges.scopes].reduce((res, next) => {
    if (next[accessPolicyScopeConfig.edges.userIsInScope]) {
      return [...res, next[accessPolicyScopeConfig.edges.accessType]];
    }
    return res;
  }, [accessPolicy[accessPolicyConfig.edges.accountAccess]]);

  return getHighestAccessRight(rights);
};

export const copyUserScope = (scope) => {
  if (isNullOrUndefined(scope)) {
    return scope;
  }
  const res = { ...scope };
  delete res.uid;

  if (!isNullOrUndefined(res.timeRange)) {
    delete res.timeRange.uid;
  }
  if (!isNullOrUndefined(res.numberRange)) {
    delete res.numberRange.uid;
  }
  return res;
};

export const copyUserScopeTree = (scopeTree) => {
  if (isNullOrUndefined(scopeTree)) {
    return scopeTree;
  }
  const res = { ...scopeTree };
  delete res.uid;
  if (!isNullOrUndefined(res.scope)) {
    res.scope = copyUserScope(res.scope);
  }
  if (Array.isArray(res.children)) {
    res.children = res.children.map((c) => copyUserScopeTree(c));
  }
  return res;
};

export const copyAccessPolicy = (policy) => {
  if (isNullOrUndefined(policy)) {
    return null;
  }
  const res = { ...policy };
  delete res.uid;
  if (!Array.isArray(res.scopes)) {
    return res;
  }

  res.scopes = res.scopes.map((s) => {
    const cp = { ...s };
    delete cp.uid;
    cp.scope = copyUserScopeTree(cp.scope);
    return cp;
  });
  return res;
};

/* returns scopes, that are common in a list of access policy scopes (array of array of scopes) */
export const accessPolicyCommonScopes = (accessPolicyScopes) => {
  if (accessPolicyScopes.length === 0) {
    return [];
  }

  return accessPolicyScopes.reduce(
    (res, next) => res.filter((r) => next.find((s) => {
      if (r.accessType !== s.accessType) {
        return false;
      }

      if (s.scope !== null && r.scope !== null) {
        return s.scope.treeHash === r.scope.treeHash;
      }

      return false;
    })),
    accessPolicyScopes[0],
  );
};

export const spreadAccessPolicyScopes = (accessPolicyScope) => accessPolicyScope.scope.children.map((c) => c.children[0].scope)
  .reduce((acc, us) => {
    const splitFlatObject = (obj) => {
      const arrayField = Object.keys(obj).find((k) => Array.isArray(obj[k]) && obj[k].length > 0);
      if (arrayField === undefined) {
        return obj;
      }

      return obj[arrayField].map((el) => ({
        ...obj,
        [arrayField]: [el],
      }));
    };
    splitFlatObject(us).forEach((us) => {
      acc.push({
        [userScopeTreeConfig.edges.account]: accessPolicyScope.scope.account,
        [userScopeTreeConfig.edges.op]: userScopeOperator.or,
        [userScopeTreeConfig.edges.children]: [{
          [userScopeTreeConfig.edges.op]: userScopeOperator.and,
          [userScopeTreeConfig.edges.children]: [{
            [userScopeTreeConfig.edges.op]: userScopeOperator.and,
            [userScopeTreeConfig.edges.scope]: us,
          }],
        }],
      });
    });
    return acc;
  }, [])
  .map((ust) => ({
    [accessPolicyScopeConfig.edges.accessType]: accessPolicyScope[accessPolicyScopeConfig.edges.accessType],
    [accessPolicyScopeConfig.edges.scope]: ust,
  }));
