<template>
  <m-tooltip
    placement="left"
    :disabled="scopeCount === 0"
  >
    <access-policy-scope-row
      :icon="scopeIcon"
      :access-type="accessType"
      :access-types="accessTypes"
      :disabled="disabled"
      @change="emit('change', $event)"
    >
      <template #name>
        {{ scopeName }}
      </template>
      <template #description>
        {{ scopeDescription }}
        <span v-if="scopeCount !== undefined">・{{ $t('accessPolicyScopeRow.people', {count: scopeCount}) }}</span>
      </template>
      <template #access-policy-type-selector>
        <slot name="access-policy-type-selector" />
      </template>
    </access-policy-scope-row>
    <template #title>
      <div class="_overlay">
        <div
          v-for="user in scope.scopeUsers"
          :key="user.uid"
          class="_item"
        >
          <user-display
            :style="{ width: '100%' }"
            :user="user"
            xs
          />
        </div>
      </div>
    </template>
  </m-tooltip>
</template>
<script setup>

import AccessPolicyScopeRow from '@/components/access-policy/AccessPolicyScopeRow.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import usePropertyOptions from '@/composables/property-option/property-option';
import useSpaces from '@/composables/space/spaces';
import { accessPolicyType, propertyType } from 'shared/constants.json';
import { computed } from 'vue';
import { iconByType } from '@/lib/property';
import { textByLang } from 'shared/lib/language';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  scope: {
    type: Object,
    required: true,
  },
  accessType: {
    type: String,
    required: true,
  },
  accessTypes: {
    type: Array,
    default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const scopeCount = computed(() => props.scope.scopeUsers?.length);
const userScope = computed(() => props.scope.scope.children[0].children[0].scope);

const propertySvc = useProperties();
const { userLang } = useLoggedInUser();

const property = computed(() => propertySvc.selectSingle(userScope.value.property.uid));
const scopeIcon = computed(() => iconByType(property.value));
const scopeDescription = computed(() => textByLang(property.value.label, userLang));

const propertyOptionSvc = usePropertyOptions();
const spaceSvc = useSpaces();

const scopeName = computed(() => {
  switch (property.value.type) {
    case propertyType.options:
    case propertyType.singleSelect:
    {
      const propertyOption = propertyOptionSvc.selectSingle(userScope.value.selectedOptions[0].uid);
      return textByLang(propertyOption.label, userLang);
    }
    case propertyType.space:
    {
      const space = spaceSvc.selectSingle(userScope.value.spaces[0].uid);
      return space.title;
    }
    default:
      return t('general.untitled');
  }
});

const emit = defineEmits(['change']);

</script>
<style scoped>
._overlay{
  max-width: 240px;

  ._item{
    min-width: 140px;
    padding: .2rem 0;
  }
}
</style>
