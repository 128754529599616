<template>
  <div class="access-policy-for-access-groups">
    <div
      v-for="scope in sortedScopes"
      :key="scope.uid"
    >
      <template v-if="canRenderAccessPolicyScope(scope)">
        <access-policy-scope-user-row
          v-if="isStaticUserScope(scope)"
          :user="userFromStaticUserScope(scope)"
          :access-type="scope.accessType"
        >
          <template #access-policy-type-selector>
            <m-btn
              hide-border
              class="_action"
              :disabled="disabled"
              icon="delete"
              light
              fab
              @click.stop="removeScope(scope)"
            />
          </template>
        </access-policy-scope-user-row>
        <access-policy-scope-group-row
          v-else
          :scope="scope"
          :access-type="scope.accessType"
        >
          <template #access-policy-type-selector>
            <m-btn
              hide-border
              class="_action"
              :disabled="disabled"
              icon="delete"
              light
              fab
              @click.stop="removeScope(scope)"
            />
          </template>
        </access-policy-scope-group-row>
        <m-divider none />
      </template>
    </div>
    <m-content
      :padding-y="6"
      :padding-x="4"
      class="_actions"
    >
      <div class="_btn _invite">
        <m-btn
          :disabled="disabled"
          block
          @click.stop="showScopeSelector"
        >
          {{ $t('accessPolicyForAccessGroups.inviteButton') }}
        </m-btn>
      </div>
    </m-content>
    <m-dialog
      v-model:value="showModal"
      hide-footer
      no-padding
    >
      <access-policy-scope-selector
        :button-label="$t('general.add')"
        hide-type-selector
        @submit="scopeSubmitted"
      />
    </m-dialog>
  </div>
</template>

<script>
import AccessPolicyScopeGroupRow from '@/components/access-policy/AccessPolicyScopeGroupRow.vue';
import AccessPolicyScopeSelector from '@/components/access-policy/AccessPolicyScopeSelector.vue';
import AccessPolicyScopeUserRow from '@/components/access-policy/AccessPolicyScopeUserRow.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import { DateTime } from 'luxon';
import { UserScopeTreeHasher } from '@/lib/user-scope-tree_hasher';
import { accessPolicyType, userScopeType } from 'shared/constants.json';
import { canRenderAccessPolicyScope } from '@/lib/access-policy-scope';
import { getAccessTypeOfUser, spreadAccessPolicyScopes } from '@/lib/access-policy';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { shallowCopy } from 'shared/lib/copy';
import { uniqBy } from 'lodash-es';

export default {
  name: 'AccessPolicyForAccessGroups',
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { userProperties } = useProperties();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { userProperties, loggedInUser, userLang, account: loggedInUserAccount };
  },
  emits: ['input', 'update:value'],
  components: {
    AccessPolicyScopeGroupRow,
    AccessPolicyScopeSelector,
    AccessPolicyScopeUserRow,
  },
  data() {
    return { showModal: false };
  },
  computed: {
    sortedScopes() {
      return shallowCopy(this.value.scopes).filter((s) => s?.deletedAt === undefined).sort((a, b) => {
        const aStatic = this.isStaticUserScope(a);
        const bStatic = this.isStaticUserScope(b);
        if (aStatic === bStatic) return 0;
        if (aStatic === true) return 1;
        return -1;
      });
    },
  },
  methods: {
    canRenderAccessPolicyScope,
    isStaticUserScope(accessPolicyScope) {
      return accessPolicyScope.scope?.children[0]?.children[0]?.scope.type === userScopeType.staticUsers || false;
    },
    userFromStaticUserScope(accessPolicyScope) {
      return accessPolicyScope.scope?.children[0]?.children[0]?.scope.staticUsers[0];
    },
    showScopeSelector() {
      if (this.disabled) {
        return;
      }
      this.showModal = true;
    },
    scopeSubmitted(scope) {
      this.showModal = false;
      this.updateScopes(spreadAccessPolicyScopes(scope));
    },
    accessPolicyContainsUser(accessPolicy, user) {
      return getAccessTypeOfUser({ accessPolicy }, user) !== accessPolicyType.disabled;
    },
    confirmRemoveSelf(newScope) {
      const update = this.updateScopes;
      this.$confirm({
        title: this.$t('accessPolicyForAccessGroups.confirmRemoveSelfText'),
        okText: this.$t('general.remove'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          update(newScope);
        },
      });
    },
    removeScope(scope) {
      const deletedAt = DateTime.local().toISO();
      const scopesToDelete = [{ ...scope, deletedAt }];

      const resultingAccessPolicy = { ...this.value, scopes: this.value.scopes.filter((s) => s.uid !== scope.uid) };

      if (this.accessPolicyContainsUser(resultingAccessPolicy, this.loggedInUser)) {
        this.updateScopes(scopesToDelete);
        return;
      }
      this.confirmRemoveSelf(scopesToDelete);
    },
    updateScopes(newScopes) {
      const hasher = new UserScopeTreeHasher();
      const fillHashesToAccessPolicyScope = (aps) => {
        if (!isNullOrUndefined(aps.scope)) {
          const hash = hasher.treeHash(aps.scope);
          return { ...aps, scopeHash: hash, scope: { ...aps.scope, treeHash: hash } };
        }
        return aps;
      };
      let scopes = this.value.scopes.map(fillHashesToAccessPolicyScope);
      const newSc = newScopes.map(fillHashesToAccessPolicyScope);
      scopes = uniqBy([...newSc, ...scopes], 'scopeHash');
      const updated = {
        ...this.value,
        scopes,
      };
      this.$emit('input', updated);
      this.$emit('update:value', updated);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
