<template>
  <m-card
    :level="level"
    :class="['update-feed-item', showMenu ? '-active' : '']"
    :no-padding="!padding"
    :bordered="bordered"
  >
    <div
      v-if="!hideTop"
      class="_top"
    >
      <user-display
        :user="creator"
        xl
      >
        <template #subName>
          <div class="_date">
            {{ formatDate(update.createdAt) }}
            <template v-if="update.createdAt !== update.modifiedAt">
              ({{ $t('updateFeedItem.edited') }})
            </template>
          </div>
        </template>
      </user-display>
      <div class="_right">
        <div
          v-if="hideCheckinDetails"
          class="_tag"
        >
          <m-tag
            xxs
            color="light"
            :title="$t('updateFeedItem.viaCheckin')"
            :style="{ display: 'inline-flex', textTransform: 'uppercase', fontWeight: '500' }"
            clickable
            @click="handleTagClick"
          />
        </div>
      </div>
      <div
        v-if="update.creator !== null && update.creator.uid === loggedInUser.uid"
        class="_actions"
      >
        <m-dropdown
          v-model:value="showMenu"
          close-on-click
          :title="$t('general.actions')"
        >
          <m-btn
            icon="ellipsis"
            fab
            small
            hide-border
            @click="showMenu = true"
          />
          <template #overlay>
            <m-card
              no-padding
              list
            >
              <m-card-item
                v-if="update.goal === null"
                icon="edit"
                @click.stop="show"
              >
                {{ $t('general.edit') }}
              </m-card-item>
              <m-card-item
                icon="delete"
                @click="onDeleteCheckIn"
              >
                {{ $t('general.delete') }}
              </m-card-item>
            </m-card>
          </template>
        </m-dropdown>
      </div>
    </div>
    <div
      v-if="!hideProps && !hideCheckinDetails"
      class="_props"
    >
      <div
        v-for="prop in visibleProps"
        :key="prop.uid"
        class="_item"
      >
        <item-wrapper
          v-if="prop.isTitle"
          class="_item"
          :tooltip="prop.label"
          show-tooltip
        >
          <m-tag
            :icon="buildIconFromEntity(update)"
            :title="update.title"
            small
            :style="{ marginRight: '.4rem' }"
          />
        </item-wrapper>
        <user-prop
          v-if="prop.property.type === propertyType.user"
          :users="getUsers(prop)"
          :label="prop.label"
          show-tooltip
        />
        <space-prop
          v-if="prop.property.type === propertyType.space"
          :spaces="getSpaces(prop)"
          :label="prop.label"
          :class="['_option-prop', clickable(prop.property) ? '-clickable' : '']"
          wrap
          @space-clicked="goToSpace($event, prop.property)"
        />
        <option-prop
          v-if="[propertyType.options, propertyType.singleSelect].includes(prop.property.type)"
          :options="getOptions(prop)"
          :label="prop.label"
          :class="['_option-prop', clickable(prop.property) ? '-clickable' : '']"
          wrap
        />
        <date-prop
          v-if="prop.property.type === propertyType.date"
          :entity="update"
          :prop="prop"
          :property-values-key="propertyValuesKey"
        />
        <text-prop
          v-if="prop.property.type === propertyType.text && !prop.isTitle"
          :entity="update"
          :prop="prop"
          :property-values-key="propertyValuesKey"
        />
        <url-prop
          v-if="prop.property.type === propertyType.url"
          :entity="update"
          :prop="prop"
          :property-values-key="propertyValuesKey"
        />
        <number-prop
          v-if="prop.property.type === propertyType.number"
          :entity="update"
          :prop="prop"
          :property-values-key="propertyValuesKey"
        />
      </div>
    </div>
    <div
      v-if="!hideCheckinDetails"
      class="_body"
    >
      <m-editor
        v-show="message !== null"
        ref="editor"
        disabled
        :initial-value="message"
        :default-font-size="editorFontSize"
        :allowed-content="allowedContent"
      />
    </div>
    <div class="_goal-updates">
      <h6
        v-if="update.goalActivities.length > 0 && message !== null"
        class="_updates-header"
      >
        {{ $t('goalUpdateEditor.goalUpdates') }}
      </h6>
      <goal-activity-form
        v-for="(activity, index) in goalActivities"
        :key="`${guid()}_${index}`"
        :activity="activity"
        :wrap-titles="wrapTitles"
        disabled
        searchable
        class="_activity"
      />
    </div>
    <reaction-list
      v-if="reactions.length > 0"
      :update="update"
      class="_reactions"
    />
    <div
      v-if="reactions.length === 0 || comments.length === 0"
      class="_bottom-actions"
    >
      <div
        v-if="reactions.length === 0"
        class="_add-reaction"
      >
        <add-reaction-btn
          :update="update"
          show-text
          hide-border
          light
          small
        />
      </div>
      <div
        v-if="comments.length === 0"
        class="_add-comment"
      >
        <m-btn
          v-if="showAddComment"
          icon="message"
          hide-border
          small
          light
          @click="addComment"
        >
          {{ $t('updateFeedItem.addComment') }}
        </m-btn>
      </div>
    </div>
    <div
      v-if="showComments"
      class="_comments"
    >
      <comment-list
        ref="commentsRef"
        :comments="comments"
        :update="update"
        collapse
        class="_comments"
        @write-comment="writeComment = true"
      />
    </div>
    <m-dialog
      :value="showModal"
      :max-width="$modalSizes.xl"
      hide-footer
      no-padding
      keep-height
      hide-header
      top="7rem"
      @close="handleClose"
    >
      <update-editor
        :update="update"
        :disabled="viewOnly"
        :modal="true"
        @saved="handleSaved"
        @cancel="hideModal"
        @is-dirty="updateDirty = true"
      />
    </m-dialog>
  </m-card>
</template>

<script>
import AddReactionBtn from '@/components/reaction/AddReactionBtn.vue';
import CommentList from '@/components/comment/CommentList.vue';
import DateProp from '@/components/list/DateProp.vue';
import GoalActivityForm from '@/components/goal/GoalActivityForm.vue';
import ItemWrapper from '@/components/list/ItemWrapper.vue';
import MEditor from '@/components/editor/MEditor.vue';
import NumberProp from '@/components/list/NumberProp.vue';
import OptionProp from '@/components/list/OptionProp.vue';
import ReactionList from '@/components/reaction/ReactionList.vue';
import SpaceProp from '@/components/list/SpaceProp.vue';
import TextProp from '@/components/list/TextProp.vue';
import UpdateEditor from '@/components/updates/UpdateEditor.vue';
import UrlProp from '@/components/list/UrlProp.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import UserProp from '@/components/list/UserProp.vue';
import useAccess from '@/composables/access/access';
import useComments from '@/composables/comments/comments';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import useReactions from '@/composables/reactions/reactions';
import useResourceSettings from '@/composables/logged-in-user-account/resource-settings';
import useUpdates from '@/composables/updates/updates';
import useUsers from '@/composables/user/users';
import { DateTime } from 'luxon';
import { SPACE_DETAILS } from '@/route-names';
import { UPDATE_VIEW, VIEW } from '@/route-params';
import { buildIconFromEntity } from 'shared/lib/icon';
import { createPropsList, getValueFromEntity, sortedPropertyElements } from '@/lib/props';
import { editorNodeType, featureFlag, propertyType } from 'shared/constants.json';
import { findInArray } from 'shared/lib/array/array';
import { fontSizes } from 'shared/font-sizes';
import { guid } from 'shared/lib/uuid';
import { logCatch } from '@/lib/logger/logger';
import { ref, toRef } from 'vue';
import { shallowCopy } from 'shared/lib/copy';
import { sortByArray } from 'shared/lib/sort';
import { update as updateConfig } from 'shared/api/query/configs.json';

export default {
  name: 'UpdateFeedItem',
  props: {
    update: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      default: 1,
    },
    hideTop: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Array,
      default: () => [],
    },
    hideProps: {
      type: Boolean,
      default: false,
    },
    propertyValuesKey: {
      type: String,
      default: updateConfig.edges.properties,
    },
    routeAfterDelete: {
      type: Object,
      default: null,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    editorFontSize: {
      type: String,
      default: fontSizes[4],
    },
    loadUpdate: {
      type: Boolean,
      default: false,
    },
    hideCheckinDetails: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    wrapTitles: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SpaceProp,
    ItemWrapper,
    UrlProp,
    AddReactionBtn,
    CommentList,
    MEditor,
    UserDisplay,
    GoalActivityForm,
    UpdateEditor,
    OptionProp,
    NumberProp,
    DateProp,
    UserProp,
    TextProp,
    ReactionList,
  },
  setup(props) {
    const commentsRef = ref(null);
    const updateSvc = useUpdates();

    const { reactions } = useReactions(toRef(props, 'update'));

    const { comments } = useComments();
    const { mooncampBotUser } = useUsers();
    const { updateProperties } = useProperties();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { resourceSettings } = useResourceSettings();

    const allowedContent = [
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
      editorNodeType.mention,
      editorNodeType.goalMention,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    return {
      userLang,
      commentsRef,
      mooncampBotUser,
      deleteUpdate: updateSvc.deleteUpdate,
      comments: comments({ updateId: props.update.uid }),
      updateProperties,
      loggedInUser,
      reactions,
      resourceSettings,
      allowedContent,
    };
  },
  data() {
    return {
      propertyType,
      writeComment: false,
      guid,
      showAddComment: true,
      updateDirty: false,
      showMenu: false,
      showModal: false,
      viewOnly: false,
    };
  },
  computed: {
    goalActivities() {
      return shallowCopy(this.update.goalActivities).sort(sortByArray(this.update.goalActivitiesOrder));
    },
    creator() {
      if (this.update.generated) {
        return this.mooncampBotUser;
      }

      return this.update.creator;
    },
    showComments() {
      return !this.showAddComment || this.comments.length > 0;
    },
    message() {
      if (this.update.message === null || this.update.message === undefined) {
        return null;
      }
      return this.update.message;
    },
    visibleProps() {
      return this.props.length === 0 ? this.defaultVisibleProps : this.props.filter((p) => !p.hideInProps && p.show);
    },
    defaultVisibleProps() {
      return createPropsList({
        properties: this.updateProperties,
        directProperties: [],
        userLang: this.userLang,
        model: updateConfig.model,
      });
    },
  },
  methods: {
    buildIconFromEntity,
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
    },
    isDirectEdgeValue(value) {
      if (!Array.isArray(value) && typeof value !== 'object') {
        return true;
      }

      if (value.length === 0) {
        return typeof value.uid === 'undefined';
      }

      return typeof value[0].uid === 'undefined';
    },
    getOptions(prop) {
      const val = getValueFromEntity(prop, this.update, this.propertyValuesKey);
      if (val === null) {
        return [];
      }
      if (prop.isDirect && !this.isDirectEdgeValue(val)) {
        return prop.property.options.filter((o) => val.map((v) => v.uid).includes(o.value));
      }
      if (prop.isDirect) {
        return prop.property.options.filter((o) => o.value === val);
      }
      const sortedVal = sortedPropertyElements(val, prop.property.propertyOptionOrder);
      if (prop.property.type === propertyType.singleSelect && sortedVal.length > 1) {
        return [sortedVal[0]];
      }
      return sortedVal;
    },
    getSpaces(prop) {
      const spaces = getValueFromEntity(prop, this.update, this.propertyValuesKey);
      if (spaces === null) {
        return [];
      }
      if (!Array.isArray(spaces)) {
        return [spaces];
      }
      return sortedPropertyElements(spaces, this.resourceSettings.spaceOrder.map(({ uid }) => uid));
    },
    getUsers(prop) {
      const users = getValueFromEntity(prop, this.update, this.propertyValuesKey);
      if (users === null) {
        return [];
      }

      if (!Array.isArray(users)) {
        return [users];
      }
      return users;
    },
    handleClose() {
      const close = () => {
        this.showModal = false;
        this.viewOnly = false;
        this.updateDirty = false;
      };

      if (this.updateDirty) {
        this.$confirm({
          title: this.$t('general.discardEditPrompt'),
          okText: this.$t('general.discardChanges'),
          cancelText: this.$t('general.close'),
          okType: 'warning',
          maskClosable: false,
          onOk() {
            close();
          },
        });
        return;
      }

      close();
    },
    handleTagClick() {
      this.showModal = true;
      this.viewOnly = true;
    },
    clickable(property) {
      const p = findInArray({ haystack: this.updateProperties, needle: property.uid });
      return p !== null && p.type === propertyType.space;
    },
    addComment() {
      this.showAddComment = false;
      this.$nextTick(() => {
        this.commentsRef.add();
      });
    },
    goToSpace(space, property) {
      if (!this.clickable(property)) {
        return;
      }
      this.$router.push({ name: SPACE_DETAILS, params: { optionId: space.uid }, query: { [VIEW]: UPDATE_VIEW } });
    },
    hideModal() {
      this.showModal = false;
    },
    handleSaved() {
      this.updateDirty = false;
      this.hideModal();
    },
    show() {
      this.showMenu = false;
      this.showModal = true;
    },
    deleteUpdateEntity() {
      this.deleteUpdate(this.update).then(() => {
        if (this.routeAfterDelete === null) {
          return;
        }
        this.$router.push(this.routeAfterDelete);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    onDeleteCheckIn() {
      const deleteMethod = this.deleteUpdateEntity;

      this.$confirm({
        title: this.$t('updateFeedItem.deletePrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .update-feed-item {
    display: flex;
    width: 100%;
    z-index: 0;

    ._top {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 2rem;

      ._date {
        font-size: $font-size-4;
        color: $font-color-secondary;
      }

      ._right {
        margin-left: auto;
        margin-right: 3.6rem;

        ._tag {
          margin-top: -.3rem;
        }
      }

      ._actions {
        display: none;
        position: absolute;
        top: 2.4rem;
        right: 2.4rem;
        background-color: white;
        border-radius: $btn-border-radius;
      }
    }

    ._props {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 1.5rem;

      ._item {
        ._prop {
          margin-right: .8rem;
        }

        ._option-prop {
          flex-wrap: wrap;

          &.-clickable {
            cursor: pointer;
          }
        }
      }
    }

    ._body {
      margin-bottom: 2rem;
    }

    ._goal-updates {
      max-width: 60rem;

      ._updates-header {
        font-size: $font-size-2;
        font-weight: $font-weight-semibold;
        color: $font-color-secondary;
        text-transform: uppercase;
        letter-spacing: .05em;
      }

      ._activity {
        margin-bottom: 1rem;
      }
    }

    &:hover,
    &.-active {
      ._actions {
        display: block;
      }
    }

    ._reactions {
      margin-top: 1.4rem;
    }

    ._comments {
      margin-top: 1rem;
    }

    ._bottom-actions {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1.2rem;

      ._add-reaction {
        margin-right: .8rem;
      }
    }
  }
</style>
