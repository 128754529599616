<template>
  <m-card
    class="space-membership-selector"
    padding
  >
    <div class="_header">
      <span class="_label">{{ $t('spaceMembershipSelector.addMembersTo') }}</span>
      <item-title
        class="_title"
        :title="space.title"
        :icons="[{ value: icon }]"
      />
    </div>
    <div class="_content">
      <scope-filter
        v-model:value="userScopeTree"
        :account="account"
        :props="allowedProps"
        show-static-user-selection
        open-with-static-user-selection
        :max-tag-text-length="40"
        hide-op
        hide-leaf-op
        :add-btn-label="$t('spaceMembershipSelector.addMore')"
        class="_filter"
      />
      <user-scope-tree-user-list
        :user-scope-tree="userScopeTree"
      />
    </div>
    <div class="_actions">
      <m-btn
        color="primary"
        class="_invite"
        @click="submit"
      >
        {{ $t('general.invite') }}
      </m-btn>
    </div>
  </m-card>
</template>

<script>
import ItemTitle from '@/components/ItemTitle.vue';
import ScopeFilter from '@/components/filter/ScopeFilter.vue';
import UserScopeTreeUserList from '@/components/UserScopeTreeUserList.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import useSpace from '@/composables/space/space';
import useUsers from '@/composables/user/users';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed, toRef } from 'vue';
import { createProp } from '@/lib/props';
import { isInFilter } from '@/lib/filter/base-frontend-filter/handler';
import { propertyType, userScopeOperator } from 'shared/constants.json';
import { user as userConfig } from 'shared/api/query/configs.json';

export default {
  name: 'SpaceMembershipSelector',
  props: {
    space: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { userProperties } = useProperties();
    const { userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { users } = useUsers();
    const spaceSvc = useSpace(toRef(props, 'space'));
    const excludedUsers = computed(() => spaceSvc.members.value.map(({ user }) => user));
    return {
      userProperties,
      account: loggedInUserAccount,
      userLang,
      users,
      excludedUsers,
    };
  },
  emits: ['select'],
  components: { ItemTitle, ScopeFilter, UserScopeTreeUserList },
  data() {
    return {
      userScopeTree: {
        account: { uid: this.account.uid },
        op: userScopeOperator.or,
        children: [],
      },
    };
  },
  computed: {
    icon() {
      return buildIconFromEntity(this.space);
    },
    allowedProps() {
      return this.userProperties.filter((p) => [propertyType.singleSelect, propertyType.options, propertyType.space].includes(p.type))
        .map((p) => createProp(p, false, true, true, this.userLang, userConfig.model));
    },
  },
  methods: {
    submit() {
      const members = this.users.filter((user) => !this.excludedUsers.map(({ uid }) => uid).includes(user.uid))
        .filter((entity) => isInFilter({ isFilterMode: false })({ entity, scopeTree: this.userScopeTree, propertyValues: entity.values }))
        .map((user) => ({ user, role: null }));
      this.$emit('select', members);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .space-membership-selector {
    width: $modal-size-base;

    ._header {
      display: flex;
      align-items: center;

      ._label {
        flex: 0 0 auto;
      }

      ._title {
        flex: 0 1 auto;
        padding: 0 1rem;
        font-weight: $font-weight-semibold;
      }
    }

    ._content {
      margin-top: 2rem;

      ._filter {
        margin-bottom: 2rem;
      }
    }

    ._actions {
      display: flex;
      justify-content: flex-end;

      ._invite {
        margin-left: .8rem;
      }
    }
  }

</style>
