<template>
  <m-dropdown
    :value="showEditPropertyMenu"
    placement="topRight"
    :title="$t('general.actions')"
    :trigger="trigger"
    block
  >
    <component
      :is="component"
      :disabled="disabled"
      icon="unordered-list"
      :tooltip="tooltip"
      :style="{ width: '100%' }"
      @click="showEditPropertyMenu = true"
    >
      {{ $t('contextMenu.editProperty') }}
      <template
        v-if="showIcon"
        #right
      >
        <m-icon
          type="chevron-right"
          :color="$colors.grey.lighten1"
        />
      </template>
    </component>
    <template #overlay>
      <m-card
        list
        no-padding
      >
        <m-card-item
          v-for="prop in properties"
          :key="prop.edgeName"
          :style="{ width: '100%' }"
          @click="$emit('edit-property', { prop })"
        >
          {{ textByLang(prop.label, userLang) }}
        </m-card-item>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { propertyType } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'EditGoalPropertyItem',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    component: {
      type: String,
      default: 'm-card-item',
    },
    trigger: {
      type: String,
      default: 'click',
    },
  },
  emits: ['edit-property'],
  setup() {
    const { goalCycles } = useGoalCycle();
    const { properties: goalProperties } = useGoalProperty();
    const { goalSettings } = useGoalSettings();
    const { userLang } = useLoggedInUser();

    return { goalCycles, goalProperties, goalSettings, userLang };
  },
  data() {
    return {
      showEditPropertyMenu: false,
      textByLang,
      goalConfig,
      selectedCycles: [],
      parent: null,
      showForm: false,
    };
  },
  computed: {
    properties() {
      return [
        {
          label: { [this.userLang]: this.$t('goalsFilter.cycle') },
          edgeName: goalConfig.edges.goalCycle,
          type: propertyType.options,
        },
        {
          label: { [this.userLang]: this.$t('goal.goalEditor.parent', { title: this.goalSettings.featureNameSingular }) },
          edgeName: goalConfig.edges.parents,
          type: propertyType.options,
        },
        ...this.goalProperties.filter((p) => p.type !== propertyType.lookup && p.type !== propertyType.status),
      ];
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._input-overlay {
    min-width: 30rem;
  }
</style>
