<template>
  <div class="goal-settings">
    <page-header
      :title="$t('goalSettings.title')"
      boxed-xs
      heading="h3"
      underlined
      class="_header"
    />
    <m-content
      boxed-xs
      padding
      class="_content"
    >
      <m-section
        heading-size="h4"
        :title="$t('goalSettings.advanced.title')"
        class="_section"
      >
        <template
          v-for="(flag, index) in flags"
          :key="index"
        >
          <settings-switch-item
            :title="$t(`goalSettings.advanced.${camelCase(flag)}.title`)"
            :sub-title="$t(`goalSettings.advanced.${camelCase(flag)}.subTitle`)"
            :value="getValue(flag)"
            :disabled="!canToggle(flag)"
            @toggle-clicked="toggle(flag)"
          >
            <template
              v-if="featureFlags.includes(flag)"
              #after-title
            >
              <plan-tag
                :style="{ display: 'inline-flex', marginLeft: '.6rem' }"
                :feature-flag="flag"
              />
            </template>
          </settings-switch-item>
          <m-divider small />
        </template>
      </m-section>
      <m-section
        heading-size="h4"
        :title="$t('goalSettings.featureName.title')"
        :sub-title="$t('goalSettings.featureName.subTitle')"
        class="_section"
      >
        <div
          class="_feature-name"
        >
          <div class="_label">
            {{ $t('goalSettings.featureName.labelSingular') }}
          </div>
          <m-text-field v-model:value="featureNameSingular" />
          <div class="_label">
            {{ $t('goalSettings.featureName.labelPlural') }}
          </div>
          <m-text-field v-model:value="featureNamePlural" />
        </div>
        <m-btn
          class="_button"
          color="primary"
          @click="saveNames"
        >
          {{ $t('general.save') }}
        </m-btn>
      </m-section>
      <m-section
        heading-size="h4"
        :title="$t('goalSettings.accessRight.title')"
        :sub-title="$t('goalSettings.accessRight.description')"
        class="_section"
      >
        <div
          class="_access-policy"
        >
          <div class="_wrapper">
            <access-policy
              :value="accessPolicy"
              :creator="creator"
              :add-user-text="$t('goalSettings.accessRight.addUserText')"
              :add-group-button-text="$t('general.add')"
              :access-types="[accessPolicyType.read, accessPolicyType.comment, accessPolicyType.write, accessPolicyType.full]"
              :loading="accessPolicyLoading"
              button-color=""
              suppress-confirm
              @input="updateAccessPolicy"
            />
          </div>
        </div>
        <m-btn
          class="_button"
          color="primary"
          @click="saveAccessPolicy"
        >
          {{ $t('general.save') }}
        </m-btn>
      </m-section>
      <m-section
        heading-size="h4"
        :title="$t('goalSettings.insightRanges.title')"
        :sub-title="$t('goalSettings.insightRanges.description')"
        class="_section"
      >
        <m-content
          class="_dashboard-settings"
        >
          <goal-dashboard-settings
            v-model:value="thresholds"
          />
        </m-content>
        <m-btn
          class="_button"
          color="primary"
          @click="saveThresholds"
        >
          {{ $t('general.save') }}
        </m-btn>
      </m-section>
    </m-content>
  </div>
</template>

<script>
import AccessPolicy from '@/components/access-policy/AccessPolicy.vue';
import GoalDashboardSettings from '@/components/settings/GoalDashboardSettings.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import PlanTag from '@/components/plans/PlanTag.vue';
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import { accessPolicyType, featureFlag } from 'shared/constants.json';
import { accountSettings as accountSettingsConfig } from 'shared/api/query/configs.json';
import { camelCase } from 'lodash-es';

export default {
  name: 'GoalSettings',
  components: { PlanTag, SettingsSwitchItem, GoalDashboardSettings, AccessPolicy, PageHeader },
  data() {
    return {
      camelCase,
      accessPolicy: null,
      accessPolicyLoading: false,
      creator: {
        firstName: 'Example',
        lastName: 'User',
      },
      featureNameSingular: '',
      featureNamePlural: '',
      thresholds: {
        redThreshold: 0,
        yellowThreshold: 0,
      },
      accessPolicyType,
      featureFlag,
    };
  },
  setup() {
    const {
      goalSettings,
      updateGoalSettings,
    } = useGoalSettings();
    const {
      accountSettings,
      accountSettingsEdgeByFlag,
      canToggleFlag,
      toggleFlag,
      updateAccountSettings,
    } = useAccountSettings();
    return {
      goalSettings,
      updateGoalSettings,
      accountSettings,
      accountSettingsEdgeByFlag,
      canToggleFlag,
      toggleFlag,
      updateAccountSettings,
    };
  },
  computed: {
    featureFlags() {
      return [
        featureFlag.multiGoalAlignment,
        featureFlag.publishedViews,
        featureFlag.weightedGoals,
      ];
    },
    flags() {
      return [
        ...this.featureFlags,
        accountSettingsConfig.edges.canGroupByUser,
      ];
    },
  },
  methods: {
    getValue(flag) {
      if (this.featureFlags.includes(flag)) {
        return this.accountSettings[this.accountSettingsEdgeByFlag(flag)];
      }
      return this.accountSettings[flag];
    },
    canToggle(flag) {
      if (this.featureFlags.includes(flag)) {
        return this.canToggleFlag(flag);
      }
      return true;
    },
    toggle(flag) {
      if (this.featureFlags.includes(flag)) {
        return this.toggleFlag(flag).catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
      }

      return this.updateAccountSettings({
        ...this.accountSettings,
        [flag]: !this.accountSettings[flag],
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    updateAccessPolicy(val) {
      this.accessPolicy = val;
    },
    saveAccessPolicy() {
      this.accessPolicyLoading = true;
      this.updateGoalSettings({
        ...this.goalSettings,
        defaultAccessPolicy: this.accessPolicy,
      }).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.saved') });
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }).finally(() => {
        this.accessPolicyLoading = false;
      });
    },
    saveThresholds() {
      this.updateGoalSettings({
        ...this.goalSettings,
        redThreshold: this.thresholds.redThreshold,
        yellowThreshold: this.thresholds.yellowThreshold,
      }).then((goalSettings) => {
        this.setThresholds(goalSettings);
        this.$showSnackbar({ color: 'success', message: this.$t('success.saved') });
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    saveNames() {
      this.updateGoalSettings({
        ...this.goalSettings,
        featureNameSingular: this.featureNameSingular,
        featureNamePlural: this.featureNamePlural,
      }).then((goalSettings) => {
        this.setFeatureNameSingular(goalSettings);
        this.setFeatureNamePlural(goalSettings);
        this.$showSnackbar({ color: 'success', message: this.$t('success.saved') });
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },

    setThresholds(goalSettings) {
      this.thresholds = {
        redThreshold: goalSettings.redThreshold,
        yellowThreshold: goalSettings.yellowThreshold,
      };
    },
    setFeatureNameSingular(goalSettings) {
      if (goalSettings.featureNameSingular === '') {
        this.featureNameSingular = this.$t('goalSettings.featureName.defaultSingular');
        return;
      }
      this.featureNameSingular = goalSettings.featureNameSingular;
    },
    setFeatureNamePlural(goalSettings) {
      if (goalSettings.featureNamePlural === '') {
        this.featureNamePlural = this.$t('goalSettings.featureName.defaultPlural');
        return;
      }
      this.featureNamePlural = goalSettings.featureNamePlural;
    },
    setValues() {
      this.accessPolicy = this.goalSettings.defaultAccessPolicy;
      this.setThresholds(this.goalSettings);
      this.setFeatureNamePlural(this.goalSettings);
      this.setFeatureNameSingular(this.goalSettings);
    },
  },
  created() {
    this.setValues();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-settings {
    height: 100%;

    ._feature-name {
      margin-bottom: 2rem;

      ._label {
        margin-top: 1rem;
        margin-bottom: -.2rem;
        color: $font-color-secondary;
      }
    }

    ._header {
      margin-bottom: 2rem;
    }

    ._section {
      margin-bottom: 4rem;
    }

    ._access-policy {
      display: flex;

      ._wrapper {
        margin: .4rem 0 2rem;
        border: 1px solid $border-color;
        border-radius: $default-border-radius;
      }
    }

    ._dashboard-settings {
      padding: .8rem 0 2rem;
    }

    ._confidence {
      margin-top: 1.2rem;
    }
  }
</style>
