<template>
  <div class="grid-page-chart-editor">
    <div class="_top">
      <div class="_left">
        <breadcrumbs :breadcrumbs="breadcrumbItems" />
        <div class="_divider">
          /
        </div>
        <m-dropdown
          :title="$t('general.actions')"
        >
          <m-btn
            type="clickable"
            hide-border
            small
          >
            {{ maxLength(props.gridPageTile.title, 30) }}
          </m-btn>
          <template #overlay>
            <m-card
              padding-xxs
              :style="{ width: '20rem'}"
            >
              <m-focusable>
                <simple-editor
                  auto-focus
                  :initial-value="props.gridPageTile.title"
                  :read-only="false"
                  auto-select
                  :placeholder="$t('gridPageChartEditor.titlePlaceholder')"
                  @update:value="updateTitle"
                />
              </m-focusable>
            </m-card>
          </template>
        </m-dropdown>
        <m-dropdown
          v-model:value="descriptionEditMode"
          :style="{ marginLeft: '.6rem' }"
          :title="$t('general.actions')"
        >
          <m-btn
            type="clickable"
            small
            light
            :icon="props.gridPageTile.description === '' ? 'plus' : undefined"
            hide-border
          >
            <template v-if="props.gridPageTile.description !== ''">
              {{ maxLength(props.gridPageTile.description, 50) }}
            </template>
            <template v-else>
              {{ $t('gridPageChartEditor.addDescription') }}
            </template>
          </m-btn>
          <template #overlay>
            <m-card
              padding-xxs
              :style="{ width: '30rem'}"
            >
              <m-focusable>
                <simple-editor
                  :style="{ width: '100%' }"
                  :initial-value="props.gridPageTile.description"
                  :read-only="false"
                  auto-select
                  auto-focus
                  :placeholder="$t('gridPageChartEditor.descriptionPlaceholder')"
                  :enter-handler="updateDescription"
                  @update:value="updateDescription"
                  @enter="descriptionEditMode = false"
                />
              </m-focusable>
            </m-card>
          </template>
        </m-dropdown>
      </div>
      <div class="_right">
        <m-btn
          :style="{ marginRight: '.4rem' }"
          hide-border
          @click="emit('close')"
        >
          {{ $t('general.discardChanges') }}
        </m-btn>
        <m-btn
          color="primary"
          @click="save"
        >
          {{ $t('general.save') }}
        </m-btn>
      </div>
    </div>
    <div class="_bottom">
      <div class="_left">
        <m-content
          class="_chart"
          :padding-x="10"
          :padding-y="10"
        >
          <grid-page-chart
            :chart="toSave"
            size="large"
          />
        </m-content>
      </div>
      <div class="_right">
        <m-section
          :title="$t('gridPageChartEditor.title')"
          heading-size="h5"
        >
          <m-form-item :label="$t('gridPageChartEditor.source')">
            <m-select
              v-model:value="source"
              :items="sources"
              full-width
            />
          </m-form-item>
          <m-form-item :label="$t('gridPageChartEditor.filter')">
            <m-dropdown
              block
              :relocate-key="filter"
              :title="$t('general.actions')"
            >
              <m-focusable
                type="clickable"
                full-width
              >
                <m-tag
                  icon="filter"
                  :title="$t('gridPageChartEditor.filter')"
                  color="none"
                />
                <m-tag
                  v-if="filter?.children.length > 0"
                  :title="`${filter?.children.length}`"
                  small
                  :style="{ marginLeft: '.6rem' }"
                />
              </m-focusable>
              <template #overlay>
                <m-card
                  padding-xs
                  :style="{ minWidth: '30rem' }"
                >
                  <scope-filter
                    v-model:value="filter"
                    can-remove-all
                    :props="filterProps"
                    :account="loggedInUserAccount"
                  />
                </m-card>
              </template>
            </m-dropdown>
          </m-form-item>
          <m-form-item
            v-if="source === goalConfig.model"
            :label="$t('gridPageChartEditor.cycle')"
          >
            <goal-cycle-selector
              v-model:value="selectedCycles"
              keep-open-on-click
              multiple
              full-width
              wrap
            />
          </m-form-item>
        </m-section>
        <m-section
          :title="$t('gridPageChartEditor.chart')"
          heading-size="h5"
        >
          <m-form-item class="_chart-type-selector">
            <m-tooltip
              v-for="type in chartTypes"
              :key="type"
            >
              <m-focusable
                type="clickable"
                :class="{ '_chart-btn': true, '-selected': chartType === type }"
                @click="chartType = type"
              >
                <div class="_icon-wrapper">
                  <m-icon
                    :type="getIconByType(type)"
                    size="20"
                  />
                </div>
              </m-focusable>
              <template #title>
                {{ $t(`gridPageChartEditor.${type}`) }}
              </template>
            </m-tooltip>
          </m-form-item>
        </m-section>
        <template v-if="[gridPageChartType.bar, gridPageChartType.line].includes(chartType)">
          <m-section
            :title="$t('gridPageChartEditor.xAxis')"
            heading-size="h5"
          >
            <m-form-item :label="$t('gridPageChartEditor.whatToShow')">
              <group-by-selector
                v-model:value="xAxisGroupBy"
                :properties="properties"
                full-width
                show-search
                match-trigger-width
                :hidden-group-by-options="[chartGroupByOption.unique]"
              />
            </m-form-item>
            <m-form-item
              v-if="xAxisGroupBy.groupBy === chartGroupByOption.bucket"
              :label="$t('gridPageChartEditor.bucket')"
            >
              <bucket-selector
                v-model:value="customBucket"
              />
            </m-form-item>
          </m-section>
          <m-section
            :title="$t('gridPageChartEditor.yAxis')"
            heading-size="h5"
          >
            <m-form-item :label="$t('gridPageChartEditor.whatToShow')">
              <aggregation-selector
                v-model:value="yAxisGroupBy"
                :properties="properties"
                full-width
                :hidden-aggregator-functions="[aggregatorType.countDistinct]"
              />
            </m-form-item>
            <m-form-item :label="$t('gridPageChartEditor.groupBy')">
              <group-by-selector
                v-model:value="stackOptions"
                :properties="properties"
                show-none
                full-width
                :hidden-group-by-options="[chartGroupByOption.unique]"
              />
            </m-form-item>
          </m-section>
        </template>
        <template v-if="chartType === gridPageChartType.pie">
          <m-section
            :title="$t('gridPageChartEditor.data')"
            heading-size="h5"
          >
            <m-form-item :label="$t('gridPageChartEditor.whatToShow')">
              <group-by-selector
                v-model:value="xAxisGroupBy"
                :properties="properties"
                :hidden-group-by-options="[chartGroupByOption.unique]"
                full-width
                show-search
                match-trigger-width
              />
            </m-form-item>
            <m-form-item
              v-if="xAxisGroupBy.groupBy === chartGroupByOption.bucket"
              :label="$t('gridPageChartEditor.bucket')"
            >
              <bucket-selector
                v-model:value="customBucket"
              />
            </m-form-item>
            <m-form-item :label="$t('gridPageChartEditor.representation')">
              <aggregation-selector
                v-model:value="yAxisGroupBy"
                :properties="properties"
                full-width
              />
            </m-form-item>
          </m-section>
        </template>
        <template v-if="chartType === gridPageChartType.metric">
          <m-section
            :title="$t('gridPageChartEditor.data')"
            heading-size="h5"
          >
            <m-form-item :label="$t('gridPageChartEditor.whatToShow')">
              <aggregation-selector
                v-model:value="yAxisGroupBy"
                :properties="properties"
                full-width
              />
            </m-form-item>
          </m-section>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import AggregationSelector from '@/components/custom-grid/AggregationSelector.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import BucketSelector from '@/components/custom-grid/BucketSelector.vue';
import GoalCycleSelector from '@/components/goal/cycle/GoalCycleSelector.vue';
import GridPageChart from '@/components/custom-grid/chart/GridPageChart.vue';
import GroupBySelector from '@/components/custom-grid/GroupBySelector.vue';
import ScopeFilter from '@/components/filter/ScopeFilter.vue';
import SimpleEditor from '@/components/SimpleEditor.vue';
import useDebounce from '@/composables/debounce';
import useGoalProperty from '@/composables/property/goal-property';
import useGridPage from '@/composables/grid-page/grid-page';
import useGridPageTileChart from '@/composables/grid-page/chart/grid-page-tile-chart';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { CREATED_AT, MODIFIED_AT } from 'shared/api/query/constants';
import { aggregatorType, chartGroupByOption, gridPageChartType, propertyType, routeName } from 'shared/constants.json';
import { computed, ref, watch } from 'vue';
import { createPropsList } from '@/lib/props';
import { directProperties as directGoalActivityProperties } from '@/lib/goal-activity/properties';
import { directProperties as directUpdateProperties } from '@/lib/updates/properties';
import { goalActivity as goalActivityConfig, goal as goalConfig, update as updateConfig } from 'shared/api/query/configs.json';
import { goalChartProperties } from '@/lib/goal/properties';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  gridPageTile: {
    type: Object,
    required: true,
  },
  gridPage: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const breadcrumbItems = ref([
  { title: t('views.dashboards'), to: { name: routeName.dashboards } },
  { title: props.gridPage.title, onClick: () => { emit('close'); } },
]);

const debounceSvc = useDebounce();
const gridPageService = useGridPage();

const getIconByType = (type) => {
  switch (type) {
    case gridPageChartType.bar:
      return 'bar-chart';
    case gridPageChartType.line:
      return 'line-chart';
    case gridPageChartType.pie:
      return 'pie-chart';
    case gridPageChartType.metric:
      return 'number';
    default:
      return '';
  }
};

function updateTitle(val) {
  debounceSvc.debounce(() => gridPageService.updateGridPageTile({ uid: props.gridPageTile.uid, title: val }), 500);
}

const descriptionEditMode = ref(false);
function updateDescription(val) {
  debounceSvc.debounce(() => gridPageService.updateGridPageTile({ uid: props.gridPageTile.uid, description: val }), 500);
}

const chartService = useGridPageTileChart();
const gridPageSvc = useGridPage();
const gridPageTileChart = computed(() => {
  const res = gridPageSvc.gridPageTileChart.value.find((e) => e.tile.uid === props.gridPageTile.uid);
  if (res === undefined) {
    return null;
  }

  return res;
});

const save = () => {
  chartService.updateSingle(toSave.value);
};

const toSave = computed(() => ({
  uid: gridPageTileChart.value.uid,
  source: source.value,
  chartType: chartType.value,
  filter: filter.value,
  goalCycles: selectedCycles.value,
  groupBy: {
    uid: gridPageTileChart.value.groupBy.uid,
    ...groupByToStore.value,
    customBucket: {
      uid: gridPageTileChart.value.groupBy.customBucket.uid,
      ...customBucket.value,
    },
  },
  aggregationConfig: {
    uid: aggregationConfig.value.uid,
    aggregation: {
      uid: aggregationConfig.value.aggregation.uid,
      ...yAxisGroupBy.value,
    },
    stackOptions: {
      uid: aggregationConfig.value.stackOptions.uid,
      ...stackOptionsToStore.value,
    },
  },
}));

const stackOptionsToStore = computed(() => {
  if ([gridPageChartType.pie, gridPageChartType.metric].includes(chartType.value)) {
    return { groupBy: chartGroupByOption.none };
  }
  return stackOptions.value;
});

const groupByToStore = computed(() => {
  if (chartType.value === gridPageChartType.metric) {
    return { groupBy: chartGroupByOption.none };
  }
  return xAxisGroupBy.value;
});

const aggregationConfig = computed(() => gridPageTileChart.value.aggregationConfig);

const maxLength = (str, max) => {
  if (str.length === 0) {
    return t('general.untitled');
  }
  if (str.length > max) {
    return `${str.slice(0, max)}...`;
  }
  return str;
};

const source = ref(goalConfig.model);

const { loggedInUserAccount } = useLoggedInUserAccount();

const filter = ref(null);

const { properties: goalProperties } = useGoalProperty();

const selectedCycles = ref([]);

const chartTypes = [gridPageChartType.bar, gridPageChartType.line, gridPageChartType.pie, gridPageChartType.metric];
const chartType = ref(gridPageChartType.bar);

const xAxisGroupBy = ref(null);
const customBucket = ref(null);
const properties = computed(() => {
  if (source.value === goalConfig.model) {
    return [
      { isProperty: false, edge: CREATED_AT, edgeType: propertyType.date },
      { isProperty: false, edge: MODIFIED_AT, edgeType: propertyType.date },
      { isProperty: false, edge: goalConfig.edges.cachedCalculatedCurrent, edgeType: propertyType.number },
      { isProperty: false, edge: goalConfig.edges.goalCycle, edgeType: propertyType.options },
      ...goalProperties.value.map((e) => ({ isProperty: true, property: e })),
    ];
  }
  return [];
});

const yAxisGroupBy = ref(null);
const stackOptions = ref(null);

const { userLang } = useLoggedInUser();
const goalDefaultProps = computed(() => createPropsList({
  properties: goalProperties.value,
  directProperties: goalChartProperties(t),
  userLang: userLang.value,
  model: goalConfig.model,
}));

const updateDefaultProps = computed(() => createPropsList({
  properties: [],
  directProperties: directUpdateProperties(t),
  userLang: userLang.value,
  model: updateConfig.model,
}));

const goalActivityDefaultProps = computed(() => createPropsList({
  properties: [],
  directProperties: directGoalActivityProperties(t),
  userLang: userLang.value,
  model: goalActivityConfig.model,
}));

const filterProps = computed(() => {
  switch (source.value) {
    case goalConfig.model:
      return goalDefaultProps.value;
    case updateConfig.model:
      return updateDefaultProps.value;
    case goalActivityConfig.model:
      return goalActivityDefaultProps.value;
    default:
      return [];
  }
});

const sources = [
  {
    text: t('gridPageChartEditor.goal'),
    icon: 'compass',
    value: goalConfig.model,
  },
  {
    text: t('gridPageChartEditor.checkin'),
    icon: 'history',
    value: updateConfig.model,
  },
  {
    text: t('gridPageChartEditor.goalActivity'),
    icon: 'retweet',
    value: goalActivityConfig.model,
  },
];

if (gridPageTileChart.value !== null) {
  source.value = gridPageTileChart.value.source;
  chartType.value = gridPageTileChart.value.chartType;
  filter.value = gridPageTileChart.value.filter;
  selectedCycles.value = gridPageTileChart.value.goalCycles;
  yAxisGroupBy.value = gridPageTileChart.value.aggregationConfig.aggregation;
  stackOptions.value = gridPageTileChart.value.aggregationConfig.stackOptions;
  xAxisGroupBy.value = { ...gridPageTileChart.value.groupBy, customBucket: undefined };
  customBucket.value = { ...gridPageTileChart.value.groupBy.customBucket };
}

watch(source, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    filter.value = null;
  }
});
</script>

<style scoped lang="scss">
.grid-page-chart-editor {
  display: grid;
  grid-template-rows: 6rem 1fr;
  height: 100vh;

  ._top {
    flex: 0 0 4rem;
    border-bottom: 1px solid $input-border-color;
    display: flex;
    align-items: center;
    padding: 0 2rem;

    ._left {
      display: flex;

      ._divider {
        margin: 0 .6rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    ._right {
      margin-left: auto;
      display: flex;
    }
  }

  ._bottom {
    display: flex;

    ._left {
      flex: 1 0 auto;

      ._chart {
        height: 100%;
      }
    }

    ._right {
      flex: 0 0 30rem;
      padding: 2rem;
      border-left: 1px solid $input-border-color;
    }
  }

  ._chart-type-selector {
    display: flex;
    gap: 1rem;

    ._chart-btn {
      border: none;
      width: 4.2rem;
      height: 4.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 0 1px $input-border-color;

      ._icon-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.-selected {
        box-shadow: 0 0 0 2px $primary-color;
        background-color: $highlighted-color-light;
      }
    }
  }
}
</style>
