import useCustomBuckets from '@/composables/grid-page/chart/custom-buckets';
import useDebounce from '@/composables/debounce';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useParamEnricher from '@/composables/view-params/enrich';
import useProperties from '@/composables/property/property';
import { AND, HAS, NOT, TYPE, UID } from 'shared/api/query/constants';
import { GoalActivityFilterHandler } from '@/lib/filter/goal-activity/handler';
import { GoalFilterHandler } from '@/lib/filter/goal/handler';
import { UpdatesFilterHandler } from '@/lib/filter/updates/handler';
import { aggregatorType, chartGroupByOption, gridPageChartType } from 'shared/constants.json';
import { combine } from 'shared/api/query/filter';
import { computed, ref, watch } from 'vue';
import { copy } from 'shared/lib/copy';
import { createGoalCycleFilter } from '@/api/query/nebula/goal-cascade';
import { dateGroupingFunctions } from '@/composables/grid-page/chart/utils';
import { dogma } from '@/api';
import { goalActivity as goalActivityConfig, goal as goalConfig, update as updateConfig } from 'shared/api/query/configs.json';
import { logCatch } from '@/lib/logger/logger';

export default function useChartDataQuery(chart) {
  const { debounce } = useDebounce();
  const loading = ref(false);
  const result = ref(null);
  const resultRaw = ref(null);
  const error = ref(null);
  const { userLang } = useLoggedInUser();

  const customBucket = computed(() => chart.value.groupBy.customBucket);
  const { findBucket } = useCustomBuckets(customBucket, resultRaw, computed(() => chart.value.groupBy));

  const goalFilterHandler = new GoalFilterHandler();
  const updatesFilterHandler = new UpdatesFilterHandler();
  const goalActivityFilterHandler = new GoalActivityFilterHandler();

  const propertySvc = useProperties();
  const goalsSvc = useGoals();
  const paramEnricher = useParamEnricher(propertySvc, goalsSvc);

  const enrichedFilter = computed(() => {
    if (chart.value.filter === null) {
      return null;
    }
    return paramEnricher.enrich(copy(chart.value.filter));
  });

  const filter = computed(() => {
    const goalCycleFilter = createGoalCycleFilter(chart.value.goalCycles);
    if (enrichedFilter.value === null) {
      return { filterTree: goalCycleFilter, varBlocks: [] };
    }

    switch (chart.value.source) {
      case goalConfig.model: {
        const filterObject = goalFilterHandler.Translate(enrichedFilter.value);
        return {
          filterTree: combine(AND, [goalCycleFilter, filterObject.filterTree]),
          varBlocks: filterObject.varBlocks,
        };
      }
      case updateConfig.model: {
        const filterObject = updatesFilterHandler.Translate(enrichedFilter.value);
        const checkInFilter = { op: NOT, child: [{ func: { name: HAS, attr: updateConfig.edges.goal } }] };
        return {
          filterTree: combine(AND, [checkInFilter, filterObject.filterTree]),
          varBlocks: filterObject.varBlocks,
        };
      }
      case goalActivityConfig.model: {
        const filterObject = goalActivityFilterHandler.Translate(enrichedFilter.value);
        return {
          filterTree: filterObject.filterTree,
          varBlocks: filterObject.varBlocks,
        };
      }
      default:
        return { filterTree: null, varBlocks: [] };
    }
  });

  const getEdge = (prop) => {
    if (prop.isProperty) {
      return 'cachedProperty';
    }
    return prop.edge;
  };

  const getLangs = (prop) => {
    if (prop.isProperty) {
      return [`p${prop.property.uid}`];
    }
    return null;
  };

  const query = computed(() => {
    switch (chart.value.chartType) {
      case gridPageChartType.metric:
        switch (chart.value.aggregationConfig.aggregation.aggregator) {
          case aggregatorType.count:
            return [
              ...filter.value.varBlocks,
              {
                alias: 'res',
                func: { name: TYPE, args: [{ value: chart.value.source, typ: 1 }] },
                filter: filter.value.filterTree,
                model: chart.value.source,
                children: [
                  { attr: UID, isCount: true },
                ],
              },
            ];
          case aggregatorType.countDistinct:
            return [
              ...filter.value.varBlocks,
              {
                alias: 'res',
                func: { name: TYPE, args: [{ value: chart.value.source, typ: 1 }] },
                filter: filter.value.filterTree,
                model: chart.value.source,
                isGroupby: true,
                groupbyAttrs: [{
                  attr: `${chart.value.source}.${getEdge(chart.value.aggregationConfig.aggregation)}`,
                  langs: getLangs(chart.value.aggregationConfig.aggregation),
                }],
              },
            ];
          case aggregatorType.avg:
          case aggregatorType.sum:
          case aggregatorType.min:
          case aggregatorType.max: {
            return [
              ...filter.value.varBlocks,
              {
                alias: 'var',
                func: { name: TYPE, args: [{ value: chart.value.source, typ: 1 }] },
                filter: filter.value.filterTree,
                model: chart.value.source,
                children: [
                  {
                    attr: getEdge(chart.value.aggregationConfig.aggregation),
                    langs: getLangs(chart.value.aggregationConfig.aggregation),
                    var: 'edgeVal',
                  },
                ],
              },
              {
                alias: 'res',
                children: [
                  {
                    alias: chart.value.aggregationConfig.aggregation.aggregator,
                    attr: 'val',
                    isInternal: true,
                    needsVar: [
                      {
                        name: 'edgeVal',
                        typ: 2,
                      },
                    ],
                    func: {
                      name: chart.value.aggregationConfig.aggregation.aggregator,
                      needsVar: [
                        {
                          name: 'edgeVal',
                          typ: 2,
                        },
                      ],
                    },
                  },
                ],
              },
            ];
          }
          default:
            return [];
        }
      case gridPageChartType.pie:
        switch (chart.value.groupBy.groupBy) {
          case chartGroupByOption.day:
          case chartGroupByOption.week:
          case chartGroupByOption.month:
          case chartGroupByOption.quarter:
          case chartGroupByOption.year:
          case chartGroupByOption.unique:
          case chartGroupByOption.bucket:
            switch (chart.value.aggregationConfig.aggregation.aggregator) {
              case aggregatorType.count:
                return [
                  ...filter.value.varBlocks,
                  {
                    alias: 'res',
                    func: { name: TYPE, args: [{ value: chart.value.source, typ: 1 }] },
                    filter: filter.value.filterTree,
                    model: chart.value.source,
                    isGroupby: true,
                    groupbyAttrs: [{
                      alias: 'val',
                      attr: `${chart.value.source}.${getEdge(chart.value.groupBy)}`,
                      langs: getLangs(chart.value.groupBy),
                    }],
                    children: [
                      { attr: UID, isCount: true },
                    ],
                  },
                ];
              default:
                return [];
            }
          default:
            return [];
        }
      default:
        return [];
    }
  });

  const decodeResponse = (response) => {
    switch (chart.value.chartType) {
      case gridPageChartType.metric:
        switch (chart.value.aggregationConfig.aggregation.aggregator) {
          case aggregatorType.count:
            return response.data.res[0].count;
          case aggregatorType.avg:
            return response.data.res[0].avg;
          case aggregatorType.sum:
            return response.data.res[0].sum;
          case aggregatorType.min:
            return response.data.res[0].min;
          case aggregatorType.max:
            return response.data.res[0].max;
          case aggregatorType.countDistinct:
            return response.data.res[0]['@groupby'].length;
          default:
            return [];
        }
      case gridPageChartType.pie:
        switch (chart.value.aggregationConfig.aggregation.aggregator) {
          case aggregatorType.count:
            switch (chart.value.groupBy.groupBy) {
              case chartGroupByOption.unique:
                return response.data.res[0]['@groupby'];
              case chartGroupByOption.day:
              case chartGroupByOption.week:
              case chartGroupByOption.month:
              case chartGroupByOption.quarter:
              case chartGroupByOption.year: {
                const groupByYear = (data) => data.reduce((acc, { count, val }) => {
                  const group = dateGroupingFunctions[chart.value.groupBy.groupBy](val, userLang.value);
                  if (!acc[group]) {
                    acc[group] = 0;
                  }
                  acc[group] += count;
                  return acc;
                }, {});
                return Object.entries(groupByYear(response.data.res[0]['@groupby'])).map(([year, count]) => ({
                  val: year,
                  count,
                }));
              }
              case chartGroupByOption.bucket: {
                const groupByBucket = (data) => data.reduce((acc, { count, val }) => {
                  const bucket = findBucket(val);
                  if (bucket === undefined) {
                    return acc;
                  }
                  if (!acc[bucket.label]) {
                    acc[bucket.label] = 0;
                  }
                  acc[bucket.label] += count;
                  return acc;
                }, {});
                return Object.entries(groupByBucket(response.data.res[0]['@groupby'])).map(([label, count]) => ({
                  val: label,
                  count,
                }));
              }
              default:
                return [];
            }
          default:
            return [];
        }
      default:
        return [];
    }
  };

  const runQuery = () => {
    if (query.value.length === 0) {
      return;
    }

    loading.value = true;
    result.value = null;
    dogma.query(query.value)
      .then((res) => {
        resultRaw.value = res.data.res;
        result.value = decodeResponse(res);
        error.value = null;
      })
      .catch(logCatch((e) => {
        error.value = e;
      }))
      .finally(() => {
        loading.value = false;
      });
  };

  watch(chart, (newVal, oldVal) => {
    if (newVal?.chartType !== oldVal?.chartType) {
      resetResult();
    }
    debounce(runQuery, 2000);
  }, { deep: true });

  runQuery();

  const resetResult = () => {
    result.value = null;
  };

  return {
    runQuery,
    result,
    error,
    loading,
  };
}
